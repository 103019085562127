<template>
	<div class="page-content">
		<h5 v-if="!displayedTemplates.length && !$apollo.loading" class="display-5 empty-placeholder">
			{{ $t('emptyPlaceholder') }}
		</h5>
		<spinner :show="$apollo.loading" />
		<div class="alert alert-danger" v-show="assignmentCount >= assignmentLimit">
			{{ $t('assignmentLimitReached') }}
		</div>
		<div class="controls">
			<dropdown-list
				icon="fas fa-filter"
				:list="filterDropdownItems"
				v-model:selection="filters"
				:i18n="filtersI18n"
			/>
			<dropdown class="order">
				<template #button>
					<fa-icon size="lg" icon="fa-solid fa-sort" />
					<span class="button-label">{{ $t(currentSort) }}</span>
				</template>
				<template #default>
					<li v-for="option in sortOptions">
						<button
							type="button"
							class="dropdown-item"
							:class="{ active: currentSort === option }"
							@click="currentSort = option"
						>
							<span>{{ $t(option) }}</span>
						</button>
					</li>
				</template>
			</dropdown>

			<div class="alert alert-info mb-0 py-2 ms-auto">
				<span class="me-1">{{ $t('assignmentCountLimit', { assignmentCount, assignmentLimit }) }}</span>
				<popper hover arrow placement="left">
					<fa-icon icon="fa-regular fa-circle-question" />
					<template #content>
						<p>
							{{ $t('assignmentCountLimitTipOne') }}
						</p>
						<p>{{ $t('assignmentCountLimitTipTwo') }}</p>
					</template>
				</popper>
			</div>
		</div>

		<div class="controls">
			<div>
				<label>{{ $t('tagsFilter') }}</label>
				<tag-list :all-tags="tagsList" v-model:tags="selectedTags" editable />
			</div>
			<div class="search-task-by-name">
				<label>{{ $t('searchTemplate') }}</label>
        		<input class="form-control form-control-sm" type="text" v-model="searchTemplate"/>
			</div>
			<router-link
				class="btn btn-success ms-auto"
				:class="{ disabled: assignmentCount >= assignmentLimit }"
				:to="assignmentCount >= assignmentLimit ? {} : { name: 'Assignment' }"
				>{{ $t('newTemplate') }}</router-link
			>
		</div>

		<div class="templates-list mb-3">
			<assignment-template-card v-for="template in displayedTemplates" :key="template.id" :template="template" />
		</div>
		<pagination :items-on-page="itemsPerPage" :items-total="filteredTemplates.length" @changePage="setBounds" />
	</div>
</template>

<style scoped lang="scss">
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.page-content {
	position: relative;

	.empty-placeholder {
		position: absolute;
		top: 40%;
		left: 0;
		width: 100%;
		text-align: center;
		color: $secondary;
	}

	.controls {
		display: flex;
		align-items: flex-end;
		margin-bottom: 0.5rem;
		gap: 0.5rem;

		.order {
		.dropdown-toggle {
			.button-label {
				margin-left: 0.5rem;
			}
		}
	}
	}
	.controls + .controls {
		margin-bottom: 1rem;
	}

	.templates-list {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
		grid-template-rows: max-content;
		grid-auto-rows: 1fr;
		gap: 1rem;
	}

.search-task-by-name {
	max-width: 14.375rem;
}
}
</style>

<i18n locale="ru" src="@/locales/ru/views/assignment-templates.json"></i18n>
<i18n locale="en" src="@/locales/en/views/assignment-templates.json"></i18n>

<script>
import dayjs from 'dayjs';
import QUERY from '@/queries/views/assignment-templates/query-templates.graphql';

import AssignmentTemplateCard from '@/components/assignment-templates/AssignmentTemplateCard';
import TagList from '@/components/TagList';
import Pagination from '@/components/Pagination';
import Spinner from '@/components/Spinner.vue';
import DropdownList from '@/components/dropdowns/DropdownList';
import Dropdown from '@/components/dropdowns/Dropdown';

export default {
	data() {
		return {
			templates: [],
			tags: [],
			selectedTags: [],
			currentPage: 1,
			itemsPerPage: 12,
			bounds: [0, 12],
			assignmentLimit: 1,
			filterDropdownItems: ['local', 'foreign', null, 'editable', 'static'],
			filters: {
				local: true,
				foreign: true,
				editable: true,
				static: true,
			},
			sortOptions: ['none', 'сreatedAtAsc', 'сreatedAtDesc', 'nameAsc', 'nameDesc', 'authorAsc', 'authorDesc'],
			currentSort: 'none',
			searchTemplate: '',
		};
	},
	components: {
		AssignmentTemplateCard,
		TagList,
		Pagination,
		Spinner,
		DropdownList,
		Dropdown,
	},
	computed: {
		assignmentCount() {
			return this.templates.filter(el => el.availableFrom === 'COMPANY').length;
		},
		filteredTemplates() {
			const filters = this.filters;
			const searchTemplate = this.searchTemplate.toLowerCase()
			return this.templates.filter(function (template) {
				if (this.selectedTags.length) {
					const hasAllTags =
						template.tags.length > 0 &&
						this.selectedTags.every(tag => template.tags.some(t => t.id === tag.id));
					if (!hasAllTags) {
						return false;
					}
				}
				if (!filters.local && template.availableFrom === 'COMPANY') {
					return false;
				}
				if (!filters.foreign && template.availableFrom !== 'COMPANY') {
					return false;
				}
				const isEditable = template.isEditable && template.availableFrom !== 'LICENCE';
				if (!filters.editable && isEditable) {
					return false;
				}
				if (!filters.static && !isEditable) {
					return false;
				}
				if (searchTemplate.length) {
					if (!template.name.toLowerCase().includes(searchTemplate)){
						return false;
					}
				}
				return true;
			}, this);
		},
		displayedTemplates() {
			const option = this.currentSort;
			return this.filteredTemplates.sort(function (a, b) {
					if (option === 'none') {
						return 0;
					}
					const createA = dayjs(a.createdAt);
					const createB = dayjs(b.createdAt);
					const nameA = a.name;
					const nameB = b.name;
					const authorA = a.author.email;
					const authorB = b.author.email;
					if (option === 'nameAsc') {
						return  nameA.localeCompare(nameB);
					}
					if (option === 'nameDesc') {
						return  nameB.localeCompare(nameA);
					}
					if (option === 'authorAsc') {
						return  authorA > authorB ? 1 : -1;
					}
					if (option === 'authorDesc') {
						return  authorA > authorB ? -1 : 1;
					}
					if (option === 'сreatedAtAsc') {
						return createA.isBefore(createB) ? -1 : 1;
					}
					if (option === 'сreatedAtDesc') {
						return createA.isBefore(createB) ? 1 : -1;
					}
				}).slice(...this.bounds);
		},
		filtersI18n() {
			return {
				locale: this.$i18n.locale,
				messages: this.$i18n.getLocaleMessage(this.$i18n.locale).filters,
			};
		},
		tagsList() {
			return this.templates.reduce(function (acc, template) {
				if (!template.tags?.length) {
					return acc;
				}
				const tags = template.tags.map(tag => ({ ...tag })).filter(tag => !acc.some(a => a.id === tag.id));
				acc.push(...tags);
				return acc;
			}, []);
		},
	},
	methods: {
		setBounds(page, bounds) {
			this.bounds = bounds;
		},
	},
	apollo: {
		templates: {
			query: QUERY,
			manual: true,
			fetchPolicy: 'cache-and-network',
			result({ loading, data }) {
				if (!loading) {
					this.templates = data.myCompany.assignments;
					this.tags = data.myCompany.tags;
					this.assignmentLimit = data.myCompany.assignmentLimit;
				}
			},
		},
	},
};
</script>
