export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страница не найдена (404)"])}
      },
      "en": {
        "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page not found (404)"])}
      }
    }
  })
}
