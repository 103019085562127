<template>
	<tr>
		<td>{{ user.line }}</td>
		<template v-if="user.failed">
			<td class="table-danger" colspan="4">{{ $t('lineParseFailed') }}</td>
		</template>
		<template v-else>
			<td :class="{ 'table-danger': v.email.$error }">
				<span>{{ user.email }}</span>
				<span class="invalid-tooltip">{{
					v.email.$error && $t(`errors.${v.email.$errors[0]?.$message}`)
				}}</span>
			</td>
			<td :class="{ 'table-danger': v.password.$error }">
				<span>{{ user.password ?? $t('autoGenerated') }}</span>
				<span class="invalid-tooltip">{{
					v.password.$error && $t(`errors.${v.password.$errors[0]?.$message}`)
				}}</span>
			</td>
			<td :class="{ 'table-danger': v.pin.$error }">
				<span>{{ user.pin ?? $t('autoGenerated') }}</span>
				<span class="invalid-tooltip">{{ v.pin.$error && $t(`errors.${v.pin.$errors[0]?.$message}`) }}</span>
			</td>
			<td>
				<span>{{ user.notes }}</span>
			</td>
		</template>
	</tr>
</template>

<style scoped lang="scss">
td {
	position: relative;
}
.invalid-tooltip {
	left: 0;
	pointer-events: none;
}

td.table-danger:hover > .invalid-tooltip {
	display: block;
}

tr:last-child .invalid-tooltip {
	top: unset;
	bottom: 100%;
}
</style>

<i18n>
	{
		"ru": {
			"autoGenerated": "<автоматически>",
			"errors": {
				"LineParseFailedError": "Неверный формат строки.",
				"NoEmailError": "Адрес электронной почты обязателен.",
				"EmailFormatError": "Адрес электронной почты имеет неправильный формат.",
				"EmailExistsError": "Адрес электронной почты уже используется другим пользователем.",
				"PasswordTooShortError": "Длина пароля не должна быть меньше 8 символов.",
				"SameAsEmailError": "Пароль не должен совпадать с адресом электронной почты.",
				"PinLengthError": "Длина пин-кода должна составлять 4 символа.",
				"PinFormatError": "Пин-код может содержать только цифры."
			}
		},
		"en": {
			"autoGenerated": "<autogenerated>",
			"errors": {
				"LineParseFailedError": "Line format invalid.",
				"NoEmailError": "Email address is required.",
				"EmailFormatError": "Email address is invalid.",
				"EmailExistsError": "Email address already in use.",
				"PasswordTooShortError": "Password must be at least 8 characters long.",
				"SameAsEmailError": "Password must be different from email address.",
				"PinLengthError": "Pin-code muse be exactly 4 characters long.",
				"PinFormatError": "Pin-code must not contain non-numeric characters."
			}
		}
	}
</i18n>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
export default {
	props: {
		user: {
			type: Object,
			default: () => ({}),
		},
		validations: {
			type: Object,
			default: () => ({}),
		},
	},
	setup(props) {
		return { v: useVuelidate(props.validations, props.user) };
	},
};
</script>
