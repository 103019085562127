<template>
	<div class="page-content not-found-page">
		<div>
			<fa-icon icon="fa-regular fa-face-sad-tear" class="icon-sad-face" />
			<h1>{{ $t('notFound') }}</h1>
		</div>
	</div>
</template>

<i18n>
{
    "ru": {
        "notFound": "Страница не найдена (404)"
    },
    "en": {
        "notFound": "Page not found (404)"
    }
}
</i18n>

<style lang="scss" scoped>
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.not-found-page {
	position: relative;

	div {
		display: flex;
		height: 100%;
		justify-content: center;
		align-items: center;
		gap: 1rem;
		color: $secondary;

		.icon-sad-face {
			font-size: 2.5rem;
		}
	}
}
</style>
