<template>
	<div class="form-floating form-input-group">
		<textarea
			v-model="localValue"
			:id="inputId"
			:class="['form-control', { 'is-invalid': vError }]"
			:placeholder="textPlaceholder"
			:disabled="disabled"
		></textarea>
		<label :for="inputId">{{ textPlaceholder }}</label>
		<div class="invalid-tooltip">{{ vErrorMsg }}</div>
	</div>
</template>

<script>
export default {
	emits: ['update:value'],
	data() {
		return {
			visible: false,
		};
	},
	props: {
		inputId: {
			type: String,
			default: '',
		},
		value: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		vError: {
			type: Boolean,
			default: false,
		},
		vErrorMsg: {
			type: String,
			default: '',
		},
		required: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		localValue: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('update:value', value);
			},
		},
		textPlaceholder() {
			return this.required ? this.placeholder + '*' : this.placeholder;
		},
	},
};
</script>

<style lang="scss">
.form-input-group {
	position: relative;

	textarea {
		min-height: 8rem;
	}
}
</style>
