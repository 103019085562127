<template>
	<modal class="confirm-ownership" v-model:show="localShow" size="small">
		<template #header>{{ $t('header') }}</template>
		<template #body>
			<p>{{ $t('body', { company: companyName }) }}</p>
			<div>
				<text-input
					class="repeat-company-name"
					v-model:value="repeatCompanyName"
					:placeholder="$t('repeatCompanyName')"
					:error="v.repeatCompanyName.$error"
					:error-msg="$t('companyNameMismatch')"
				/>
			</div>
		</template>
		<template #footer>
			<button class="btn btn-outline-secondary" @click="localShow = false">{{ $t('cancel') }}</button>
			<button class="btn btn-danger" @click="confirm">{{ $t('confirm') }}</button>
		</template>
	</modal>
</template>

<i18n>
	{
		"ru": {
			"header": "Внимание!",
			"body": "Вы собираетесь передать контроль над организацией \"{ company }\" другому лицу. Пожалуйста, подтвердите ваши намерения, введя название этой организации ниже.",
			"repeatCompanyName": "Название организации",
			"companyNameMismatch": "Название организации не совпадает.",
			"cancel": "Отмена",
			"confirm": "Подтвердить передачу управления"
		},
		"en": {
			"header": "Warning!",
			"body": "You are about to transfer ownership of \"{ company }\" organisation to another person. Please confirm your intentions by entering the organisation's name below.",
			"repeatCompanyName": "Organisation name",
			"companyNameMismatch": "Organisation name does not match.",
			"cancel": "Cancel",
			"confirm": "Confirm ownership transfer"
		}
	}
</i18n>

<style lang="scss">
.confirm-ownership .vr-modal-body {
	overflow-y: visible;
}
</style>

<script>
import useVuelidate from '@vuelidate/core';
import { required, sameAs } from '@vuelidate/validators';
import { modalMixin } from '@/mixins';

import TextInput from '@/components/inputs/TextInput';

export default {
	mixins: [modalMixin],
	components: {
		TextInput,
	},
	emits: ['confirm'],
	props: {
		companyName: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			repeatCompanyName: '',
		};
	},
	validations() {
		return {
			repeatCompanyName: {
				required: required,
				sameAs: sameAs(this.companyName),
			},
		};
	},
	methods: {
		confirm() {
			this.v.$touch();
			if (this.v.$error) {
				return;
			}
			this.$emit('confirm');
			this.localShow = false;
		},
	},
	watch: {
		localShow(val) {
			if (!val) {
				this.v.$reset();
				this.repeatCompanyName = '';
			}
		},
	},
	setup() {
		return { v: useVuelidate() };
	},
};
</script>
