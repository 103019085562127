export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О ПОЛЬЗОВАТЕЛЕ"])},
        "fields": {
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
          "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["организация"])},
          "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["пин-код"])}
        }
      },
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USER DATA"])},
        "fields": {
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
          "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organisation"])},
          "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pin-code"])}
        }
      }
    }
  })
}
