<template>
	<div class="card text-dark bg-light">
		<spinner :show="loading" :loader-background="true" />
		<div class="card-header">{{ $t('title') }}</div>
		<form class="card-body registration" @submit.prevent="doRegistration">
			<div class="registration-form-group">
				<input
					class="form-control"
					:class="{ 'is-invalid': v$.email.$error }"
					type="text"
					:placeholder="$t('emailPlaceholder') + '*'"
					v-model="email"
				/>
				<div class="invalid-tooltip">{{ v$.email.$errors[0]?.$message }}</div>
			</div>
			<div class="registration-form-group">
				<input
					class="form-control"
					:class="{ 'is-invalid': v$.companyName.$error }"
					type="text"
					:placeholder="$t('companyPlaceholder')"
					v-model="companyName"
				/>
				<div class="invalid-tooltip">{{ v$.companyName.$errors[0]?.$message }}</div>
			</div>
			<password-input
				v-model:password="password"
				:vError="v$.password.$error"
				:placeholder="$t('passwordPlaceholder')"
				:vErrorMsg="v$.password.$errors[0]?.$message"
				required
			/>
			<password-input
				v-model:password="confirmPassword"
				:vError="v$.confirmPassword.$error"
				:placeholder="$t('confirmPasswordPlaceholder')"
				:vErrorMsg="v$.confirmPassword.$errors[0]?.$message"
				required
			/>
			<div v-show="alert.show" :class="['alert', `alert-${alert.type}`]">
				{{ alert.message }}
			</div>
			<div class="user-agreement registration-form-group">
				<input type="checkbox" v-model="signature" />
				<a :href="userAgreementUrl" target="_blank">{{ $t('message') }}</a>
			</div>
			<button :disabled="!signature" class="btn btn-primary" type="submit">{{ $t('submitBtn') }}</button>
		</form>
	</div>
</template>

<style scoped lang="scss">
.registration {
	display: flex;
	flex-direction: column;

	& > * + * {
		margin-top: 1rem;
	}

	.registration-form-group {
		position: relative;
	}

	.alert {
		margin-bottom: 0;
	}
}
.user-agreement {
	display: flex;
	gap: 1rem;
	align-items: baseline;
}
</style>

<i18n locale="ru" src="@/locales/ru/components/registration.json"></i18n>
<i18n locale="en" src="@/locales/en/components/registration.json"></i18n>

<script>
import useVuelidate from '@vuelidate/core';
import PasswordInput from '@/components/inputs/PasswordInput.vue';
import { required, sameAs, minLength, email, helpers } from '@vuelidate/validators';
import { QueryError } from '@/errors';
import REGIONS from '@/queries/views/login/query-regions.graphql';
import REGISTRATION from '@/queries/views/login/mutation-create-user-with-company.graphql';
import { userAgreementUrlMixin } from '@/mixins';
import Spinner from '@/components/Spinner.vue';

export default {
	mixins: [userAgreementUrlMixin],
	setup() {
		return { v$: useVuelidate() };
	},
	data() {
		return {
			email: null,
			companyName: null,
			password: null,
			confirmPassword: null,
			agreement: null,
			passwordMinLength: 8,
			regions: [],
			alert: { show: false, type: null, message: null },
			serverError: null,
			signature: false,
			loading: false,
		};
	},
	apollo: {
		regions: {
			query: REGIONS,
			manual: true,
			result({ loading, data, errors }) {
				if (!loading) {
					this.regions = data.regions;
				}
			},
		},
	},
	methods: {
		setAlert(show, type, message) {
			this.alert.show = show;
			this.alert.type = type;
			this.alert.message = message;
		},
		async doRegistration() {
			this.setAlert(false, '', '');
			this.v$.$touch();
			if (this.v$.$error) {
				return;
			}
			this.loading = true;
			try {
				const { data } = await this.$apollo.mutate({
					mutation: REGISTRATION,
					variables: {
						data: {
							email: this.email,
							companyName: this.companyName,
							password: this.password,
							regionId: this.regionId,
						},
					},
				});
				if (data.createUserWithCompany.success) {
					this.setAlert(true, 'success', this.$t('query.success'));
					this.email = null;
					this.companyName = null;
					this.password = null;
					this.confirmPassword = null;
					this.signature = false;
				} else {
					throw new QueryError(data.createUserWithCompany.error);
				}
			} catch (err) {
				if (err instanceof QueryError) {
					this.serverError = `errors.${err.cause.type}`;
				} else {
					this.serverError = 'errors.FallbackError';
				}
				this.handleError(err);
			} finally {
				this.loading = false;
				this.v$.$reset();
			}
		},
		handleError(err) {
			if (this.serverError === 'errors.UniqueValueError' && err.cause.entity === 'User') {
				this.setAlert(true, 'danger', this.$t('query.error.uniqueUser'));
			}
			if (this.serverError === 'errors.UniqueValueError' && err.cause.entity === 'Company') {
				this.setAlert(true, 'danger', this.$t('query.error.uniqueCompany'));
			}
			if (
				this.serverError === 'errors.InvalidValueError' &&
				err.cause.entity === 'User' &&
				err.cause.field === 'email'
			) {
				this.setAlert(true, 'danger', this.$t('query.error.invalidEmail'));
			}
		},
	},
	computed: {
		regionId() {
			return this.$i18n.locale === 'ru'
				? this.regions.find(el => el.name === 'ru').id
				: this.regions.find(el => el.name === 'en').id;
		},
	},
	validations() {
		return {
			email: {
				required: helpers.withMessage(this.$t('validation.email.required'), required),
				validEmail: helpers.withMessage(this.$t('validation.email.validEmail'), email),
				sameAsPassword: helpers.withMessage(
					this.$t('validation.email.notSameAsPassword'),
					() => this.email !== this.password
				),
			},
			companyName: {
				sameAsPassword: helpers.withMessage(
					this.$t('validation.companyName.notSameAsPassword'),
					() => this.companyName !== this.password
				),
			},
			password: {
				required: helpers.withMessage(this.$t('validation.password.required'), required),
				minLength: helpers.withMessage(
					this.$t('validation.password.minLength', { passwordLength: this.passwordMinLength }),
					minLength(this.passwordMinLength)
				),
				whiteSpaces: helpers.withMessage(
					this.$t('validation.password.whiteSpaces'),
					() => !/\s/g.test(this.password)
				),
				sameAsPassword: helpers.withMessage(
					this.$t('validation.password.sameAs'),
					sameAs(this.confirmPassword)
				),
			},
			confirmPassword: {
				required: helpers.withMessage(this.$t('validation.password.required'), required),
				minLength: helpers.withMessage(
					this.$t('validation.password.minLength', { passwordLength: this.passwordMinLength }),
					minLength(this.passwordMinLength)
				),
				whiteSpaces: helpers.withMessage(
					this.$t('validation.password.whiteSpaces'),
					() => !/\s/g.test(this.confirmPassword)
				),
				sameAsPassword: helpers.withMessage(this.$t('validation.password.sameAs'), sameAs(this.password)),
			},
			agreement: {
				signed: () => this.signature,
			},
		};
	},
	components: {
		PasswordInput,
		Spinner,
	},
};
</script>
