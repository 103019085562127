<template>
	<vr-modal v-model:show="modalShow" @close="resetForm" size="medium" align="center" :footer-line="false">
		<template v-slot:header> {{ $t('title') }} </template>
		<template v-slot:body>
			<div :class="[device.isValid ? 'alert-success' : 'alert-danger', 'alert']" role="alert">
				<h6>{{ statusLabel }}</h6>
			</div>
			<form @submit.prevent="updateDevice">
				<page-section :title="$t('sections.common.title')">
					<text-input
						v-model:value="formData.name"
						inputId="deviceName"
						:placeholder="$t('sections.common.fields.name')"
						:error="v$.formData.name.$error"
						:errorMsg="v$.formData.name.$errors[0]?.$message"
						:disabled="!canChangeDevice"
						required
					/>
					<page-section-field :label="$t('sections.common.fields.model')" :value="device.model" />
				</page-section>
			</form>
			<page-section :title="$t('sections.licence.title')">
				<page-section-field
					:label="$t('sections.licence.fields.startedAt')"
					:value="formatDate(device.licence?.startedAt)"
				/>
				<page-section-field
					:label="$t('sections.licence.fields.endedAt')"
					:value="formatDate(device.licence?.endedAt)"
				/>
				<page-section-field :label="$t('sections.licence.fields.key')" :value="device.licence?.key" />
			</page-section>
		</template>
		<template v-slot:footer>
			<button @click="closeModal" class="btn btn-secondary">{{ $t('cancelBtn') }}</button>
			<button @click="updateDevice" type="submit" class="btn btn-success">
				{{ $t('submitBtn') }}
			</button>
		</template>
	</vr-modal>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { QueryError } from '@/errors';
import { required, helpers, maxLength } from '@vuelidate/validators';
import VrModal from '@/components/modals/VrModal.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import PageSection from '@/components/page-sections/PageSection.vue';
import PageSectionField from '@/components/page-sections/PageSectionField.vue';
import UPDATEDEVICE from '@/queries/views/devices/mutation-update-device.graphql';

import { dateTimeMixin } from '@/mixins';
import toastMixin from '@/mixins/toast.js';
import devicePermissionsMixin from '@/mixins/permissions/devicePermissions.js';

export default {
	emits: ['updateDevice', 'update:show'],
	mixins: [dateTimeMixin, toastMixin, devicePermissionsMixin],
	setup() {
		return { v$: useVuelidate() };
	},
	data() {
		return {
			formData: {
				name: null,
			},
			maxNameLength: 55,
		};
	},
	methods: {
		resetFormData() {
			for (let key in this.formData) {
				this.formData[key] = this.device[key];
			}
		},
		resetForm() {
			this.v$.$reset();
			this.resetFormData();
		},
		closeModal() {
			this.modalShow = false;
			this.resetForm();
		},
		dataChanged() {
			if (this.device.id === null) {
				return false;
			}
			for (let key in this.formData) {
				if (this.formData[key] !== this.device[key]) {
					return true;
				}
			}
			return false;
		},
		async updateDevice() {
			this.v$.$touch();
			let success = true;
			if (this.v$.$error) {
				return;
			}
			if (this.formDataChanged) {
				try {
					const { data } = await this.$apollo.mutate({
						mutation: UPDATEDEVICE,
						variables: {
							deviceId: this.device.id,
							deviceData: { ...this.formData },
						},
					});
					if (data.updateDevice.success) {
						this.$emit('updateDevice', { ...data.updateDevice.device });
						this.showToast('query.success', '', 'success');
					} else {
						throw new QueryError(data.updateDevice.error);
					}
				} catch (err) {
					success = false;
					let errMsg;
					if (err instanceof QueryError) {
						errMsg = `${err.cause.type}`;
					} else {
						errMsg = 'FallbackError';
					}
					this.handleError(errMsg);
				}
			}
			if (success) this.closeModal();
		},
		handleError(msg) {
			this.showToast(`query.errors.${msg}`, '', 'danger');
		},
	},
	computed: {
		modalShow: {
			get() {
				return this.show;
			},
			set(show) {
				this.$emit('update:show', show);
			},
		},
		formDataChanged() {
			if (this.device.id === null) {
				return false;
			}
			for (let key in this.formData) {
				if (this.formData[key] !== this.device[key]) {
					return true;
				}
			}
			return false;
		},
		statusLabel() {
			return this.device.isValid
				? this.$t('status.isValid')
				: this.$t('status.isInvalid', { licenceStatus: this.licenceStatusLabel });
		},
		licenceStatusLabel() {
			if (!this.device.licence?.isActive) return this.$t('licenceStatus.notActive');
			if (this.device.licence?.isValid) return this.$t('licenceStatus.isValid');
			if (this.device.licence?.isPending) return this.$t('licenceStatus.isPending');
			if (this.device.licence?.isBlocked) return this.$t('licenceStatus.isBlocked');
			if (this.device.licence?.isExpired) return this.$t('licenceStatus.isExpired');
		},
	},
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		device: {
			type: Object,
			default: () => ({ id: null }),
		},
	},
	components: {
		VrModal,
		TextInput,
		PageSection,
		PageSectionField,
	},
	watch: {
		device() {
			this.resetFormData();
		},
	},
	validations() {
		return {
			formData: {
				name: {
					required: helpers.withMessage(this.$t('validation.name.required'), required),
					maxLength: helpers.withMessage(
						this.$t('validation.name.maxLength', { nameLength: this.maxNameLength }),
						maxLength(this.maxNameLength)
					),
				},
			},
		};
	},
};
</script>

<i18n locale="ru" src="@/locales/ru/components/modals/modal-device-data.json"></i18n>
<i18n locale="en" src="@/locales/en/components/modals/modal-device-data.json"></i18n>
