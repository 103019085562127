<template>
	<div>
		<page-section :title="$t('title')">
			<form class="change-pin-form" @submit.prevent="changePin">
				<div class="change-pin-form__group">
					<input
						class="form-control"
						:class="{ 'is-invalid': v$.pin.$error }"
						type="text"
						:placeholder="$t('pin')"
						v-model="newPin"
					/>
					<div class="invalid-tooltip">{{ v$.pin.$errors[0]?.$message }}</div>
				</div>
				<button class="btn btn-primary" type="submit">
					{{ $t('changePinBtn') }}
				</button>
			</form>
		</page-section>
	</div>
</template>

<i18n locale="ru" src="@/locales/ru/components/user-settings-view/change-pin-section.json"></i18n>
<i18n locale="en" src="@/locales/en/components/user-settings-view/change-pin-section.json"></i18n>

<script>
import PageSection from '@/components/page-sections/PageSection.vue';
import { QueryError } from '@/errors';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import CHANGEPIN from '@/queries/views/user-settings/mutation-change-pin.graphql';
import { toastMixin } from '@/mixins';

export default {
	emits: ['changePin'],
	mixins: [toastMixin],
	setup() {
		return { v$: useVuelidate() };
	},
	data() {
		return {
			newPin: null,
			pinLength: 4,
			serverError: null,
		};
	},
	props: {
		pin: {
			type: String,
			default: null,
		},
	},
	methods: {
		async changePin() {
			this.v$.$touch();
			if (this.v$.$error) {
				return;
			}
			try {
				const { data } = await this.$apollo.mutate({
					mutation: CHANGEPIN,
					variables: { newPin: this.newPin },
				});
				if (data.changeUserPin.success) {
					this.$emit('changePin', this.newPin);
					this.newPin = null;
					this.showToast('query.success.pinChanged', '', 'success');
				} else {
					throw new QueryError(data.changeUser.pin.error);
				}
			} catch (err) {
				if (err instanceof QueryError) {
					this.serverError = `errors.${err.cause.type}`;
				} else {
					this.serverError = 'errors.fallback';
				}
				this.handleError();
			} finally {
				this.v$.$reset();
			}
		},
		handleError() {
			if (this.serverError === 'errors.FallbackError') {
				this.showToast('query.errors.fallBack', '', 'danger');
			}
		},
	},
	validations() {
		return {
			pin: {
				required: helpers.withMessage(this.$t('validation.pin.required'), required),
				exactLength: helpers.withMessage(
					this.$t('validation.pin.digits', { pinLength: this.pinLength }),
					() => this.newPin.length === this.pinLength
				),
				containOnlyDigits: helpers.withMessage(
					this.$t('validation.pin.digits', { pinLength: this.pinLength }),
					() => /^\d+$/.test(this.newPin)
				),
				notSameAsNew: helpers.withMessage(this.$t('validation.pin.notSameAs'), () => this.newPin !== this.pin),
			},
		};
	},
	components: {
		PageSection,
	},
};
</script>

<style lang="scss" scoped>
.change-pin-form {
	&__group {
		position: relative;
	}

	& > * + * {
		margin-top: 1rem;
	}
}
</style>
