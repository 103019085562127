export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web client \"Vic's Science Studio\""])},
      "annotation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is a short description of each web client section. Section's visibility and available actions depend on current user permissions"])},
      "user": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
        "assignments": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignments"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["allows you to view current user assignments."])}
        },
        "settings": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["allows you to view current user account, change pin-code and password."])}
        }
      },
      "company": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
        "assignments": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignments"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["allows you to view information about all exising assignments, control them and create new ones. An assignment is a group of tasks, all of which must be completed within a limited time frame. You can view the current state of an assignment, including each user's progress, by clicking on its card."])}
        },
        "assignmentTemplates": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment templates"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["allows you to view information about all assignment templates available to your organisation, control them and create new ones. A template can only be changed or deleted if it has been created within your organisation. An assignment template is a group of tasks that can be used to create an assignment."])}
        },
        "users": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["allows you to view information about organization's users, control them and create new ones. Allows you to control your organisation's users"])}
        },
        "roles": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["allows you to view information about organization's roles, control roles within your organisation, including setting a default role, which is assigned to users that lack any role. A role is a set of permissions that determines available actions both within the web application and the laboratory."])}
        },
        "devices": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devices"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["allows you to view information about organisations's devices, control devices bound to your organisation. Further information regarding device activation can be found in dialog window \"Bind devices\" in the \"devices\" section."])}
        },
        "tasks": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["allows you to view information about organisations's tasks, control tasks available to your organisation. A task is a scenario that can be run in the laboratory app, representing an experiment or a set thereof. Tasks are created and edited using the task editor application. To use a newly created task, it must first be published. You can not change a task once it has been published and added to an assignment template."])}
        },
        "licences": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licences"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["allows you to view information about organisations's licences and control licences belonging to your organisation. A licence may have the following attributes:"])},
          "attributes": {
            "expirationTime": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["started at, ended at"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["these two dates determine time interval during which the license is active;"])}
            },
            "availableEntities": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available tasks and assignments"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["determines tasks and assignments your organisation gains access to;"])}
            },
            "maxDevicesNumber": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maximum devices number"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["determines maximum devices number that can be bound to licence at the same time;"])}
            },
            "canManageTasks": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["can manage tasks"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["grants your organisation permission to manage tasks. Users within an organisation without this permission can only view the task list and run tasks in the laboratory, but do not have access to task editor application."])}
            }
          }
        }
      },
      "info": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
        "about": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a condensed overview of web application features."])}
        }
      }
    }
  })
}
