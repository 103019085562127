export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "absenceTag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В организации пока нет тегов"])},
        "searchTag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Нет тегов, начинающихся на ", _interpolate(_named("search"))])}
      },
      "en": {
        "absenceTag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tags exist within this organisation"])},
        "searchTag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["There are no tags starting with ", _interpolate(_named("search"))])}
      }
    }
  })
}
