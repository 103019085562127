export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ru",
    "resource": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
      "statuses": {
        "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активен"])},
        "INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не активен"])},
        "ARCHIVED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивирован"])}
      },
      "tagsFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтровать по тегам"])},
      "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата регистрации"])},
      "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пин-код"])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роли"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теги"])},
      "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Примечания"])},
      "addOneUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить пользователя"])},
      "addManyUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить пользователей из файла"])},
      "editOneUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать пользователя"])},
      "editManyUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать пользователей"])},
      "archiveOneUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивировать пользователя"])},
      "archiveManyUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивировать пользователей"])},
      "unarchiveOneUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Восстановить из архива"])},
      "unarchiveManyUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Восстановить из архива"])},
      "restoreOneUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Восстановить пользователя"])},
      "restoreManyUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Восстановить пользователей"])},
      "deleteOneUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить пользователя"])},
      "deleteManyUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить пользователей"])},
      "filters": {
        "showActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показывать активных пользователей"])},
        "showInactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показывать неактивных пользователей"])},
        "showArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показывать архивных пользователей"])}
      },
      "userLimitMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Пользователей в организации: ", _interpolate(_named("userCount")), "/", _interpolate(_named("userLimit")), "."])},
      "toastSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операция успешна"])},
      "bulkCreateSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Добавлены пользователи: ", _interpolate(_named("emails"))])},
      "bulkArchiveSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Отправлены в архив пользователи: ", _interpolate(_named("emails"))])},
      "bulkRestoreSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Восстановлены пользователи: ", _interpolate(_named("emails"))])},
      "bulkDeleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи были удалены"])},
      "updateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные пользователя успешно изменены."])},
      "bulkUpdateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные пользователей успешно изменены."])},
      "toastError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка!"])},
      "bulkCreateErrorMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Произошла ошибка: \"", _interpolate(_named("errorMessage")), "\", исправьте ошибку и попробуйте загрузить следующих пользователей снова."])},
      "errors": {
        "UserPermissionError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас нет разрешения на выполнение этого действия."])},
        "NotFoundError": {
          "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь не найден."])},
          "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Организация не найдена"])},
          "Role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одна из ролей, назначенных пользователю, не найдена."])},
          "Region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регион не найден."])}
        },
        "UnauthorizedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы не вошли в систему."])},
        "CompanyMismatchError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невозможно выполнить операцию над пользователем, принадлежащим к другой организации."])},
        "UnexpectedLengthError": {
          "pin": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Неверный формат пин-кода: пин-код должен иметь ", _interpolate(_named("expected")), " цифр, а не ", _interpolate(_named("length"))])},
          "password": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Неверный формат пароля: длина пароля не должна быть меньше ", _interpolate(_named("expected")), " симоволов (сейчас ", _interpolate(_named("length")), ")"])}
        },
        "UnexpectedSymbolError": {
          "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный формат пин-кода: код содержит отличные от цифр символы"])},
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный формат пароля: пароль содержит запрещённые к использованию символы."])}
        },
        "InvalidValueError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль не должен совпадать с адресом электронной почты пользователя."])},
        "UniqueValueError": {
          "email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Пользователь с адресом электронной почты ", _interpolate(_named("value")), " уже существует."])}
        },
        "EmptyValueError": {
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь должен иметь адрес электронной почты."])}
        },
        "UserIsOwnerError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невозможно выполнить операцию над владельцем организации."])},
        "UserIsSelfError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невозможно выполнить операцию над текущим пользователем."])},
        "EntityProtectedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь может быть удалён только из корзины."])},
        "EntityLimitError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Достигнуто допустимое количество пользователей для организации."])},
        "FallbackError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка на сервере. Пожалуйста, попробуйте позже."])}
      }
    }
  })
}
