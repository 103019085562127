<template>
	<vr-base-modal v-model:show="modalShow" :size="size" :align="align" :staticBackdrop="staticBackdrop" :class="$attrs.class">
		<div :class="['vr-modal-header', { 'vr-modal-header--line': headerLine }]">
			<h5 class="vr-modal-header__title"><slot name="header" /></h5>

			<button @click="closeModal" class="btn-close vr-modal-header__close-btn"></button>
		</div>
		<div class="vr-modal-body">
			<slot name="body" />
		</div>
		<div :class="['vr-modal-footer', { 'vr-modal-footer--line': footerLine }, `vr-modal-footer--align-${footerAlign}`]">
			<slot name="footer" />
		</div>
	</vr-base-modal>
</template>

<script>
import VrBaseModal from '@/components/modals/VrBaseModal.vue';

export default {
		emits: ['close', 'update:show'],
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		// possible values (width in brackets): small (800px), medium (1100px), large (1400px)
		size: {
			type: String,
			default: 'medium',
		}, // possible values: top, center
		align: {
			type: String,
			default: 'top',
		},
		staticBackdrop: {
			type: Boolean,
			default: false,
		},
		headerLine: {
			type: Boolean,
			default: true,
		},
		footerLine: {
			type: Boolean,
			default: true,
		},
		// possible values: 'start', 'center', 'end'
		footerAlign: {
			type: String,
			default: 'end'
		},
	},
	components: {
		VrBaseModal,
	},
	computed: {
		modalShow: {
			get() {
				return this.show;
			},
			set(show) {
				this.$emit('update:show', show);
			},
		},
	},
	methods: {
		closeModal() {
			this.modalShow = false;
		},
	},
	watch: {
		modalShow(value) {
			if (!value) {
				this.$emit('close');
			}
		}
	},
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.vr-modal {
	&-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: $modal-header-padding;

		&__title {
			margin-bottom: 0;
			line-height: $modal-title-line-height;
		}

		&__close-btn {
			&,
			&:active,
			&:hover,
			&:focus {
				box-shadow: none;
			}
		}
		&--line {
			border-bottom: $modal-header-border-width solid $modal-header-border-color;
		}
	}

	&-body {
		padding: $modal-inner-padding;
		max-height: calc(min(40rem, 100vh));
		flex-grow: 1;
		overflow-y: auto;
	}

	&-footer {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		padding: calc($modal-inner-padding - $modal-footer-margin-between * 0.5);

		&--line {
			border-top: $modal-footer-border-width solid $modal-footer-border-color;
		}

		&--align-start {
			justify-content: flex-start;
		}

		&--align-center {
			justify-content: center;
		}

		&--align-end {
			justify-content: flex-end;
		}
	}
}
</style>
