export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "assignedBy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Задание назначено пользователем ", _interpolate(_named("email")), "."])},
        "assignmentStarts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Приступить к выполнению задания можно после ", _interpolate(_named("date")), "."])},
        "assignmentEnds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Задание должно быть выполнено до ", _interpolate(_named("date")), "."])},
        "assignmentEnded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Срок выполнения задания прошёл ", _interpolate(_named("date")), "."])}
      },
      "en": {
        "assignedBy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Assigned by user ", _interpolate(_named("email")), "."])},
        "assignmentStarts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You may start work on this assignment after ", _interpolate(_named("date")), "."])},
        "assignmentEnds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Assignment is due ", _interpolate(_named("date")), "."])},
        "assignementEnded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Assignment due date was ", _interpolate(_named("date")), "."])}
      }
    }
  })
}
