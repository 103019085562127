export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load users from file"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a .csv file, each line of which conforms to the sample below."])},
      "userLimitAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can not add users. Doing so will exceed your organisation's user limit."])},
      "sampleTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File sample"])},
      "sampleEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["user", "@", "domain.tld"])},
      "emailNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User email address. Mandatory."])},
      "samplePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password"])},
      "passwordNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User password. Must be at least 8 characters long. If not provided, will be automatically generated."])},
      "sampleNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
      "samplePin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1234"])},
      "pinNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User pin-code. Must containt exactly 4 digits. If not provided, it will be automatically generated."])},
      "usersToAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These users will be added:"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin-code"])},
      "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
      "fileParseFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File format invalid."])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load users"])}
    }
  })
}
