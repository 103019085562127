import base from './base';
import { permissionComputeds, authorOrPermissionMethods } from './base';

export default {
	mixins: [base],
	methods: authorOrPermissionMethods(
		'CHANGE_DEVICE',
		'DELETE_DEVICE'
	),
	computed: permissionComputeds('VIEW_DEVICE', 'ADD_DEVICE'),
};
