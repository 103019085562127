export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add devices"])},
      "dropdown": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licence"])}
      },
      "instruction": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to add new devices"])},
        "list": {
          "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a licence from the dropdown and enter the number of activation codes you need into the input field. Press the \"Create\" button to get activation codes."])},
          "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Vic's Science Studio app on the device you wish to add. Press \"Activate\" on title screen and enter on of the activation codes you received in the previous step."])}
        },
        "note": {
          "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*For each device you add to a licence you require a separate activation key."])},
          "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["**Activation keys have a limited lifetime (15 minutes). To get an up-to-date list of activation codes for a licence press \"Refresh\"."])},
          "third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["***If an activation code has expired without being used, you can issue a new key that will replace it."])}
        }
      },
      "refreshBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
      "endedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["valid until:"])},
      "licenceInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LICENCE INFO"])},
      "activationCodesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACTIVATION CODES"])},
      "indefinitely": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indefinitely"])},
      "fields": {
        "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licence key"])},
        "devicesNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["devices"])},
        "availableKeys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available keys"])}
      },
      "codesNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation codes num."])},
      "createActivationCodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
      "errors": {
        "ValueOutOfBounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available activation code number exceeded."])}
      }
    }
  })
}
