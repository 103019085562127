<template>
	<div class="page-content about-page lead">
		<div class="about-page__header">
			<img :src="logoPath" />
			<h3>{{ $t("title") }}</h3>
		</div>
		<p>{{ $t("annotation") }}</p>
		<h4>{{ $t("user.name") }}</h4>
		<ul class="ul-primary">
			<li>
				<fa-icon icon="fa-solid fa-clipboard-list" /> <span>{{ $t("user.assignments.name") }}</span> - {{ $t("user.assignments.description") }}
			</li>
			<li>
				<fa-icon icon="fa-solid fa-wrench" /> <span>{{ $t("user.settings.name") }}</span> - {{ $t("user.settings.description") }}
			</li>
		</ul>
		<h4>{{ $t('company.name') }}</h4>
		<ul class="ul-primary">
			<li>
				<fa-icon icon="fa-solid fa-clipboard-list" /> <span>{{ $t("company.assignments.name") }}</span> - {{ $t("company.assignments.description") }}
			</li>
			<li>
				<fa-icon icon="fa-solid fa-file-invoice" /> <span>{{ $t("company.assignmentTemplates.name") }}</span> - {{ $t("company.assignmentTemplates.description") }}
			</li>
			<li>
				<fa-icon icon="fa-solid fa-user-group" /> <span>{{ $t("company.users.name") }}</span> - {{ $t("company.users.description") }}
			</li>
			<li>
				<fa-icon icon="fa-solid fa-users-rectangle" /> <span>{{ $t("company.roles.name") }}</span> - {{ $t("company.roles.description") }}
			</li>
			<li>
				<fa-icon icon="fa-solid fa-vr-cardboard" /> <span>{{ $t("company.devices.name") }}</span> - {{ $t("company.devices.description") }}
			</li>
			<li>
				<fa-icon icon="fa-solid fa-book-open" /> <span>{{ $t("company.tasks.name") }}</span> - {{ $t("company.tasks.description") }}
			</li>
			<li>
				<fa-icon icon="fa-solid fa-clipboard" /> <span>{{ $t("company.licences.name") }}</span> - {{ $t("company.licences.description") }}

				<ul class="ul-secondary">
					<li>
						<span>{{ $t("company.licences.attributes.expirationTime.name") }}</span> - {{ $t("company.licences.attributes.expirationTime.description") }}
					</li>
					<li>
						<span>{{ $t("company.licences.attributes.availableEntities.name") }}</span> - {{ $t("company.licences.attributes.availableEntities.description") }}
					</li>
					<li>
						<span>{{ $t("company.licences.attributes.maxDevicesNumber.name") }}</span> - {{ $t("company.licences.attributes.maxDevicesNumber.description") }}
					</li>
					<li>
						<span>{{ $t("company.licences.attributes.canManageTasks.name") }}</span> - {{ $t("company.licences.attributes.canManageTasks.description") }}
					</li>
				</ul>
			</li>
		</ul>
		<h4>{{ $t("info.name") }}</h4>
		<ul class="ul-primary">
			<li>
				<fa-icon icon="fa-solid fa-circle-question" /> <span>{{ $t("info.about.name") }}</span> - {{ $t("info.about.description") }}
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	computed: {
		logoPath() {
			const name = this.$i18n.locale === 'ru' ? 'vrchemlab' : 'vics-studio';
			return require(`@/assets/img/${name}-logo.png`);
		},
	},
};
</script>

<i18n locale="ru" src="@/locales/ru/views/about.json"></i18n>
<i18n locale="en" src="@/locales/en/views/about.json"></i18n>

<style lang="scss" scoped>
.about-page {
	padding-left: 4rem;

	&__header {
		display: flex;
		align-items: flex-end;
		gap: 0.5rem;
		padding-left: 2rem;
		margin-bottom: 1rem;

		img {
			max-width: 3rem;
			height: auto;
		}

		h3 {
			font-weight: bold;
			margin-bottom: 0;
		}
	}

	p {
		text-indent: 2rem;
	}

	.ul-primary > li {
		margin-bottom: 1rem;
	}

	.ul-primary > li > span {
		font-weight: bold;
	}

	.ul-secondary {
		margin-top: 1rem;
	}

	.ul-secondary > li > span {
		font-style: italic;
		font-weight: bolder;
	}
}
</style>
