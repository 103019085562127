export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ru",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Смена пароля"])},
      "fields": {
        "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущий пароль"])},
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый пароль"])},
        "confirmNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторите новый пароль"])}
      },
      "changePasswordBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить"])},
      "validation": {
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите пароль"])},
          "sameAs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароли не совпадают"])},
          "notSameAs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Старый и новый пароли должны отличаться"])},
          "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Пароль должен состоять не менее чем из ", _interpolate(_named("passwordLength")), " символов"])},
          "whiteSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль не должен содержать пробелов и отступов"])}
        }
      },
      "query": {
        "success": {
          "passwordChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль успешно изменён."])}
        },
        "errors": {
          "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Указан неверный текущий пароль."])},
          "fallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка соединения. Попробуйте позже."])}
        }
      }
    }
  })
}
