export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновление пользовательского соглашения"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добрый день! Мы обновили пользовательское соглашение и правила пользования приложением, и для продолжения работы с лабораторией просим подтвердить ваше согласие с ними. Полный текст пользовательского соглашения можно найти "])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["по этой ссылке"])},
        "checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я ознакомился и согласен с пользовательским соглашением"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить работу"])},
        "query": {
          "updateCompany": {
            "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы успешно подписали пользовательское соглашение"])},
            "errors": {
              "FallbackError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка соединения. Попробуйте позже."])}
            }
          }
        }
      },
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User agreement"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello, we will update your user agreement soon. In the meantime, you can continue working"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to continue working"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
        "query": {
          "updateCompany": {
            "sucsess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "error": {
              "FallbackError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
            }
          }
        }
      }
    }
  })
}
