<template>
	<router-view name="navbar" />
	<router-view name="sidebar" />
	<router-view name="content" />
	<router-view name="footbar" />
	<user-agreement v-model:show="showAgreement" />
	<cookie-toast />
	<toast-controller />
</template>

<style lang="scss">
@import 'overrides';
@import 'bootstrap/scss/bootstrap';
@import './assets/styles/popper.css';
@import 'node_modules/@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss';
@import 'global';
</style>

<i18n>
	{
		"ru": {
			"title": "VR Chemistry LAB",
		},
		"en": {
			"title": "Vic's Science Studio",
		}
	}
</i18n>

<script>
import FAVICON_RU from '@/assets/favicons/vrchemlab.png';
import FAVICON_EN from '@/assets/favicons/vics-studio.png';
import CookieToast from '@/components/CookieToast.vue';
import ToastController from '@/components/toasts/Controller';
import UserAgreement from '@/components/user-agreement/UserAgreementModal.vue';

const ICONS = {
	ru: FAVICON_RU,
	en: FAVICON_EN,
};

export default {
	data() {
		return {
			user: {},
			showTaskCreate: true,
			showAgreement: false,
		};
	},

	components: {
		CookieToast,
		ToastController,
		UserAgreement,
	},
	computed: {
		showAcceptAgreement() {
			return this.$store.state.auth.user.showAcceptAgreement;
		},
	},
	watch: {
		showAcceptAgreement(val) {
			this.showAgreement = val;
		},
	},
	created() {
		const title = this.$t('title');
		const icon = ICONS[this.$i18n.locale];
		document.title = title;
		const iconEl = document.querySelector('link[rel="icon"]');
		iconEl.setAttribute('type', 'image/png');
		iconEl.setAttribute('href', icon);
	},
};
</script>
