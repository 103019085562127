export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ru",
    "resource": {
      "createModalHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание нового тега"])},
      "createModalBody": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вы уверены, что хотите создать новый тег \"", _interpolate(_named("tag")), "\"?"])},
      "deleteModalHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаление тега"])},
      "deleteModalBody": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вы уверены, что хотите удалить тег \"", _interpolate(_named("tag")), "\"?"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
      "successes": {
        "create": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Тег ", _interpolate(_named("tag")), " успешно создан."])},
        "delete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Тег ", _interpolate(_named("tag")), " успешно удалён."])}
      },
      "errors": {
        "UniqueValueError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тег стаким именем уже существует."])},
        "FallbackError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка на сервере. Пожалуйста, попробуйте позже."])}
      }
    }
  })
}
