<template>
	<vr-modal v-model:show="modalShow" @close="resetForm" size="small" align="center">
		<template v-slot:header> {{ $t('modalAddLicence.title') }} </template>
		<template v-slot:body>
			<form class="form-data" @submit.prevent="activateByCompany">
				<div class="form-group">
					<input
						v-model="licenceKey"
						type="text"
						:class="['form-control', { 'is-invalid': v$.licenceKey.$error }]"
						:placeholder="$t('modalAddLicence.fieldPlaceholder')"
					/>
					<div class="invalid-tooltip">
						{{ v$.licenceKey.$error && $t(v$.licenceKey.$errors[0]?.$message) }}
					</div>
				</div>
			</form>
		</template>
		<template v-slot:footer>
			<button @click="closeModal" class="btn btn-secondary">{{ $t('modalAddLicence.cancelBtn') }}</button>
			<button @click="activateByCompany" type="submit" class="btn btn-success">
				{{ $t('modalAddLicence.submitBtn') }}
			</button>
		</template>
	</vr-modal>
</template>

<script>
import { mapState } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import { QueryError } from '@/errors';
import ACTIVATEBYCOMPANY from '@/queries/views/licences/mutation-activate-by-company.graphql';
import VrModal from '@/components/modals/VrModal.vue';
import { toastMixin } from '@/mixins';

export default {
	emits: ['licenceAdded', 'update:show'],
	mixins: [toastMixin],
	setup() {
		return { v$: useVuelidate() };
	},
	data() {
		return {
			licenceKey: null,
			queryError: null,
			timeout: null,
		};
	},
	props: {
		show: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		resetForm() {
			this.licenceKey = null;
			this.v$.$reset();
		},
		closeModal() {
			this.modalShow = false;
			this.resetForm();
		},
		async activateByCompany() {
			this.v$.$touch();
			if (this.v$.$error) {
				return;
			}
			try {
				const { data } = await this.$apollo.mutate({
					mutation: ACTIVATEBYCOMPANY,
					variables: { key: this.licenceKey.trim() },
				});
				if (data.activateByCompany.success) {
					this.$emit('licenceAdded', { ...data.activateByCompany.licence });
					if (data.activateByCompany.licence.canManageTasks) {
						this.$store.commit('auth/updateFieldCanManageTasks', true);
					}
					this.showToast('successes.bounded', '', 'success');
					this.closeModal();
				} else {
					throw new QueryError(data.activateByCompany.error);
				}
			} catch (err) {
				if (err instanceof QueryError) {
					this.handleError(err, `errors.${err.cause.type}`);
				} else {
					this.handleError(err, 'errors.FallbackError');
				}
			} finally {
				this.v$.$reset();
			}
		},
		handleError(err, msg) {
			if (msg === 'errors.NotFoundError') {
				this.showToast('errors.notFound', '', 'danger');
			}
			if (msg === 'errors.MutationError' && err.cause.field === 'company') {
				this.showToast('errors.alreadyActivated', '', 'danger');
			}
			if (msg === 'errors.InvalidValueError') {
				this.showToast('errors.invalidValue', '', 'danger');
			}
		},
	},
	computed: {
		...mapState('auth', ['company']),
		modalShow: {
			get() {
				return this.show;
			},
			set(show) {
				this.$emit('update:show', show);
			},
		},
	},
	validations() {
		return {
			licenceKey: {
				required: helpers.withMessage('validations.EmptyLicenceKey', required),
			},
		};
	},

	components: {
		VrModal,
	},
};
</script>

<i18n locale="ru" src="@/locales/ru/components/modals/modal-add-licence.json"></i18n>
<i18n locale="en" src="@/locales/en/components/modals/modal-add-licence.json"></i18n>

<style lang="scss" scoped>
@import '@/assets/styles/form.scss';
</style>
