export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ru",
    "resource": {
      "productName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VR Chemistry Lab"])},
      "sections": {
        "user": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь"])},
          "links": {
            "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["профиль"])},
            "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["задания"])}
          }
        },
        "company": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Организация"])},
          "links": {
            "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["настройки"])},
            "licences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["лицензии"])},
            "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["задачи"])},
            "devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["устройства"])},
            "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["пользователи"])},
            "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["задания"])},
            "assignmentTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["шаблоны заданий"])},
            "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["роли"])}
          }
        },
        "info": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация"])},
          "links": {
            "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["справка"])},
            "userAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["пользовательское соглашение"])}
          }
        }
      }
    }
  })
}
