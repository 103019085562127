export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ru",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Установите новый пароль"])},
      "passwordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
      "confirmPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторите пароль"])},
      "submitBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить пароль"])},
      "obtainLinkAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получить ссылку повторно"])},
      "toLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти к авторизации"])},
      "validation": {
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["введите пароль"])},
          "sameAs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["пароли не совпадают"])},
          "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["пароль должен состоять не менее чем из ", _interpolate(_named("passwordLength")), " символов"])},
          "whiteSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["пароль не должен содержать пробелов и отступов"])}
        }
      },
      "query": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль успешно изменён."])},
        "error": {
          "invalidTokenValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка для восстановления пароля была уже использована, либо время её действия закончилось."])},
          "invalidPasswordValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль не может совпадать с почтовым адресом пользователя."])},
          "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка запроса. Пожалуйста, попробуйте повторить попытку позже."])}
        }
      }
    }
  })
}
