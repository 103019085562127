export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ru",
    "resource": {
      "emptyTablePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас ещё нет лицензий"])},
      "noContentPacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["---"])},
      "filters": {
        "showValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активные лицензии"])},
        "showInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неактивные лицензии"])}
      },
      "licencesTable": {
        "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список лицензий"])},
        "headers": {
          "contentPacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контент паки"])},
          "endedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действует до"])},
          "isValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активная"])},
          "devicesNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Устройства"])}
        }
      },
      "activateLicence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активировать лицензию"])},
      "indefinitely": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бессрочно"])}
    }
  })
}
