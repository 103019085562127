export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change pin-code"])},
      "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New pin-code"])},
      "changePinBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
      "validation": {
        "pin": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter pin-code."])},
          "digits": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pin-code must be ", _interpolate(_named("pinLength")), " digits long."])},
          "notSameAs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old and new pin-codes must be different."])}
        }
      },
      "query": {
        "success": {
          "pinChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin-code change successful."])}
        },
        "errors": {
          "fallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occured. Please try again later."])}
        }
      }
    }
  })
}
