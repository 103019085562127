//based on https://github.com/vue-bulma/click-outside/blob/master/index.js

function validateCallback(cb) {
	if (typeof cb !== 'function') {
		console.warn('Click outside callback must be a function');
		return false;
	} else {
		return true;
	}
}

export const clickOutside = {
	mounted(el, { value: callback }) {
		if (!validateCallback(callback)) {
			return;
		}

		function handler(e) {
			if (!el.contains(e.target)) {
				el.__clickOutside__.callback(e);
			}
		}

		el.__clickOutside__ = {
			handler,
			callback,
		};
		const clickHandler = 'ontouchstart' in document.documentElement ? 'touchstart' : 'click';
		document.addEventListener(clickHandler, handler);
	},

	updated(el, { value: callback }) {
		if (validateCallback(callback)) {
			el.__clickOutside__.callback = callback;
		}
	},

	beforeUnmount(el) {
		const clickHandler = 'ontouchstart' in document.documentElement ? 'touchstart' : 'click';
		el.__clickOutside__ && document.removeEventListener(clickHandler, el.__clickOutside__.handler);
		delete el.__clickOutside__;
	},
};
