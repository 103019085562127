import dayjs from 'dayjs';

export default {
	methods: {
		formatDateTime(date) {
			if (!date) {
				return '';
			} else {
				return dayjs(date).format('DD.MM.YYYY; HH:mm');
			}
		},
		formatDate(date) {
			if (!date) {
				return '';
			} else {
				return dayjs(date).format('DD.MM.YYYY');
			}
		},
	},
};
