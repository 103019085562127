<template>
	<div class="card group-card">
		<div class="card-header d-flex align-items-center">
			<input
				type="checkbox"
				:id="`group-${localGroup.id}-ordered`"
				v-model="localGroup.ordered"
				:disabled="!isEditable"
				hidden
			/>
			<label
				:for="`group-${localGroup.id}-ordered`"
				class="btn"
				:class="{ 'btn-primary': localGroup.ordered, 'btn-secondary': !localGroup.ordered }"
				:title="$t('ordered')"
				><fa-icon icon="fa-solid fa-arrow-down-1-9"
			/></label>
			<button class="btn-close ms-auto" @click="$emit('remove', localGroup)" :disabled="!inputsEnabled"></button>
		</div>
		<div class="card-body">
			<draggable
				class="group-tasks"
				v-model="localGroup.tasks"
				group="tasks"
				item-key="id"
				:disabled="!inputsEnabled"
			>
				<template #item="{ index }">
					<div>
						<task-card
							v-model:task="localGroup.tasks[index]"
							@remove="removeTask"
							:isEditable="isEditable"
							:inputsEnabled="inputsEnabled"
						/>
					</div>
				</template>
				<template #footer>
					<p v-show="!localGroup.tasks.length" class="empty-message">
						{{ $t('emptyMessage') }}
					</p>
				</template>
			</draggable>
		</div>
	</div>
</template>

<i18n>
	{
		"ru": {
			"ordered": "Последовательное выполнение",
			"emptyMessage": "Перетащите задачи сюда"
		},
		"en": {
			"ordered": "Ordered",
			"emptyMessage": "Drag tasks here"
		}
	}
</i18n>

<script>
import Draggable from 'vuedraggable';
import TaskCard from '@/components/assignment-editor/TaskCard';
import { nanoid } from 'nanoid';

export default {
	emits: ['update:group', 'remove'],
	methods: {
		removeTask(task) {
			this.localGroup.tasks = this.localGroup.tasks.filter(t => t !== task);
		},
	},
	props: {
		group: {
			type: Object,
			default: () => ({ id: nanoid(), ordered: false, tasks: [] }),
		},
		isEditable: {
			type: Boolean,
			default: true,
		},
		inputsEnabled: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		localGroup: {
			get() {
				return this.group;
			},
			set(group) {
				this.$emit('update:group', group);
			},
		},
	},
	components: {
		Draggable,
		TaskCard,
	},
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.group-card {
	.card-body {
		.group-tasks {
			display: flex;
			flex-direction: column;
			gap: 1rem;

			.empty-message {
				color: $text-muted;
				border: 1px dashed $border-color;
				border-radius: $border-radius;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
			}
		}
	}
}
</style>
