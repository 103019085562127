<template>
	<teleport to="#toast-container">
		<div v-show="visible" class="toast show cookie-toast">
			<div class="toast-header cookie-toast-header text-bg-info">
				<fa-icon icon="fa-solid fa-cookie-bite" />
				<span>{{ $t('title') }}</span>
			</div>
			<div class="toast-body cookie-toast-body">
				<p>{{ $t('body') }}</p>
				<button @click="accept" class="btn btn-outline-info btn-sm">OK</button>
			</div>
		</div>
	</teleport>
</template>

<i18n>
	{
		"ru": {
			"title": "Cookies",
			"body": "Этот сайт использует cookies для хранения данных. Продолжая использовать сайт, вы даёте согласие на работу с этими файлами."
		},
		"en": {
			"title": "Cookies",
			"body": "This site uses cookies for data storage. By continuing to use site you consent to the use cookies."
		}
	}
</i18n>

<script>
export default {
	data() {
		return {
			visible: !localStorage.getItem('cookiesAccepted'),
		};
	},
	methods: {
		accept() {
			localStorage.setItem('cookiesAccepted', true);
			this.visible = false;
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.cookie-toast {
	&-header {
		display: flex;
		align-items: center;
		gap: 0.5rem;
	}

	&-body {
		background-color: $cyan-100;
		button {
			width: 100%;
		}
	}
}
</style>
