export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ru",
    "resource": {
      "newAssignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый шаблон задания"])},
      "assignAssignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назначить задание"])},
      "filters": {
        "showEnded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показывать завершившиеся"])},
        "showActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показывать текущие"])},
        "showPending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показывать запланированные"])},
        "showComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показывать завершённые"])},
        "showIncomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показывать незавершённые"])}
      },
      "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет сортировки"])},
      "startedAtAsc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По времени начала (сначала старые)"])},
      "startedAtDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По времени начала (сначала новые)"])},
      "endedAtAsc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По времени окончания (сначала старые)"])},
      "endedAtDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По времени окончания (сначала новые)"])},
      "toast": {
        "deleteSuccessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно!"])},
        "deleteSuccessText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назначенное задание отменено."])},
        "deleteErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка!"])},
        "deleteErrorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка при отмене задания. Пожалуйста, попробуйте ещё раз."])}
      },
      "emptyPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас ещё нет заданий"])}
    }
  })
}
