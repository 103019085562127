export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "startsAt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Начало: ", _interpolate(_named("date"))])},
        "endsAt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Конец: ", _interpolate(_named("date"))])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь"])},
        "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готово"])},
        "andMore": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["и ещё ", _interpolate(_named("count"))])}
      },
      "en": {
        "startsAt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Start date: ", _interpolate(_named("date"))])},
        "endsAt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["End date: ", _interpolate(_named("date"))])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
        "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
        "andMore": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["and ", _interpolate(_named("count")), " more"])}
      }
    }
  })
}
