import { apollo } from '@/apollo';
import LOGIN from '@/queries/auth/login.graphql';
import LOGOUT from '@/queries/auth/logout.graphql';
import ME from '@/queries/auth/me.graphql';
import { QueryError } from '@/errors';

export default {
	namespaced: true,
	state: {
		user: {
			id: null,
			email: null,
			isSuperuser: false,
			isStaff: false,
			permissions: null,
			ownedCompanyId: null,
		},
		company: {
			id: null,
			name: null,
			canManageTasks: null,
		},
	},
	getters: {
		isLoggedIn(state) {
			return !!state.user.id;
		},
		isSuperOrStaff(state) {
			return state.user.isSuperuser || state.user.isStaff;
		},
		canManageTasks(state) {
			return state.company.canManageTasks || state.user.isSuperuser || state.user.isStaff;
		},
		isCompanyOwner(state) {
			if (!state.user?.id || !state.company?.id) {
				return false;
			}
			if (state.user.isSuperuser) {
				return true;
			}
			if (!state.user.ownedCompanyId) {
				return false;
			}
			return state.user.ownedCompanyId === state.company.id;
		},
	},
	mutations: {
		signAgreement(state) {
			state.user.showAcceptAgreement = false;
		},
		setUser(state, { id, email, permissions, isSuperuser, isStaff, ownedCompany, showAcceptAgreement } = {}) {
			state.user.id = id;
			state.user.email = email;
			state.user.isSuperuser = isSuperuser;
			state.user.isStaff = isStaff;
			state.user.permissions = permissions;
			state.user.ownedCompanyId = ownedCompany?.id;
			state.user.showAcceptAgreement = showAcceptAgreement
		},
		setCompany(state, { id, name, canManageTasks } = {}) {
			state.company.id = id;
			state.company.name = name;
			state.company.canManageTasks = canManageTasks;
		},
		deleteUser(state) {
			state.user.id = null;
			state.user.email = null;
			state.user.isSuperuser = false;
			state.user.isStaff = false;
			state.user.permissions = null;
			state.user.showAcceptAgreement = false
		},
		deleteCompany(state) {
			state.company.id = null;
			state.company.name = null;
			state.company.canManageTasks = null;
		},
		updateFieldCanManageTasks(state, canManageTasks) {
			state.company.canManageTasks = canManageTasks;
		}
	},
	actions: {
		async login({ commit }, { email, password }) {
			const { data } = await apollo.mutate({ mutation: LOGIN, variables: { data: { email, password } } });
			if (data.login.success) {
				commit('setUser', data.login.user);
				commit('setCompany', data.login.user.company);
			} else {
				throw new QueryError(data.login.error);
			}
		},
		async logout({ commit }) {
			const { data } = await apollo.mutate({ mutation: LOGOUT });
			if (data.logout.success) {
				commit('deleteUser');
				commit('deleteCompany');
			}
		},
		async me({ commit }, { force } = {}) {
			const { data } = await apollo.query({
				query: ME,
				fetchPolicy: force ? 'network-only' : 'cache-first',
				context: { noBatch: true },
			});
			commit('setUser', data.me);
			commit('setCompany', data.me.company);
		},
	},
};
