<template>
	<vr-modal v-model:show="modalShow" @close="resetForm" size="medium" align="center" :footer-line="false">
		<template v-slot:header> {{ $t('title') }}</template>
		<template v-slot:body>
			<form class="create-task-form" @submit.prevent="createTask">
				<div class="form-group">
					<label>{{ $t('fields.tags') }}</label>
					<!-- condition !(isStaff || isSuperuser) temporary disables possibility to add tags at new licence tasks by superuser and staff -->
					<tag-list
						v-if="modalShow && !(isStaff || isSuperuser)"
						v-model:tags="taskData.tags"
						v-model:all-tags="localTags"
						@deleteTag="$emit('deleteTag', $event)"
						:allow-create="canAddTag"
						:allow-delete="canDeleteTag"
						editable
					/>
				</div>
				<text-input
					v-model:value="taskData.name"
					inputId="taskName"
					:placeholder="$t('fields.name')"
					:error="v$.taskData.name.$error"
					:errorMsg="v$.taskData.name.$errors[0]?.$message"
					:disabled="!canAddTask"
					required
				/>
				<text-area-input
					v-model:value="taskData.annotation"
					inputId="taskAnnotation"
					:placeholder="$t('fields.annotation')"
					:disabled="!canAddTask"
				/>
				<text-input
					v-model:value="taskData.passingGrade"
					inputId="taskPassingGrade"
					:placeholder="$t('fields.passingGrade')"
					:error="v$.taskData.passingGrade.$error"
					:errorMsg="v$.taskData.passingGrade.$errors[0]?.$message"
					:disabled="!canAddTask"
				/>
			</form>
		</template>
		<template v-slot:footer>
			<button @click="closeModal" class="btn btn-secondary">{{ $t('cancelBtn') }}</button>
			<button @click="createTask" type="submit" class="btn btn-success">
				{{ $t('submitBtn') }}
			</button>
		</template>
	</vr-modal>
</template>

<i18n locale="ru" src="@/locales/ru/components/modals/modal-add-task.json"></i18n>
<i18n locale="en" src="@/locales/en/components/modals/modal-add-task.json"></i18n>

<script>
import { QueryError } from '@/errors';
import useVuelidate from '@vuelidate/core';
import { required, maxLength, helpers } from '@vuelidate/validators';
import VrModal from '@/components/modals/VrModal.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import TextAreaInput from '@/components/inputs/TextAreaInput.vue';
import taskPermissionsMixin from '@/mixins/permissions/taskPermissions.js';
import TagList from '@/components/TagList.vue';
import _ from 'lodash';
import { toastMixin, tagPermissionsMixin } from '@/mixins';
import CREATETASK from '@/queries/views/tasks/mutation-create-task.graphql';
import { mapState } from 'vuex';

export default {
	setup() {
		return { v$: useVuelidate() };
	},
	emits: ['createTask', 'update:show'],
	mixins: [toastMixin, taskPermissionsMixin, tagPermissionsMixin],
	data() {
		return {
			taskData: {
				name: null,
				annotation: null,
				passingGrade: null,
				platform: null,
				tags: [],
			},
			maxNameLength: 55,
			maxGradeVal: 999,
		};
	},
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		tags: {
			type: Array,
			default: () => [],
		},
	},
	components: {
		VrModal,
		TextInput,
		TextAreaInput,
		TagList,
	},
	methods: {
		resetForm() {
			this.v$.$reset();
			for (let key in this.taskData) {
				if (_.isString(this.taskData[key]) || _.isNumber(this.taskData[key]) || this.taskData[key] === null) {
					this.taskData[key] = null;
				}
				if (_.isArray(this.taskData[key])) this.taskData[key] = [];
				if (_.isPlainObject(this.taskData[key])) this.taskData[key] = {};
			}
		},
		closeModal() {
			this.modalShow = false;
			this.resetForm();
		},
		async createTask() {
			this.v$.$touch();
			let success = true;
			if (this.v$.$error) {
				return;
			}
			try {
				this.taskData.passingGrade = !this.taskData.passingGrade ? 0 : +this.taskData.passingGrade;
				this.taskData.platform = 'VR'; // TODO: add platform choise in futute ("WEB" or "VR")

				const { data } = await this.$apollo.mutate({
					mutation: CREATETASK,
					variables: { data: { ...this.taskData, tags: this.taskData.tags.map(tag => tag.id) } },
					update(cache, { data }) {
						cache.modify({
							id: cache.identify(data.createTask.task.company),
							fields: {
								tasks(existingTasks) {
									const ref = cache.identify(data.createTask.task);
									return [...existingTasks, { __ref: ref }];
								},
							},
						});
					},
				});
				if (data.createTask.success) {
					this.showToast('query.success', '', 'success');
				} else {
					throw new QueryError(data.createTask.error);
				}
			} catch (err) {
				success = false;
				let errorMsg;
				if (err instanceof QueryError) {
					errorMsg = `${err.cause.type}`;
				} else {
					errorMsg = 'FallbackError';
				}
				this.handleError(errorMsg);
			}
			if (success) this.closeModal();
		},
		handleError(msg) {
			this.showToast(`query.errors.${msg}`, '', 'danger');
		},
	},

	computed: {
		modalShow: {
			get() {
				return this.show;
			},
			set(show) {
				this.$emit('update:show', show);
			},
		},
		localTags: {
			get() {
				return this.tags;
			},
			set(tags) {
				this.$emit('update:tags', tags);
			},
		},
		...mapState('auth', {
			canManageTasks: state => state.company.canManageTasks,
			isSuperuser: state => state.user.isSuperuser,
			isStaff: state => state.user.isStaff,
		}),
	},
	validations() {
		return {
			taskData: {
				name: {
					required: helpers.withMessage(this.$t('validation.name.required'), required),
					maxLength: helpers.withMessage(
						this.$t('validation.name.maxLength', { nameLength: this.maxNameLength }),
						maxLength(this.maxNameLength)
					),
				},
				passingGrade: {
					format: helpers.withMessage(
						this.$t('validation.passingGrade.format', { gradeVal: this.maxGradeVal }),
						() =>
							+this.taskData.passingGrade >= 0 &&
							+this.taskData.passingGrade <= this.maxGradeVal &&
							Number.isInteger(+this.taskData.passingGrade)
					),
				},
			},
		};
	},
};
</script>

<style lang="scss" scoped>
.create-task-form {
	&__group {
		position: relative;
	}

	& > * + * {
		margin-top: 1rem;
	}

	&:last-child {
		margin-bottom: 1rem;
	}
}

.form-switch {
	label {
		margin-right: 1rem;
	}
}

::v-deep(.popper) {
	max-width: 40rem;
}
</style>
