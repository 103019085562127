export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ru",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Смена пин-кода"])},
      "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый пин-код"])},
      "changePinBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить"])},
      "validation": {
        "pin": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите пин-код"])},
          "digits": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Пин-код должно состоять из ", _interpolate(_named("pinLength")), " цифр"])},
          "notSameAs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Старый и новый пин-коды не должны совпадать"])}
        }
      },
      "query": {
        "success": {
          "pinChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пин-код успешно изменён."])}
        },
        "errors": {
          "fallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка соединения. Попробуйте позже."])}
        }
      }
    }
  })
}
