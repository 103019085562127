<template>
	<div class="forgot-password">
		<div class="card text-dark bg-light">
			<div class="card-header">{{ $t('title') }}</div>
			<form class="card-body forgot-password__form" @submit.prevent="sendResetLink">
				<div class="forgot-password__form__group">
					<input
						class="form-control"
						:class="{ 'is-invalid': v$.email.$error }"
						type="text"
						:placeholder="$t('emailPlaceholder')"
						v-model="email"
					/>
					<div class="invalid-tooltip">{{ v$.email.$errors[0]?.$message }}</div>
					<span>{{ $t('tooltip') }}</span>
				</div>
				<div v-show="alert.show" :class="['alert', `alert-${alert.type}`]">
					{{ alert.message }}
				</div>
				<button class="btn btn-primary" type="submit">{{ $t('submitBtn') }}</button>
			</form>
		</div>
	</div>
</template>

<i18n locale="ru" src="@/locales/ru/views/forgot-password.json"></i18n>
<i18n locale="en" src="@/locales/en/views/forgot-password.json"></i18n>

<script>
import useVuelidate from '@vuelidate/core';
import PasswordInput from '@/components/inputs/PasswordInput.vue';
import { required, helpers } from '@vuelidate/validators';
import { QueryError } from '@/errors';
import SENDRESETLINK from '@/queries/views/forgot-password/mutation-send-reset-link.graphql';

export default {
	setup() {
		return { v$: useVuelidate() };
	},
	data() {
		return {
			email: null,
			serverError: null,
			alert: { show: false, type: null, message: null },
		};
	},
	validations() {
		return {
			email: {
				required: helpers.withMessage(this.$t('validation.email.required'), required),
			},
		};
	},
	methods: {
		setAlert(show, type, message) {
			this.alert.show = show;
			this.alert.type = type;
			this.alert.message = message;
		},
		async sendResetLink() {
			this.setAlert(false, '', '');
			this.v$.$touch();
			if (this.v$.$error) {
				return;
			}
			try {
				const { data } = await this.$apollo.mutate({
					mutation: SENDRESETLINK,
					variables: { email: this.email },
				});
				if (data.sendResetLink.success) {
					this.setAlert(true, 'success', this.$t('query.success'));
					this.email = null;
				} else {
					throw new QueryError(data.sendResetLink.error);
				}
			} catch (err) {
				if (err instanceof QueryError) {
					this.serverError = `errors.${err.cause.type}`;
				} else {
					this.serverError = 'errors.FallbackError';
				}
				this.handleError(err);
			} finally {
				this.v$.$reset();
			}
		},
		handleError(err) {
			if (this.serverError === 'errors.NotFoundError' && err.cause.entity === 'User') {
				this.setAlert(true, 'danger', this.$t('query.error.notFound'));
			}
		},
	},
	components: {
		PasswordInput,
	},
};
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.forgot-password {
	grid-column: 1 / -1;
	grid-row: 1 / -1;

	display: flex;
	justify-content: center;
	align-items: center;

	height: 100vh;

	&__form {
		width: 40rem;
		display: flex;
		flex-direction: column;

		& > * + * {
			margin-top: 1rem;
		}

		&__group {
			position: relative;

			.invalid-tooltip {
				position: absolute;
				top: 2.5rem;
				left: 0;
			}

			span {
				display: block;
				margin: 0.5rem 0 0 0.25rem;
				color: $gray-600;
			}
		}

		.alert {
			margin-bottom: 0;
		}
	}
}
</style>
