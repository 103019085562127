export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ru",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка пользователей из файла"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите файл формата .csv, каждая строка которого соответствует образцу ниже."])},
      "userLimitAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавление пользователей невозможно, так как в результате будет превышено максимальное количество пользователей для организации."])},
      "sampleTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Образец файла"])},
      "sampleEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["user", "@", "domain.tld"])},
      "emailNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес электронной почты пользователя. Обязателен к заполнению."])},
      "samplePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password"])},
      "passwordNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль пользователя. Не должен быть короче 8 символов. Если не указан, будет создан автоматически."])},
      "sampleNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Примечания"])},
      "samplePin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1234"])},
      "pinNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пин-код пользователя. Должен содержать ровно 4 цифры. Если не указан, будет создан автоматически."])},
      "usersToAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будут добавлены следующие пользователи:"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
      "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пин-код"])},
      "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Примечания"])},
      "fileParseFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл имеет неверный формат"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить загрузку"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить пользователей"])}
    }
  })
}
