export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ru",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Личный кабинет \"VR Chemistry Lab\""])},
      "annotation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ниже представлено краткое описание каждого из разделов личного кабинета. Отображение разделов и действия, которые в них можно совершить, ограничены набором разрешений конкретного пользователя."])},
      "user": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь"])},
        "assignments": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задания"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["позволяет просматривать информацию о всех заданиях, которые были назначены текущему пользователю."])}
        },
        "settings": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["позволяет просматривать информацию о текущем пользователе, сменить пин-код и пароль пользователя."])}
        }
      },
      "company": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Организация"])},
        "assignments": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задания"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["позволяет просматривать список назначенных заданий, редактировать, удалять их и назначать новые задания. Задание представляет набор сгрупированных задач, которые необходимо пройти за отведенное время. У каждого задания есть статистика прохождения. Это статистику можно посмотреть в диалоговом окне, нажав на карточку интересующего задания."])}
        },
        "assignmentTemplates": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблоны заданий"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["позволяет просматривать список шаблонов заданий организаций, редактировать, удалять их и создавать новые шаблоны. Шаблон задания представляет собой набор сгрупированных задач, который можно назначать для прохождения пользователям организации в качестве задания."])}
        },
        "users": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["позволяет просматривать список пользователей организации, редактировать, удалять, восстанавливать их, а также добавлять новых пользователей. Позволяет назначать пользователям роли."])}
        },
        "roles": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роли"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["позволяет просматривать список ролей организации, редактировать и удалять существующие роли, а также создавать новые. Одну из ролей организации можно назначить ролью по умолчанию. Ролью по умолчанию называется роль, которая присваивается пользователю, если для него не была указана никакая другая роль. С помощью назначения ролей пользователям присваивается набор разрешений на совершение действий в личном кабинете и в приложении лаборатории."])}
        },
        "devices": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Устройства"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["позволяет просматривать список устройств организации, редактировать информацию о каждом устройстве, откреплять устройства от лицензии и получать коды активации устройств (инструкцию по активации устройств см. в диалоговом окне \"Привязка устройств\" в разделе \"устройства\")."])}
        },
        "tasks": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задачи"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["позволяет просматривать список задач организации, редактировать, копировать, удалять, восстанавливать их, а также создавать новые. Задача представляет собой сценарий, который может быть запущен в приложении лаборатории, отражающий один или несколько экспериментов. Сценарий задачи создается с помощью редактора задач. Чтобы задачу можно было добавить в шаблон задания, её необходимо опубликовать. После того, как задача была опубликована и добавлена в шаблон задания, её дальнейшее редактирование запрещено."])}
        },
        "licences": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лицензии"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["позвозяет просматривать список лицензий организации и активировать новые. Каждая лицензия может обладать следующим набором параметров:"])},
          "attributes": {
            "expirationTime": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["действует с, действует до"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["эти две даты определяют временной интервал, в течение которого лицензия является активной;"])}
            },
            "availableEntities": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["доступные задачи и задания"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["определяет список задач и заданий, доступ к которым получает организация;"])}
            },
            "maxDevicesNumber": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["максимальное количество устройств"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["определяет максимальное количество устройств, которое может быть прикреплено к лицензии одновременно;"])}
            },
            "canManageTasks": {
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["разрешение на управление задачами"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["определяет то, могут ли пользователи управлять задачами организации. Пользователи организации без этого разрешения могут только просматривать список задач и запускать их в лаборатории, но но не имеют доступа к редактору задач."])}
            }
          }
        }
      },
      "info": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация"])},
        "about": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Справка"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["отображает краткий обзор функционала личного кабинета."])}
        }
      }
    }
  })
}
