<template>
	<div v-if="link.to.startsWith('http')">
		<a :href="link.to" target="_blank" class="vr-sidebar__body__section__navlink">
			<div>
				<fa-icon :icon="link.icon" size="lg" class="icon fa-fw" />
				<span>{{ link.label }}</span>
			</div>
		</a>
	</div>
	<div v-else>
		<router-link :to="{ name: link.to }" class="vr-sidebar__body__section__navlink">
			<div>
				<fa-icon :icon="link.icon" size="lg" class="icon fa-fw" />
				<span>{{ link.label }}</span>
			</div>
		</router-link>
	</div>
</template>

<script>
export default {
	props: {
		link: {
			type: Object,
			default: {},
			required: true,
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.vr-sidebar__body__section__navlink {
	cursor: grab;
	text-decoration: none;
	color: $gray-600;

	&:hover {
		color: $gray-800;
	}

	div {
		height: 2.5rem;
		padding: 0.5rem;
		display: flex;
		align-items: center;
		gap: 0.5rem;
	}

	span {
		line-height: 120%;
	}

	&.router-link-exact-active {
		color: $blue-600;

		&:hover {
			color: $blue-800;
		}
	}
}
</style>
