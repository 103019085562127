<template>
	<div>
		<table class="table">
			<thead>
				<tr>
					<th>{{ $t('date') }}</th>
					<th v-for="checker in checkers" :key="checker.checkerId" v-html="checker.label"></th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="attempt in attempts">
					<td>{{ formatDateTime(attempt.createdAt) }}</td>
					<td v-for="result in attempt.results" :key="result.checker">
						{{ result.result }}
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<i18n>
	{
		"ru": {
			"date": "Дата"
		},
		"en": {
			"date": "Date"
		}
	}
</i18n>

<script>
import QUERY from '@/queries/views/assignment-list/query-task.graphql';

import { dateTimeMixin } from '@/mixins';

export default {
	props: {
		progressId: {
			type: String,
			default: null,
		},
	},
	mixins: [dateTimeMixin],
	data() {
		return {
			checkers: [],
			attempts: [],
		};
	},
	apollo: {
		attempts: {
			manual: true,
			query: QUERY,
			result({ loading, data }) {
				if (!loading) {
					this.checkers = data.assignedTask.checkers;
					this.attempts = data.assignedTask.attempts.map(function (att) {
						return {
							...att,
							results: data.assignedTask.checkers.map(chk =>
								att.results.find(c => c.checker == chk.checkerId)
							),
						};
					});
				}
			},
			skip() {
				return !this.progressId;
			},
			variables() {
				return { progressId: this.progressId };
			},
		},
	},
};
</script>
