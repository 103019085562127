export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create task"])},
      "fields": {
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tags"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
        "annotation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["annotation"])},
        "passingGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["passing grade"])}
      },
      "submitBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
      "cancelBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "validation": {
        "name": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter task name"])},
          "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Task name length must not exceed ", _interpolate(_named("nameLength")), " characters."])}
        },
        "passingGrade": {
          "format": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Passing grade must be an integer between 0 and ", _interpolate(_named("gradeVal")), "."])}
        }
      },
      "query": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task creation successful."])},
        "errors": {
          "UniqueValueError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task with the same name already exists."])},
          "FallbackError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occured. Please try again later."])},
          "CompanyTaskManageError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your organisation does not have permission to manage tasks."])},
          "EntityLimitError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task limit reached."])}
        }
      }
    }
  })
}
