<template>
	<div :class="['vr-select', { 'vr-select--active': active }]" v-click-outside="() => (active = false)">
		<button @click="active = !active" type="button" class="vr-select__btn">
			<slot name="selectedOption" v-bind="selectedOption">
				<span class="vr-select__btn__label"> 
					<slot name="selectedOption">{{ localSelectedOption.label }}</slot>
					</span>
			</slot>
			<fa-icon :icon="chevronIcon" />
		</button>
		<span class="vr-select__placeholder">{{ placeholder }}</span>
		<div class="vr-select__options">
			<ul>
				<li v-for="(option, idx) in options" :key="idx">
					<button @click.prevent="chooseOption(option)">
						<slot name="option" v-bind="option">
							{{ option.label }}
						</slot>
					</button>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	emits: ['selectOption'],
	data() {
		return {
			active: false,
		};
	},
	props: {
		options: {
			type: Array,
			default: () => [],
		},
		selectedOption: {
			type: Object,
			default: () => ({id: null, label: '---'}),
		},
		placeholder: {
			type: String,
			default: null,
		},
	},
	methods: {
		chooseOption(option) {
			this.localSelectedOption = option;
			this.active = false;
		},
	},
	computed: {
		chevronIcon() {
			return this.active ? 'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down';
		},
		localSelectedOption: {
			get() {
				return this.selectedOption;
			},
			set(option) {
				this.$emit('selectOption', option.id);
			}
		}
	},
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.vr-select {
	position: relative;
	margin-bottom: 1rem;

	&__btn {
		position: relative;
		display: flex;
		width: 100%;
		height: 4rem;
		padding: 1.5rem 0 0 1.5rem;
		background-color: #ffffff;

		&,
		&:active,
		&:hover,
		&:focus {
			border: none;
			outline: 1px solid $gray-500;
		}

		&__label {
			width: calc(100% - 3rem);
			text-align: start;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
	&__placeholder {
		position: absolute;
		top: 0;
		left: 1.5rem;
		color: $gray-600;
	}

	&__options {
		display: none;
		position: absolute;
		z-index: 2;
		max-height: 11rem;
		overflow-y: scroll;
		top: 4.5rem;
		left: 0;
		width: 100%;
		padding: 0.5rem 0;
		background-color: #ffffff;
		border: 1px solid $gray-500;

		ul {
			list-style-type: none;
			padding-left: 0;
			margin-bottom: 0;

			li {
				display: flex;

				&:hover {
					background-color: $gray-200;
				}
				button {
					padding-left: 1.5rem;
					width: 100%;
					text-align: start;
					background: none;
					border: none;
				}
			}
		}
	}

	&--active {
		.vr-select__options {
			display: block;
		}
	}
}
</style>
