export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внимание!"])},
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вы собираетесь передать контроль над организацией \"", _interpolate(_named("company")), "\" другому лицу. Пожалуйста, подтвердите ваши намерения, введя название этой организации ниже."])},
        "repeatCompanyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название организации"])},
        "companyNameMismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название организации не совпадает."])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить передачу управления"])}
      },
      "en": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning!"])},
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You are about to transfer ownership of \"", _interpolate(_named("company")), "\" organisation to another person. Please confirm your intentions by entering the organisation's name below."])},
        "repeatCompanyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation name"])},
        "companyNameMismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation name does not match."])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm ownership transfer"])}
      }
    }
  })
}
