import { createStore } from 'vuex';

import auth from './auth';
import toasts from './toasts';

export default createStore({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        toasts,
    },
});
