<template>
	<div class="assignment card" :class="{ pending: assignment.group.pending, ended: assignment.group.ended }">
		<div class="card-header">
			{{ assignment.group.assignment.name }}
		</div>
		<div class="card-body">
			<p>{{ $t('assignedBy', { email: assignment.group.author.email }) }}</p>
			<p v-show="assignment.group.pending">
				{{ $t('assignmentStarts', { date: formatDateTime(assignment.group.startedAt) }) }}
			</p>
			<p v-show="!assignment.group.pending && !assignment.group.ended">
				{{ $t('assignmentEnds', { date: formatDateTime(assignment.group.endedAt) }) }}
			</p>
			<p v-show="assignment.group.ended">
				{{ $t('assignmentEnded', { date: formatDateTime(assignment.group.endedAt) }) }}
			</p>
		</div>
		<div class="card-body parts">
			<div class="card tasks" v-for="part in assignment.parts" :key="part.id">
				<div class="card task" v-for="task in part.tasks" :key="task.id">
					<div class="card-header" :class="{ 'bg-success': task.successful }">{{ task.name }}</div>
					<div class="card-body">
						<div class="progress">
							<div
								class="progress-bar"
								:class="[task.grade < task.requiredGrade ? 'bg-danger' : 'bg-success']"
								:style="{ width: `${(task.grade / task.maxGrade) * 100}%` }"
							></div>
							<div
								class="required-grade"
								v-if="task.requiredGrade > 0"
								:style="{ width: `${(task.requiredGrade / task.maxGrade) * 100}%` }"
							></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<i18n>
	{
		"ru": {
			"assignedBy": "Задание назначено пользователем { email }.",
			"assignmentStarts": "Приступить к выполнению задания можно после { date }.",
			"assignmentEnds": "Задание должно быть выполнено до { date }.",
			"assignmentEnded": "Срок выполнения задания прошёл { date }."
		},
		"en": {
			"assignedBy": "Assigned by user { email }.",
			"assignmentStarts": "You may start work on this assignment after { date }.",
			"assignmentEnds": "Assignment is due { date }.",
			"assignementEnded": "Assignment due date was { date }."
		}
	}
</i18n>

<style scoped lang="scss">
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
.assignment.ended {
	border-color: $danger;
	opacity: 0.5;
}

.assignment.pending {
	border-color: $info;
	opacity: 0.5;
}

.card-header {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.card-body + .card-body {
	padding-top: 0;
}

.card-body > p {
	margin-bottom: 0;
}

.parts,
.tasks {
	display: flex;
	flex-direction: row;
	gap: 0.5rem;
	flex-grow: 1;
	flex-shrink: 0;
}

.parts {
	overflow: auto;
	position: relative;
}

.tasks {
	padding: 0.5rem;

	.task {
		min-width: 15rem;

		.progress {
			position: relative;

			.required-grade {
				position: absolute;
				border-right: 3px solid $danger;
				height: 1.2 * $progress-height;
			}
		}
	}
}
</style>

<script>
import { dateTimeMixin } from '@/mixins';

export default {
	props: {
		assignment: {
			type: Object,
			default: () => ({}),
		},
	},
	mixins: [dateTimeMixin],
};
</script>
