<template>
	<div class="assignment-list p-3">
		<h5 v-if="!displayedGroups.length && !$apollo.loading" class="display-5 empty-placeholder">
			{{ $t('emptyPlaceholder') }}
		</h5>
		<spinner :show="$apollo.loading" />
		<div class="controls">
			<dropdown-list
				icon="fa-solid fa-filter"
				:list="filterDropdownItems"
				v-model:selection="filters"
				:i18n="filtersI18n"
			/>
			<dropdown class="order">
				<template #button>
					<fa-icon size="lg" icon="fa-solid fa-sort" />
					<span class="button-label">{{ $t(currentSort) }}</span>
				</template>
				<template #default>
					<li v-for="option in sortOptions">
						<button
							type="button"
							class="dropdown-item"
							:class="{ active: currentSort === option }"
							@click="currentSort = option"
						>
							<span>{{ $t(option) }}</span>
						</button>
					</li>
				</template>
			</dropdown>
			<router-link v-if="canAddAssignment" class="btn btn-success" :to="{ name: 'Assignment' }">{{
				$t('newAssignment')
			}}</router-link>
			<router-link v-if="canAddAssignmentForUser" class="btn btn-success" :to="{ name: 'AssignmentUsers' }">{{
				$t('assignAssignment')
			}}</router-link>
		</div>
		<assignment-card
			v-for="group in displayedGroups"
			:key="group.id"
			:assignment-group="group"
			@select-group="showDetails(group)"
		/>
		<assignment-details-modal
			v-model:show="isDetailsModalVisible"
			:groupId="selectedGroupId"
			@delete-group="showDeleteModal"
		/>

		<delete-assignment-modal v-model:show="isDeleteModalVisible" @confirm="deleteSelectedGroup" />
	</div>
</template>

<style scoped lang="scss">
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.assignment-list {
	position: relative;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
	grid-template-rows: max-content;
	grid-auto-rows: 1fr;
	gap: 1rem;

	.empty-placeholder {
		position: absolute;
		top: 40%;
		left: 0;
		width: 100%;
		text-align: center;
		color: $secondary;
	}

	.controls {
		grid-column: 1 / -1;
		display: flex;
		gap: 1rem;

		.filters {
			.dropdown-item {
				display: flex;
				align-items: center;
				gap: 0.5rem;
				.hidden {
					visibility: hidden;
				}
			}
		}

		.order {
			.dropdown-toggle {
				.button-label {
					margin-left: 0.5rem;
				}
			}
		}
	}
}
</style>

<i18n locale="ru" src="@/locales/ru/views/assignment-list.json"></i18n>
<i18n locale="en" src="@/locales/en/views/assignment-list.json"></i18n>

<script>
import dayjs from 'dayjs';
import QUERY from '@/queries/views/assignment-list/query.graphql';
import MUTATION from '@/queries/views/assignment-users/mutation-delete.graphql';
import AssignmentCard from '@/components/assignment-list/AssignmentCard';
import AssignmentDetailsModal from '@/components/assignment-list/AssignmentDetailsModal';
import DeleteAssignmentModal from '@/components/assignment-users/ModalRemoveAssignmentForUsers';
import DropdownList from '@/components/dropdowns/DropdownList';
import Dropdown from '@/components/dropdowns/Dropdown';
import { QueryError } from '@/errors';
import { assignmentPermissionsMixin, assignmentForUserPermissionsMixin, toastMixin } from '@/mixins';
import Spinner from '@/components/Spinner.vue';

export default {
	mixins: [assignmentPermissionsMixin, assignmentForUserPermissionsMixin, toastMixin],
	components: {
		AssignmentCard,
		AssignmentDetailsModal,
		DeleteAssignmentModal,
		DropdownList,
		Dropdown,
		Spinner,
	},
	data() {
		return {
			assignmentGroups: [],
			isFilterDropdownVisible: false,
			filterDropdownItems: ['showEnded', 'showActive', 'showPending', null, 'showComplete', 'showIncomplete'],
			filters: {
				showEnded: false,
				showActive: true,
				showPending: false,
				showComplete: true,
				showIncomplete: true,
			},
			isSortDropdownVisible: false,
			sortOptions: ['none', 'startedAtAsc', 'startedAtDesc', 'endedAtAsc', 'endedAtDesc'],
			currentSort: 'none',
			isDetailsModalVisible: false,
			selectedGroupId: null,
			isDeleteModalVisible: false,
			deleteGroupId: null,
		};
	},
	computed: {
		filtersI18n() {
			return {
				locale: this.$i18n.locale,
				messages: this.$i18n.getLocaleMessage(this.$i18n.locale).filters,
			};
		},
		displayedGroups() {
			const filters = this.filters;
			const option = this.currentSort;
			return this.assignmentGroups
				.filter(function (group) {
					if (!filters.showEnded && group.ended) {
						return false;
					}
					if (!filters.showActive && !group.ended && !group.pending) {
						return false;
					}
					if (!filters.showPending && group.pending) {
						return false;
					}
					const complete = group.assignmentForUsers.every(asgn => asgn.completed);
					if (!filters.showComplete && complete) {
						return false;
					}
					if (!filters.showIncomplete && !complete) {
						return false;
					}
					return true;
				})
				.sort(function (a, b) {
					if (option === 'none') {
						return 0;
					}
					const startA = dayjs(a.startedAt);
					const startB = dayjs(b.startedAt);
					const endA = dayjs(a.endedAt);
					const endB = dayjs(b.endedAt);
					if (option === 'startedAtAsc') {
						return startA.isBefore(startB) ? -1 : 1;
					}
					if (option === 'startedAtDesc') {
						return startA.isBefore(startB) ? 1 : -1;
					}
					if (option === 'endedAtAsc') {
						return endA.isBefore(endB) ? -1 : 1;
					}
					if (option === 'endedAtDesc') {
						return endA.isBefore(endB) ? 1 : -1;
					}
				});
		},
	},
	methods: {
		showDetails(group) {
			this.selectedGroupId = group.id;
			this.isDetailsModalVisible = true;
		},
		showDeleteModal(groupId) {
			this.deleteGroupId = groupId;
			this.isDeleteModalVisible = true;
		},
		async deleteSelectedGroup() {
			try {
				const { data } = await this.$apollo.mutate({
					mutation: MUTATION,
					variables: { id: this.deleteGroupId },
				});
				if (data.mutation.success) {
					this.assignmentGroups = this.assignmentGroups.filter(ag => ag.id !== this.deleteGroupId);
					this.deleteGroupId = null;
					this.showToast('toast.deleteSuccessText', 'toast.deleteSuccessTitle', 'success');
				} else {
					throw new QueryError(data.mutation.error);
				}
			} catch (err) {
				this.showToast('toast.deleteErrorText', 'toast.deleteErrorTitle', 'danger');
			}
		},
	},
	apollo: {
		assignments: {
			query: QUERY,
			manual: true,
			fetchPolicy: 'cache-and-network',
			result({ data, loading }) {
				if (!loading) {
					this.assignmentGroups = data.myCompany.userAssignmentGroups;
				}
			},
		},
	},
	watch: {
		isDetailsModalVisible(value){
			if (!value) {
				this.selectedGroupId = null;
			}
  		},
	},
};
</script>
