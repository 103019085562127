export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
      "fields": {
        "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password"])},
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
        "confirmNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat new password"])}
      },
      "changePasswordBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
      "validation": {
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter password"])},
          "sameAs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password and password repeat do not match."])},
          "notSameAs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New and old password should be different."])},
          "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Password should be at least ", _interpolate(_named("passwordLength")), " characters long."])},
          "whiteSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password should not contain spaces or tabulation."])}
        }
      },
      "query": {
        "success": {
          "passwordChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password change successful."])}
        },
        "errors": {
          "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password invalid."])},
          "fallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occured. Please try again later."])}
        }
      }
    }
  })
}
