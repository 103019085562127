<template>
	<div class="card">
		<div class="card-body assignment-card">
			<div v-show="isForeign" class="is-foreign"><fa-icon icon="fas fa-clipboard" /></div>
			<h5 class="card-title">{{ template.name }}</h5>
			<h6 class="card-subtitle">{{ authorEmail }}</h6>
			<tag-list class="mt-2" :tags="template.tags" />
			<table class="table border-top mb-0 mt-2">
				<tbody>
					<tr>
						<th>{{ $t('partCount') }}</th>
						<td>{{ partCount }}</td>
					</tr>
					<tr>
						<th>{{ $t('taskCount') }}</th>
						<td>{{ taskCount }}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<router-link :title="template.name" class="overlay" :to="{ name: 'Assignment', params: { id: template.id } }" />
	</div>
</template>

<style scoped lang="scss">
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
.card {
	position: relative;

	.is-foreign {
		position: absolute;
		right: 0;
		top: 0;

		padding-right: 0.5rem;
		padding-left: 0.5rem;
		padding-bottom: 0.25rem;

		border-top-right-radius: $card-border-radius;
		border-bottom-left-radius: $card-border-radius;

		background-color: $warning;
	}

	.assignment-card {
		padding-top: 1.5rem;
		padding-right: 1.5rem;
	}

	.card-title {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.card-subtitle {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	.overlay {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		opacity: 0;
	}
}

.card:hover {
	box-shadow: $box-shadow;
}
</style>

<i18n>
	{
		"ru": {
			"partCount": "Частей:",
			"taskCount": "Задач:",
			"publisher": "vr chemistry lab"
		},
		"en": {
			"partCount": "Parts:",
			"taskCount": "Tasks:",
			"publisher": "vics studio"
		}
	}
</i18n>

<script>
import TagList from '@/components/TagList';
export default {
	props: {
		template: {
			type: Object,
			default: () => ({}),
		},
	},
	components: {
		TagList,
	},
	computed: {
		partCount() {
			return this.template.parts.length;
		},
		taskCount() {
			return this.template.parts.reduce((acc, part) => (acc += part.tasks.length), 0);
		},
		authorEmail() {
			return this.template.author.email === 'PUBLISHER' ? this.$t('publisher') : this.template.author.email;
		},
		isForeign() {
			return this.template.availableFrom === 'LICENCE';
		},
	},
};
</script>
