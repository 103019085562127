<template>
	<div class="page-content user-settings-page">
		<common-section :user="user" />
		<change-pin-section :pin="user.pin" @changePin="pin => (user.pin = pin)" class="change-pin-section" />
		<change-password-section class="change-password-section" />
		<div v-if="$i18n.locale === 'ru'">
			<talent-account-section :user="user" />
		</div>
		<div v-if="user.isOwner">
			<user-agreement-section />
		</div>
	</div>
</template>

<script>
import CommonSection from '@/components/user-settings-view/CommonSection.vue';
import ChangePinSection from '@/components/user-settings-view/ChangePinSection.vue';
import ChangePasswordSection from '@/components/user-settings-view/ChangePasswordSection.vue';
import TalentAccountSection from '@/components/user-settings-view/TalentAccountSection';
import ME from '@/queries/views/user-settings/query-me.graphql';

export default {
	data() {
		return {
			user: {},
		};
	},
	apollo: {
		user: {
			query: ME,
			manual: true,
			result({ loading, data, errors }) {
				if (!loading) {
					this.user = { ...data.me };
				}
			},
		},
	},
	components: {
		CommonSection,
		ChangePinSection,
		ChangePasswordSection,
		TalentAccountSection,
	},
	created() {
		this.$apollo.queries.user.refetch();
	},
};
</script>

<style lang="scss" scoped>
.user-settings-page {
	.change-pin-section ::v-deep(.change-pin-form),
	.change-password-section ::v-deep(.change-password-form) {
		max-width: 30rem;
	}
}
</style>
