<template>
	<div class="auth">
		<div class="auth__container">
			<nav class="auth__container__navigation">
				<div class="nav nav-tabs" id="nav-tab" role="tablist">
					<button
						v-for="(tab, idx) in tabList"
						:key="`tab-${idx}`"
						@click="currentTab = tab"
						:class="['nav-link', { active: tab === currentTab }]"
						type="button"
						role="tab"
					>
						{{ $t(`${tab}`) }}
					</button>
				</div>
			</nav>
			<keep-alive>
				<component :is="currentTab" />
			</keep-alive>
		</div>
	</div>
</template>

<script>
import Login from '@/components/Login.vue';
import Registration from '@/components/Registration.vue';

export default {
	data() {
		return {
			tabList: ['login', 'registration'],
			currentTab: 'login',
		};
	},
	components: {
		Login,
		Registration,
	},
};
</script>

<i18n>
{
	"ru": {
		"login": "Авторизация",
		"registration": "Регистрация"
	},
	"en": {
		"login": "Log in",
		"registration": "Register"
	}
}
</i18n>

<style lang="scss" scoped>
.auth {
	grid-column: 1 / -1;
	grid-row: 1 / -1;

	display: flex;
	justify-content: center;
	align-items: center;

	height: 100vh;

	&__container {
		width: 40rem;

		&__navigation {
			display: flex;
			justify-content: flex-end;
		}
	}
}
</style>
