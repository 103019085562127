<template>
	<div class="data-table">
		<div class="data-table__header">
			<caption v-if="caption" class="h1">
				{{
					caption
				}}
			</caption>
			<div class="data-table__header__actions">
				<slot name="header" />
			</div>
		</div>
		<table class="table table-striped table-hover">
			<thead>
				<tr>
					<th v-if="checkboxes" scope="col"></th>
					<th scope="col">#</th>
					<th scope="col" v-for="(header, idx) in headers" :key="`table-header-${idx}`">
						<button class="thead-sort-btn" @click="cycleSort(header)">
							<span>{{ header.text }}</span>
							<fa-icon v-if="header.type" :icon="getSortIcon(header.field)" />
						</button>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(item, idx) in displayedItems"
					:key="`table-row-${idx}`"
					:class="{ 'table-info': localSelected.find(el => el[key] === item[key]) }"
				>
					<td v-if="checkboxes" class="table-row-select">
						<label
							@click.exact="selectItem(item)"
							@click.shift.exact="selectAll(item)"
							:for="`table-row-select-${item[key]}`"
						>
							<input
								type="checkbox"
								:id="`table-checkbox-${item[key]}`"
								class="form-check-input"
								:checked="localSelected.find(el => el[key] === item[key])"
							/>
						</label>
					</td>
					<td scope="row" class="table-row-number">
						{{ itemNumber(item) }}
						<button class="activate-row-btn" @click.stop="activateItem(item[key])"></button>
					</td>
					<template v-for="header in headers" :key="`table-cell-${header.field}`">
						<td>
							<slot :name="`item.${header.field}`" :item="item">{{
								getFieldValue(item, header.field)
							}}</slot>
						</td>
					</template>
				</tr>
			</tbody>
		</table>
		<div class="data-table__footer">
			<pagination @changePage="calcDisplayedItems" :itemsOnPage="itemsOnPage" :itemsTotal="items.length" />
			<div class="data-table__footer__actions"><slot name="footer" /></div>
		</div>
	</div>
</template>

<script>
import Pagination from '@/components/Pagination.vue';

export default {
	data() {
		return {
			currentPage: 1,
			lastSelectedKey: null,
			bounds: [0, this.itemsOnPage],
			sortBy: {
				field: null,
				type: null,
				direction: null,
			},
		};
	},
	props: {
		caption: {
			type: String,
			default: '',
		},
		// Expected headers: [{'text': <text>, 'field': <field>, 'type': <type>},...].
		// Specify <type> to enable sorting.
		// <type> possible values: 'number', 'boolean', 'string', 'dateTime'.
		headers: {
			type: Array,
			default: () => [],
		},
		items: {
			type: Array,
			default: () => [],
		},
		selected: {
			type: Array,
			default: () => [],
		},
		key: {
			type: String,
			default: 'id',
		},
		itemsOnPage: {
			type: Number,
			default: 15,
		},
		checkboxes: {
			type: Boolean,
			default: false,
		},
		defaultSort: {
			type: Object,
			default: () => ({}),
		}
	},
	components: {
		Pagination,
	},
	methods: {
		itemNumber(item) {
			return this.items.indexOf(item) + 1;
		},
		calcDisplayedItems(currentPage, bounds) {
			this.currentPage = currentPage;
			this.bounds = bounds;
		},
		activateItem(id) {
			this.$emit('activateItem', id);
		},
		cycleSort(header) {
			const sortBy = this.sortBy;

			if (sortBy.field !== header.field) {
				sortBy.field = header.field;
				sortBy.type = header.type;
				sortBy.direction = 'asc';
			} else {
				if (sortBy.direction === 'asc') {
					return (sortBy.direction = 'desc');
				}
				if (sortBy.direction === 'desc') {
					sortBy.field = null;
					sortBy.type = null;
					sortBy.direction = null;
				}
			}
		},
		getSortIcon(field) {
			const dir = this.sortBy.field === field ? this.sortBy.direction : null;
			switch (dir) {
				case 'asc':
					return 'fas fa-sort-down';
				case 'desc':
					return 'fas fa-sort-up';
				default:
					return 'fas fa-sort';
			}
		},
		selectItem(item) {
			this.lastSelectedKey = item[this.key];
			if (this.localSelected.find(el => el[this.key] === item[this.key])) {
				this.localSelected = this.localSelected.filter(el => el[this.key] !== item[this.key]);
			} else {
				this.localSelected = [...this.localSelected, item];
			}
		},
		selectAll(item) {
			if (!this.lastSelectedKey) {
				return this.selectItem(item);
			}
			const lastSelectedKey = this.lastSelectedKey;
			const lastSelectedIndex = this.displayedItems.findIndex(el => el[this.key] === lastSelectedKey);
			const selectedIndex = this.displayedItems.findIndex(el => el[this.key] === item[this.key]);
			const from = Math.min(lastSelectedIndex, selectedIndex);
			const to = Math.max(lastSelectedIndex, selectedIndex);
			const affected = this.displayedItems.slice(from, to + 1);

			affected.filter(el => el[this.key] !== lastSelectedKey).forEach(el => this.selectItem(el));
			this.lastSelectedKey = item[this.key];
		},
		getFieldValue(item, field) {
			const keys = field.split('.');
			return keys.reduce(function (acc, key) {
				return acc[key];
			}, item);
		},
	},
	computed: {
		sortedItems() {
			let items = [...this.items];
			const { field, type, direction } = this.sortBy;
			if (!field) return items;
			const mult = direction === 'desc' ? -1 : 1;
			const getFieldValue = this.getFieldValue;

			return items.sort(function (a, b) {
				const aVal = getFieldValue(a, field);
				const bVal = getFieldValue(b, field);
				if (type === 'boolean' || type === 'number') {
					return (+aVal - +bVal) * mult;
				}
				if (type === 'string') {
					return aVal.localeCompare(bVal) * mult;
				}
				if (type === 'dateTime') {
					const scoreA = new Date(aVal).getTime();
					const scoreB = new Date(bVal).getTime();
					return (scoreA - scoreB) * mult;
				}
			});
		},
		displayedItems() {
			return this.sortedItems.slice(this.bounds[0], this.bounds[1]);
		},
		localSelected: {
			get() {
				return this.selected;
			},
			set(items) {
				this.$emit('selectedItems', [...items]);
			},
		},
	},
	created() {
		if(Object.keys(this.defaultSort).length) {
			for (let key in this.sortBy) {
				this.sortBy[key] = this.defaultSort[key];
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.data-table {
	&__header {
		display: grid;
		grid-template-rows: auto auto;

		&__actions {
			grid-row: 2 / -1;
		}
	}

	&__footer {
		display: grid;
		grid-template-columns: auto auto;

		&__actions {
			grid-column: 2 / -1;
			justify-self: end;
		}
	}

	__btn {
		z-index: 999;
	}

	th {
		text-align: center;

		.thead-sort-btn {
			border: none;
			outline: none;
			background-color: transparent;
			box-shadow: none;
			font-weight: bold;

			* + * {
				margin-left: 1rem;
			}
		}
	}
	tbody {
		tr {
			position: relative;
			cursor: pointer;

			td {
				text-align: center;

				&.table-row-select {
					position: relative;
					z-index: 2;
					min-width: 2rem;

					label {
						display: flex;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;

						input {
							margin: auto;
						}
					}
				}

				&.table-row-number {
					font-weight: bold;
				}

				.activate-row-btn {
					position: absolute;
					opacity: 0;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: none;
					border: none;
				}
			}
		}
	}
}
</style>
