<template>
	<div class="page-content">
		<div class="form-group">
			<text-input
				input-id="company-name"
				v-model:value="name"
				:placeholder="$t('companyName')"
				:error="v.name.$error"
				:error-msg="(v.name.$error && $t(v.name.$errors[0]?.$message)) || ''"
			/>
		</div>
		<div class="form-group">
			<dropdown-select
				:options="roles"
				:selectedOption="defaultRole"
				@selectOption="selectDefaultRole"
				:placeholder="$t('defaultRole')"
			/>
		</div>
		<div class="form-group">
			<button class="btn btn-success" @click="updateCompany">{{ $t('save') }}</button>
		</div>
		<div v-if="isCompanyOwner" class="form-group border border-danger rounded">
			<p class="alert alert-danger">{{ $t('dangerZone') }}</p>
			<dropdown-select
				class="m-1"
				:options="users"
				:selectedOption="owner"
				@selectOption="selectOwner"
				:placeholder="$t('owner')"
			/>
			<button class="btn btn-danger m-1" @click.stop="isConfirmTransferModalVisible = true">
				{{ $t('save') }}
			</button>
		</div>

		<confirm-ownership-transfer-modal
			:company-name="name"
			v-model:show="isConfirmTransferModalVisible"
			@confirm="transferOwnership"
		/>
	</div>
</template>

<style lang="scss" scoped>
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.form-group + .form-group {
	margin-top: 1rem;
}

.vr-select::v-deep .vr-select__btn,
.vr-select::v-deep .vr-select__options {
	border-radius: $border-radius;
}
</style>

<i18n locale="ru" src="@/locales/ru/views/company-settings.json"></i18n>
<i18n locale="en" src="@/locales/en/views/company-settings.json"></i18n>

<script>
import { mapGetters } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';

import { toastMixin } from '@/mixins';
import { QueryError } from '@/errors';

import QUERY from '@/queries/views/company-settings/query-company.graphql';
import UPDATE from '@/queries/views/company-settings/mutation-update.graphql';
import TRANSFER from '@/queries/views/company-settings/mutation-transfer-ownership.graphql';

import TextInput from '@/components/inputs/TextInput';
import DropdownSelect from '@/components/dropdowns/DropdownSelect';
import ConfirmOwnershipTransferModal from '@/components/company-settings/ConfirmOwnershipTransferModal';

export default {
	components: {
		TextInput,
		DropdownSelect,
		ConfirmOwnershipTransferModal,
	},
	mixins: [toastMixin],
	data() {
		return {
			name: null,
			owner: {
				id: null,
				label: null,
			},
			users: [],
			defaultRole: {
				id: null,
				label: null,
			},
			roles: [],
			isConfirmTransferModalVisible: false,
		};
	},
	validations() {
		return {
			name: {
				requires: helpers.withMessage('errors.NoNameError', required),
			},
		};
	},
	computed: {
		...mapGetters('auth', ['isCompanyOwner']),
	},
	methods: {
		selectDefaultRole(id) {
			const role = this.roles.find(r => r.id === id);
			this.defaultRole = role;
		},
		selectOwner(id) {
			const user = this.users.find(u => u.id === id);
			this.owner = user;
		},
		async updateCompany() {
			this.v.$touch();
			if (this.v.$error) {
				return;
			}
			try {
				const { data } = await this.$apollo.mutate({
					mutation: UPDATE,
					variables: { data: { name: this.name, defaultRole: this.defaultRole.id } },
				});
				if (data.updateCompany.success) {
					this.showToast('updateSuccess', 'success', 'success');
				} else {
					throw new QueryError(data.updateCompany.error);
				}
			} catch (err) {
				this.handleError(err);
			} finally {
				this.v.$reset();
			}
		},
		async transferOwnership() {
			try {
				const { data } = await this.$apollo.mutate({
					mutation: TRANSFER,
					variables: { newOwner: this.owner.id },
				});
				if (data.transferOwnership.success) {
					this.showToast('transferSuccess', 'success', 'success');
					await this.$store.dispatch('auth/me', { force: true });
				} else {
					throw new QueryError(data.transferOwnership.error);
				}
			} catch (err) {
				this.handleError(err);
			}
		},
		handleError(err) {
			if (err instanceof QueryError) {
				let errorString = `errors.${err.cause.type}`;
				if (err.cause.type === 'NotFoundError') {
					errorString += `.${err.cause.entity}`;
				}
				if (err.cause.type === 'CompanyMismatchError') {
					errorString += `.${err.cause.entites.join('')}`;
				}
				this.showToast(errorString, 'error', 'error');
			} else {
				this.showToast('errors.FallbackError', 'error', 'danger');
			}
		},
	},
	setup() {
		return { v: useVuelidate({ $scope: false }) };
	},
	apollo: {
		data: {
			query: QUERY,
			manual: true,
			result({ loading, data }) {
				if (!loading) {
					this.name = data.myCompany.name;
					this.owner = data.myCompany.owner;
					this.users = data.myCompany.users;
					this.defaultRole = data.myCompany.defaultRole;
					this.roles = data.myCompany.roles;
				}
			},
		},
	},
};
</script>
