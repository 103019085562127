<template>
	<div class="page-content forbidden-page">
		<div>
			<fa-icon icon="fa-regular fa-face-dizzy" class="icon-dizzy-face" />
			<h1>{{ $t('forbidden') }}</h1>
		</div>
	</div>
</template>

<i18n>
{
    "ru": {
        "forbidden": "Доступ запрещён (403)"
    },
    "en": {
        "forbidden": "Forbidden (403)"
    }
}
</i18n>

<style lang="scss" scoped>
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.forbidden-page {
	position: relative;

	div {
		display: flex;
		height: 100%;
		justify-content: center;
		align-items: center;
		gap: 1rem;
		color: $secondary;

		.icon-dizzy-face {
			font-size: 2.5rem;
		}
	}
}
</style>
