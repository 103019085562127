<template>
	<div class="reset-password">
		<div class="card text-dark bg-light reset-password__card">
			<div class="card-header">{{ $t('title') }}</div>
			<form class="card-body reset-password__card__form" @submit.prevent="resetPassword">
				<password-input
					v-model:password="password"
					:vError="v$.password.$error"
					:placeholder="$t('passwordPlaceholder')"
					:vErrorMsg="v$.password.$errors[0]?.$message"
				/>
				<password-input
					v-model:password="confirmPassword"
					:vError="v$.confirmPassword.$error"
					:placeholder="$t('confirmPasswordPlaceholder')"
					:vErrorMsg="v$.confirmPassword.$errors[0]?.$message"
				/>
				<div v-show="alert.show" :class="['alert', `alert-${alert.type}`]">
					{{ alert.message }}
					<router-link
						v-if="serverError === 'errors.InvalidValueError' && errorEntity == 'Token'"
						:to="{ name: 'ForgotPassword' }"
						>{{ $t('obtainLinkAgain') }}</router-link
					>
					<router-link v-if="alert.type === 'success'" :to="{ name: 'Login' }">{{
						$t('toLogin')
					}}</router-link>
				</div>
				<button class="btn btn-primary" type="submit">{{ $t('submitBtn') }}</button>
			</form>
		</div>
	</div>
</template>

<i18n locale="ru" src="@/locales/ru/views/reset-password.json"></i18n>
<i18n locale="en" src="@/locales/en/views/reset-password.json"></i18n>

<script>
import useVuelidate from '@vuelidate/core';
import PasswordInput from '@/components/inputs/PasswordInput.vue';
import { required, sameAs, minLength, helpers } from '@vuelidate/validators';
import { QueryError } from '@/errors';
import RESETPASSWORD from '@/queries/views/reset-password/mutation-reset-password.graphql';

export default {
	setup() {
		return { v$: useVuelidate() };
	},
	data() {
		return {
			password: null,
			confirmPassword: null,
			passwordMinLength: 8,
			serverError: null,
			errorEntity: null,
			alert: { show: false, type: null, message: null },
		};
	},
	validations() {
		return {
			password: {
				required: helpers.withMessage(this.$t('validation.password.required'), required),
				sameAsPassword: helpers.withMessage(
					this.$t('validation.password.sameAs'),
					sameAs(this.confirmPassword)
				),
				whiteSpaces: helpers.withMessage(
					this.$t('validation.password.whiteSpaces'),
					() => !/\s/g.test(this.password)
				),
				minLength: helpers.withMessage(
					this.$t('validation.password.minLength', { passwordLength: this.passwordMinLength }),
					minLength(this.passwordMinLength)
				),
			},
			confirmPassword: {
				required: helpers.withMessage(this.$t('validation.password.required'), required),
				sameAsPassword: helpers.withMessage(this.$t('validation.password.sameAs'), sameAs(this.password)),
				minLength: helpers.withMessage(
					this.$t('validation.password.minLength', { passwordLength: this.passwordMinLength }),
					minLength(this.passwordMinLength)
				),
				whiteSpaces: helpers.withMessage(
					this.$t('validation.password.whiteSpaces'),
					() => !/\s/g.test(this.confirmPassword)
				),
			},
		};
	},
	methods: {
		setAlert(show, type, message) {
			this.alert.show = show;
			this.alert.type = type;
			this.alert.message = message;
		},
		async resetPassword() {
			this.setAlert(false, '', '');
			this.v$.$touch();
			if (this.v$.$error) {
				return;
			}
			try {
				const { data } = await this.$apollo.mutate({
					mutation: RESETPASSWORD,
					variables: {
						password: this.password,
						uid: this.$route.params.uid,
						token: this.$route.params.token,
					},
				});
				if (data.resetPassword.success) {
					this.setAlert(true, 'success', this.$t('query.success'));
					this.password = null;
					this.confirmPassword = null;
				} else {
					throw new QueryError(data.resetPassword.error);
				}
			} catch (err) {
				if (err instanceof QueryError) {
					this.serverError = `errors.${err.cause.type}`;
					this.errorEntity = err.cause?.entity;
				} else {
					this.serverError = 'errors.FallbackError';
				}
				this.handleError(err);
			} finally {
				this.v$.$reset();
			}
		},
		handleError(err) {
			if (this.serverError === 'errors.InvalidValueError' && err.cause.entity === 'Token') {
				this.setAlert(true, 'danger', this.$t('query.error.invalidTokenValue'));
			}
			if (
				this.serverError === 'errors.InvalidValueError' &&
				err.cause.entity === 'User' &&
				err.cause.field === 'password'
			) {
				this.setAlert(true, 'danger', this.$t('query.error.invalidPasswordValue'));
			}
		},
	},
	components: {
		PasswordInput,
	},
};
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.reset-password {
	grid-column: 1 / -1;
	grid-row: 1 / -1;

	display: flex;
	justify-content: center;
	align-items: center;

	height: 100vh;

	&__card {
		&__form {
			width: 40rem;
			display: flex;
			flex-direction: column;

			& > * + * {
				margin-top: 1rem;
			}

			.alert {
				margin-bottom: 0;
			}
			& > button {
				margin-top: 1rem;
			}
		}
	}
}
</style>
