<template>
	<div class="vr-modal-section-field">
		<span>{{ label }}: </span>
		<span>{{ value }}</span>
	</div>
</template>

<script>
export default {
	props: {
		label: {
			type: String,
			default: '',
			required: true,
		},
		value: {
			type: [String, Number],
			default: '',
			required: true,
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.vr-modal-section-field {
	margin-bottom: 1rem;

	span:first-child {
		color: $gray-600;
	}
}
</style>
