import base from './base';
import { permissionComputeds, authorOrPermissionMethods } from './base';

export default {
	mixins: [base],
	methods: authorOrPermissionMethods(
		'CHANGE_ASSIGNMENT_FOR_USER',
		'DELETE_ASSIGNMENT_FOR_USER'
	),
	computed: permissionComputeds('VIEW_ASSIGNMENT_FOR_USER', 'ADD_ASSIGNMENT_FOR_USER'),
};
