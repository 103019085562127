<template>
	<vr-modal v-model:show="modalShow" size="medium" align="center" :footer-line="false">
		<template v-slot:header> {{ $t('title') }} </template>
		<template v-slot:body>
			<page-section v-if="devices.length !== 0">
				<page-section-list :list="devicesNames" />
			</page-section>
			<h5 v-else>{{ $t('noBindedDevices') }}</h5>
		</template>
	</vr-modal>
</template>

<script>
import VrModal from '@/components/modals/VrModal.vue';
import PageSection from '@/components/page-sections/PageSection.vue';
import PageSectionList from '@/components/page-sections/PageSectionList.vue';

export default {
	emits: ['update:show'],
	data() {
		return {
			licenceModalKeys: [
				'status',
				'createdAt',
				'startedAt',
				'endedAt',
				'devicesNumber',
				'maxDevicesNumber',
				'key',
				'contentPacks',
			],
		};
	},
	computed: {
		modalShow: {
			get() {
				return this.show;
			},
			set(show) {
				this.$emit('update:show', show);
			},
		},
		devicesNames() {
			return this.devices.map(el => el.name);
		},
	},
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		devices: {
			type: Array,
			default: () => [],
		},
	},
	components: {
		VrModal,
		PageSection,
		PageSectionList,
	},
};
</script>

<i18n>
{
	"ru": {
            "title": "Список устройств",
            "noBindedDevices": "Нет прикрепленных устройств"
	},
	"en": {
		"title": "Device list",
		"noBindedDevices": "No devices"
	}
}
</i18n>
