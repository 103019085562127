import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import App from './App.vue';
import router from './router';
import store from './store';
import apolloProvider from './apollo';
import faIcons from './icons';
import { clickOutside } from './directives';
import Popper from 'vue3-popper';
import VueDatePicker from '@vuepic/vue-datepicker';

const app = createApp(App);
const hostname = window.location.hostname;
let locale;
if (hostname.endsWith(process.env.VUE_APP_EN_DOMAIN)) {
	locale = 'en';
}
if (hostname.endsWith(process.env.VUE_APP_RU_DOMAIN)) {
	locale = 'ru';
}

if (!locale) {
	console.warn('App is running on an unrecognised domain. Falling back to default locale.');
	locale = 'ru';
}

const i18n = createI18n({ locale });
app.use(i18n);
app.use(store);
app.use(router);
app.use(apolloProvider);
app.use(faIcons);
app.directive('click-outside', clickOutside);
app.mount('#app');
app.component('Popper', Popper);
app.component('DatePicker', VueDatePicker);
