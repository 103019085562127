<template>
	<div class="dropdown" v-click-outside="() => (isVisible = false)">
		<button
			class="btn btn-outline-secondary dropdown-toggle"
			:class="{ show: isVisible }"
			type="button"
			@click="isVisible = !isVisible"
			:disabled="disabled"
		>
			<slot name="button">
				<fa-icon size="lg" :icon="icon" />
			</slot>
		</button>
		<slot name="list" :close="close">
			<ul
				class="dropdown-menu"
				:class="[{ show: isVisible }, `align-${align}`]"

			>
				<slot :close="close" />
			</ul>
		</slot>
	</div>
</template>

<style scoped lang="scss">
.dropdown-menu {
	margin-top: 0.5rem;
}
.dropdown-menu.align-right {
	right: 0;
}
</style>

<script>
export default {
	props: {
		icon: {
			type: String,
			default: 'fa-solid fa-filter',
		},
		align: {
			type: String,
			default: 'left', // possible values: 'left', 'right'
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isVisible: false,
		};
	},
	methods: {
		close() {
			this.isVisible = false;
		},
	},
};
</script>
