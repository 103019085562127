<template>
	<div class="card position-relative" :class="{ pending: assignmentGroup.pending, ended: assignmentGroup.ended }">
		<div class="card-body">
			<h5 class="card-title">{{ assignmentGroup.assignment.name }}</h5>
			<h6 class="card-subtitle text-muted">{{ $t('startsAt', { date: startDateString }) }}</h6>
			<h6 class="card-subtitle text-muted">{{ $t('endsAt', { date: endDateString }) }}</h6>
			<div class="table">
				<strong>{{ $t('user') }}</strong>
				<strong>{{ $t('completed') }}</strong>
				<template v-for="assignment in assignments" :key="assignment.id">
					<p class="user-email">{{ assignment.user.email }}</p>
					<p class="text-center"><fa-icon v-show="assignment.completed" icon="fa fa-check" /></p>
				</template>
				<p v-if="userOverflowCount > 0" class="overflow-count">
					{{ $t('andMore', { count: userOverflowCount }) }}
				</p>
			</div>
		</div>
		<button class="overlay" @click.stop="$emit('select-group')"></button>
	</div>
</template>

<style scoped lang="scss">
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
.card {
	&:hover {
		box-shadow: $box-shadow;
	}

	.card-body {
		.card-title {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.table {
			display: grid;
			grid-template-columns: 1fr min-content;

			p,
			strong {
				display: block;
				padding: 0.5rem;
				border-bottom: 1px solid $border-color;
				margin: 0;
			}

			.user-email {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.overflow-count {
				grid-column: 1 / -1;
				text-align: center;
			}
		}
	}

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 10;
		opacity: 0;

		border: none;
		box-shadow: none;
		outline: none;
	}
}

.card.ended {
	border-color: $danger;
	opacity: 0.5;
}

.card.pending {
	border-color: $info;
	opacity: 0.5;
}
</style>

<i18n>
	{
		"ru": {
			"startsAt": "Начало: { date }",
			"endsAt": "Конец: { date }",
			"user": "Пользователь",
			"completed": "Готово",
			"andMore": "и ещё { count }"
		},
		"en": {
			"startsAt": "Start date: { date }",
			"endsAt": "End date: { date }",
			"user": "User",
			"completed": "Completed",
			"andMore": "and { count } more"
		}
	}
</i18n>

<script>
import { dateTimeMixin } from '@/mixins';

export default {
	props: {
		assignmentGroup: {
			type: Object,
			default: () => ({}),
		},
	},
	emits: ['select-group'],
	mixins: [dateTimeMixin],
	data() {
		return {
			maxUsersInCard: 3,
		};
	},
	computed: {
		startDateString() {
			return this.formatDateTime(this.assignmentGroup.startedAt);
		},
		endDateString() {
			return this.formatDateTime(this.assignmentGroup.endedAt);
		},
		assignments() {
			return this.assignmentGroup.assignmentForUsers
				.slice(0)
				.sort((a, b) => a.completed - b.completed)
				.slice(0, this.maxUsersInCard);
		},
		userOverflowCount() {
			return this.assignmentGroup.assignmentForUsers.length - this.maxUsersInCard;
		},
	},
};
</script>
