export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внимание!"])},
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Пользователи ", _interpolate(_named("emails")), " будут удалены безвозвратно. Вместе с ними будет удалена вся текущая статистика и весь прогресс прохождения заданий. Вы уверены, что хотите удалить пользователей?"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])}
      },
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning!"])},
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Users ", _interpolate(_named("emails")), " will be removed along with all current statistics and assignment progress. This action can not be undone. Are your sure you want to remove these users?"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])}
      }
    }
  })
}
