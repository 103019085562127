export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Личный кабинет Таланта"])},
        "talentAccountLinked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Привязка к личному кабинету Таланта выполнена."])},
        "talentAccountNotLinked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет привязки к личному кабинету Таланта."])},
        "linkTalentAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Привязать личный кабинет Таланта"])},
        "unlinkTalentAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отвязать личный кабинет Таланта"])},
        "unlinkSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Личный кабинет Таланта отвязан."])},
        "unlinkFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось отвязать личный кабинет Таланта. Пожалуйста, попробуйте позже."])}
      },
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talent account"])},
        "talentAccountLinked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talent account linked."])},
        "talentAccountNotLinked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talent account not linked."])},
        "linkTalentAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link Talent account"])},
        "unlinkTalentAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlink Talent account"])},
        "unlinkSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talent account unlinked."])},
        "unlinkFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falied to unlink Talent account. Please try again later."])}
      }
    }
  })
}
