export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "autoGenerated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<автоматически>"])},
        "errors": {
          "LineParseFailedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный формат строки."])},
          "NoEmailError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес электронной почты обязателен."])},
          "EmailFormatError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес электронной почты имеет неправильный формат."])},
          "EmailExistsError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес электронной почты уже используется другим пользователем."])},
          "PasswordTooShortError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Длина пароля не должна быть меньше 8 символов."])},
          "SameAsEmailError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль не должен совпадать с адресом электронной почты."])},
          "PinLengthError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Длина пин-кода должна составлять 4 символа."])},
          "PinFormatError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пин-код может содержать только цифры."])}
        }
      },
      "en": {
        "autoGenerated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<autogenerated>"])},
        "errors": {
          "LineParseFailedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line format invalid."])},
          "NoEmailError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address is required."])},
          "EmailFormatError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address is invalid."])},
          "EmailExistsError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address already in use."])},
          "PasswordTooShortError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be at least 8 characters long."])},
          "SameAsEmailError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be different from email address."])},
          "PinLengthError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin-code muse be exactly 4 characters long."])},
          "PinFormatError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin-code must not contain non-numeric characters."])}
        }
      }
    }
  })
}
