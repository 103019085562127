export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "timeLimits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time limits"])},
      "timeLimitStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
      "timeLimitEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End"])},
      "assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment"])},
      "accessExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignmet access is expiring"])},
      "ordered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completion order"])},
      "orderSeq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sequential"])},
      "orderArb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arbitrary"])},
      "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks"])},
      "passingGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passing grade"])},
      "allUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All users"])},
      "selectedUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected users"])},
      "assignAssignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create assignment"])},
      "updateAssignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update assignment"])},
      "deleteAssignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete assignment"])},
      "dragUsersHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag users here"])},
      "selectAssignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select assignment template"])},
      "undefiendEndedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date is undefiend"])},
      "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
      "clearAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
      "validations": {
        "AssignmentNotSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment template not selected."])},
        "UsersNotSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users not selected."])},
        "EndedAtIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An assignment must have an end date."])},
        "EndedAtIsEarlyStartedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment end date must be later that its start date."])},
        "EndedAtIsLaterEndedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment end date must be not later that its access end date."])}
      },
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
      "errors": {
        "ValueOutOfBoundsError": {
          "startedAt": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("validations.EndedAtIsEarlyStartedAt", undefined, _type)])},
          "endedAt": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("validations.EndedAtIsLaterEndedAt", undefined, _type)])}
        },
        "NotAvailableTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected assignment template includes tasks that are unavailable for your organisation."])},
        "UnpublishedTaskUsageError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected assignment template includes tasks that are not published."])},
        "NotActiveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected users include ones that are not active."])},
        "FallbackError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occured. Please try again later."])}
      },
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
      "successText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment successfully created."])},
      "tagsFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by tags"])},
      "searchUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by email"])}
    }
  })
}
