<template>
	<div class="page-content devices-page">
		<spinner :show="$apollo.loading" />
		<h5 v-if="!devices.length && !$apollo.loading" class="display-5 devices-page__placeholder">
			{{ $t('emptyTablePlaceholder') }}
		</h5>
		<data-table
			:headers="headers"
			:items="items"
			:checkboxes="true"
			:selected="selectedDevices"
			@activateItem="openDeviceData"
			@selectedItems="devices => (selectedDevices = [...devices])"
		>
			<template #header>
				<div class="devices-page__table-header-left">
					<dropdown-list
						icon="fa-solid fa-filter"
						:list="Object.keys(filters)"
						v-model:selection="filters"
						:i18n="filtersI18n"
					/>
					<dropdown v-if="canMakeActions">
						<template #button>
							{{ $t('actions.placeholder') }}
						</template>
						<template #default="{ close }">
							<template v-for="(action, idx) in actions" :key="`action-${idx}`">
								<li v-if="action?.canDo">
									<button
										type="button"
										class="dropdown-item"
										@click.stop="handleAction(close)"
										:disabled="!selectedDevices.length"
									>
										<span>{{ action.label }}</span>
									</button>
								</li>
							</template>
						</template>
					</dropdown>
				</div>
			</template>
			<template #item.isValid="{ item }">
				<fa-icon v-if="item.isValid" icon="fa-regular fa-circle-check" class="icon-true" />
				<fa-icon v-else icon="fa-regular fa-circle-xmark" class="icon-false" />
			</template>
			<template #footer>
				<button v-if="canAddDevice" @click.stop="openAddDevice" class="btn btn-success">
					{{ $t('bindDevices') }}
				</button>
			</template>
		</data-table>

		<modal-device-data v-model:show="showDeviceData" :device="openedDevice" @updateDevice="updateDevice" />
		<modal-add-device v-model:show="showAddDevice" />

		<modal-confirm v-model:show="showConfirm" @confirm="bulkDelete">
			<template #header>{{ $t('bulkDelete.header') }}</template>
			<template #body>{{ $t('bulkDelete.body') }}</template>
		</modal-confirm>
	</div>
</template>

<script>
import { QueryError } from '@/errors';
import MYCOMPANY from '@/queries/views/devices/query-company-devices.graphql';
import DELETEDEVICES from '@/queries/views/devices/mutation-delete-devices.graphql';
import DataTable from '@/components/DataTable.vue';
import Dropdown from '@/components/dropdowns/Dropdown.vue';
import DropdownList from '@/components/dropdowns/DropdownList.vue';
import ModalDeviceData from '@/components/modals/ModalDeviceData.vue';
import ModalAddDevice from '@/components/modals/ModalAddDevice.vue';
import ModalConfirm from '@/components/modals/ModalConfirm.vue';
import Spinner from '@/components/Spinner.vue';

import toastMixin from '@/mixins/toast.js';
import devicePermissionsMixin from '@/mixins/permissions/devicePermissions.js';

export default {
	mixins: [toastMixin, devicePermissionsMixin],
	data() {
		return {
			devices: [],
			openedDevice: [],
			selectedDevices: [],
			displayedDevices: [],
			actions: [{ value: 'bulkDelete', label: this.$t('actions.bulkDelete'), canDo: this.canDeleteDevice }],
			filters: {
				showValid: true,
				showInvalid: true,
			},
			headers: [
				{ text: this.$t('devicesTable.headers.name'), field: 'name', type: 'string' },
				{ text: this.$t('devicesTable.headers.model'), field: 'model', type: 'string' },
				{ text: this.$t('devicesTable.headers.isValid'), field: 'isValid', type: 'boolean' },
			],
			showDeviceData: false,
			showAddDevice: false,
			showConfirm: false,
		};
	},
	methods: {
		getModel(device) {
			try {
				const systemInfo = JSON.parse(device.systemInfo);
				return (systemInfo && systemInfo.deviceModel) ?? this.$t('devicesTable.unrecognisedDevice');
			} catch (err) {
				return this.$t('devicesTable.unrecognisedDevice');
			}
		},
		openDeviceData(index) {
			this.openedDevice = this.devices.find(el => el.id === index);
			this.openedDevice.model = this.getModel(this.openedDevice);
			this.showDeviceData = true;
		},
		openAddDevice() {
			this.showAddDevice = true;
		},
		updateDevice(device) {
			const idx = this.devices.findIndex(el => el.id === device.id);
			this.devices[idx] = { ...device };
		},
		handleAction(close) {
			close();
			this.showConfirm = true;
		},
		async bulkDelete() {
			try {
				const { data } = await this.$apollo.mutate({
					mutation: DELETEDEVICES,
					variables: {
						devices: this.selectedDevices.map(el => +el.id),
					},
				});
				if (data.bulkDeleteDevices.success) {
					this.selectedDevices.forEach(el => {
						let index = this.devices.findIndex(item => item.id === el.id);
						this.devices.splice(index, 1);
					});
					this.selectedDevices = [];
					this.showToast('query.bulkDeleteDevices.success', '', 'success');
				} else {
					throw new QueryError(data.bulkDeleteDevices.error);
				}
			} catch (err) {
				let errType = 'FallbackError';
				if (err instanceof QueryError) {
					errType = err.cause.type;
				}
				this.showToast(`query.bulkDeleteDevices.errors.${errType}`, '', 'danger');
			}
		},
	},
	computed: {
		items() {
			const filters = this.filters;
			return this.devices
				.map(el => ({
					...el,
					model: this.getModel(el),
				}))
				.filter(function (item) {
					if (!filters.showValid && item.isValid) {
						return false;
					}
					if (!filters.showInvalid && !item.isValid) {
						return false;
					}
					return true;
				});
		},
		filtersI18n() {
			return {
				locale: this.$i18n.locale,
				messages: this.$i18n.getLocaleMessage(this.$i18n.locale).filters,
			};
		},
		canMakeActions() {
			return this.canDeleteDevice;
		},
	},
	components: {
		DataTable,
		Dropdown,
		DropdownList,
		ModalDeviceData,
		ModalAddDevice,
		ModalConfirm,
		Spinner,
	},
	apollo: {
		myCompany: {
			query: MYCOMPANY,
			manual: true,
			fetchPolicy: 'cache-and-network',
			result({ loading, data, errors }) {
				if (!loading) {
					this.devices = [...data.myCompany.devices.map(el => ({ ...el }))];
				}
			},
		},
	},
};
</script>

<i18n locale="ru" src="@/locales/ru/views/devices.json"></i18n>
<i18n locale="en" src="@/locales/en/views/devices.json"></i18n>

<style lang="scss" scoped>
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.devices-page {
	position: relative;

	&__placeholder {
		position: absolute;
		top: 40%;
		left: 0;
		width: 100%;
		text-align: center;
		color: $secondary;
	}

	&__table-header-left {
		display: flex;
		gap: 1rem;
	}

	.icon-true {
		font-size: 1.25rem;
		color: $success;
	}

	.icon-false {
		font-size: 1.25rem;
		color: $danger;
	}
}
</style>
