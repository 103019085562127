<template>
	<div>
		<p class="text-muted">{{ $t('gradeTableCaption') }}</p>
		<table class="table grade-table">
			<thead>
				<tr>
					<th></th>
					<th colspan="5" class="user-email" v-for="user in users" :key="user.id">{{ user.email }}</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td></td>
					<template v-for="user in users">
						<td class="text-center first-grade">{{ $t('date') }}</td>
						<td class="text-center">{{ $t('grade') }}</td>
						<td class="text-center">{{ $t('passingGrade') }}</td>
						<td class="text-center">{{ $t('maxGrade') }}</td>
						<td class="text-center last-grade">{{ $t('journal') }}</td>
					</template>
				</tr>
				<template v-for="(part, i) in parts" :key="part.id">
					<tr>
						<td class="part-name">{{ $t('part', { n: i + 1 }) }}</td>
						<td :colspan="users.length * 5"></td>
					</tr>
					<template v-for="task in part.tasks" :key="`${part.id}-${task.id}`">
						<tr>
							<td class="task-name">
								<button
									v-if="task.checkers?.length"
									class="btn"
									@click="toggleChecker(`${part.id}-${task.id}`)"
								>
									<fa-icon
										class="text-secondary"
										:icon="
											visibleCheckers.includes(`${part.id}-${task.id}`)
												? 'fa fa-square-minus'
												: 'fa fa-square-plus'
										"
									/>
									<span>{{ task.name }}</span>
								</button>
								<span v-else>{{ task.name }}</span>
							</td>
							<template v-for="user in users">
								<td class="text-center first-grade">
									{{
										grades[user.id][part.id][task.id].completed
											? formatDateTime(grades[user.id][part.id][task.id].date)
											: '&ndash;'
									}}
								</td>
								<td class="text-center">
									{{
										grades[user.id][part.id][task.id].completed
											? grades[user.id][part.id][task.id].grade
											: '&ndash;'
									}}
								</td>
								<td class="text-center">{{ grades[user.id][part.id][task.id].requiredGrade }}</td>
								<td class="text-center">{{ task.maxGrade }}</td>
								<td class="text-center last-grade">
									<button
										v-if="grades[user.id][part.id][task.id].boardData"
										@click="
											$emit('show-board', grades[user.id][part.id][task.id].boardData, user.id)
										"
										class="btn btn-sm btn-outline-dark"
									>
										<fa-icon icon="fa-solid fa-clipboard"></fa-icon>
									</button>
								</td>
							</template>
						</tr>
						<template v-for="checker in task.checkers" :key="`${part.id}-${task.id}-${checker.id}`">
							<tr
								class="bg-secondary bg-opacity-10"
								v-show="visibleCheckers.includes(`${part.id}-${task.id}`)"
							>
								<td class="checker-name" v-html="getLabel(checker)"></td>
								<template v-for="user in users">
									<td class="first-grade"></td>
									<td class="text-center">
										{{ grades[user.id][part.id][task.id].checkers[checker.checkerId] ?? '&ndash;' }}
									</td>
									<td></td>
									<td class="text-center last-grade">{{ checker.maxGrade }}</td>
									<td></td>
								</template>
							</tr>
						</template>
						<tr
							class="bg-secondary bg-opacity-10"
							v-show="visibleCheckers.includes(`${part.id}-${task.id}`)"
						>
							<td></td>
							<template v-for="user in users">
								<td class="first-grade text-center last-grade" colspan="5">
									<button
										class="btn btn-sm btn-secondary"
										@click="
											$emit(
												'show-attempts',
												grades[user.id][part.id][task.id].progressId,
												user.id
											)
										"
										:disabled="!grades[user.id][part.id][task.id].completed"
									>
										{{ $t('showAttempts') }}
									</button>
								</td>
							</template>
						</tr>
					</template>
				</template>
			</tbody>
		</table>
	</div>
</template>

<style scoped lang="scss">
.grade-table {
	th.user-email {
		text-align: center;
	}

	td.task-name {
		padding-left: 2rem;

		button {
			display: flex;
			align-items: baseline;
			gap: 0.25rem;

			padding: 0;
			border: none;

			svg {
				font-size: 75%;
				margin-right: 0.25rem;
			}
		}
	}

	td.checker-name {
		padding-left: 4rem;
	}

	td.part-name {
		font-weight: bold;
	}

	td.first-grade {
		border-left-width: 1px;
	}

	td.last-grade {
		border-right-width: 1px;
	}
}
</style>

<i18n>
	{
		"ru": {
			"gradeTableCaption": "\"Дата\" - дата последнего прохождения или прочерк, если ни одной попытки пройти задачу не было сделано. \"Балл\" - полученный за последнее прохождение задачи балл или прочерк, если ни одной попытки пройти задачу не было сделано. \"Прх.\" - проходной балл задачи. \"Макс.\" - максимальный балл, который может быть получен за задачу.",
			"part": "Часть {n}",
			"grade": "Балл",
			"date": "Дата",
			"passingGrade": "Прх.",
			"maxGrade": "Макс.",
			"showAttempts": "Показать все попытки",
			"journal": "Журнал"
		},
		"en": {
			"gradeTableCaption": "\"Date\" is the date of the latest attempt or a dash if no attempts were ever made. \"Grade\" is the grade for the latest attempt or a dash if no attempts were ever made. \"Pass\" is the task's passing grade. \"Max\" is the maximum grade obtainable for the task.",
			"part": "Part {n}",
			"grade": "Grade",
			"date": "Date",
			"passingGrade": "Pass",
			"maxGrade": "Max",
			"showAttempts": "Show all attempts",
			"journal": "Journal"
		}
	}
</i18n>

<script>
import { dateTimeMixin } from '@/mixins';
export default {
	props: {
		group: {
			type: Object,
			default: null,
		},
	},
	mixins: [dateTimeMixin],
	emits: ['show-attempts', 'show-board'],
	data() {
		return {
			visibleCheckers: [],
		};
	},
	computed: {
		users() {
			return this.group?.users ?? [];
		},
		assignmentForUsers() {
			const assignments = this.group?.assignmentForUsers ?? [];
			return this.users.map(function (user) {
				return assignments.find(asg => asg.user.id === user.id);
			});
		},
		parts() {
			return this.group?.assignment.parts ?? [];
		},
		grades() {
			return this.assignmentForUsers.reduce(function (acc, asgn) {
				acc[asgn.user.id] = asgn.parts.reduce(function (acc2, prt) {
					acc2[prt.id] = prt.tasks.reduce(function (acc3, tsk) {
						const checkers = tsk.lastAttempt?.results.reduce(function (acc4, rslt) {
							acc4[rslt.checker] = rslt.result;
							return acc4;
						}, {});
						acc3[tsk.id] = {
							progressId: tsk.progressId,
							completed: tsk.completed,
							grade: tsk.grade,
							requiredGrade: tsk.requiredGrade,
							date: tsk.lastAttempt?.createdAt,
							boardData: tsk.lastAttempt?.boardData,
							checkers: checkers ?? {},
						};
						return acc3;
					}, {});
					return acc2;
				}, {});
				return acc;
			}, {});
		},
	},
	methods: {
		toggleChecker(id) {
			if (this.visibleCheckers.includes(id)) {
				this.visibleCheckers = this.visibleCheckers.filter(chk => chk !== id);
			} else {
				this.visibleCheckers.push(id);
			}
		},
		getLabel(checker) {
			return checker.label.replaceAll('\\n', ' ');
		},
	},
	watch: {
		group(val) {
			if (!val) {
				this.visibleCheckers = [];
			}
		},
	},
};
</script>
