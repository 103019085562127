import { mapState } from 'vuex';
import PERMISSIONS from '@/utils/permissions.js';
import capitalToTitle from '@/utils/capitalToTitle';

export function permissionComputeds(...perms) {
	return perms.reduce(function (acc, perm) {
		const name = `can${capitalToTitle(perm)}`;
		const fn = function () {
			return this.hasPermission(PERMISSIONS[perm]);
		};
		acc[name] = fn;
		return acc;
	}, {});
}

export function authorOrPermissionMethods(...perms) {
	return perms.reduce(function (acc, perm) {
		const name = `can${capitalToTitle(perm)}`;
		const fn = function (authorId) {
			return authorId === this._user.id || this.hasPermission(PERMISSIONS[perm]);
		};
		acc[name] = fn;
		return acc;
	}, {});
}

export default {
	computed: mapState('auth', { _user: state => state.user }),
	methods: {
		hasPermission(permission) {
			if (this._user.isSuperuser) return true;
			return this._user.permissions?.some(perm => perm === permission);
		},
	},
};
