export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "emptyTablePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have any devices yet"])},
      "actions": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--- Action ---"])},
        "bulkDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbind"])}
      },
      "filters": {
        "showValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active devices"])},
        "showInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive devices"])}
      },
      "devicesTable": {
        "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device list"])},
        "headers": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
          "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model"])},
          "isValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])}
        },
        "unrecognisedDevice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unknown"])}
      },
      "bindDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bind devices"])},
      "bulkDelete": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove devices"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected devices will be removed. To continue press \"Confirm\"."])}
      },
      "query": {
        "bulkDeleteDevices": {
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devices successfully deleted."])},
          "errors": {
            "NotFoundError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One or more devices were not found."])},
            "FallbackError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occured. Please try again later."])}
          }
        }
      }
    }
  })
}
