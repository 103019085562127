<template>
	<div class="page-content">
		<div class="controls">
			<dropdown-list
				icon="fa-solid fa-filter"
				:list="filterDropdownItems"
				v-model:selection="filters"
				:i18n="filtersI18n"
			/>

			<dropdown class="order">
				<template #button>
					<fa-icon size="lg" icon="fa-solid fa-sort" />
					<span class="button-label">{{ $t(currentSort) }}</span>
				</template>
				<template #default>
					<li v-for="option in sortOptions">
						<button
							type="button"
							class="dropdown-item"
							:class="{ active: currentSort === option }"
							@click="currentSort = option"
						>
							<span>{{ $t(option) }}</span>
						</button>
					</li>
				</template>
			</dropdown>
		</div>
		<assignment v-for="assignment in displayedAssignments" :key="assignment.id" :assignment="assignment" />

		<h5 v-if="!displayedAssignments.length && !$apollo.loading" class="display-5 empty-placeholder">
			{{ $t('emptyPlaceholder') }}
		</h5>
		<spinner :show="$apollo.loading" />
	</div>
</template>

<style scoped lang="scss">
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.page-content {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 1rem;

	.empty-placeholder {
		position: absolute;
		top: 40%;
		left: 0;
		width: 100%;
		text-align: center;
		color: $secondary;
	}
}

.controls {
	display: flex;
	gap: 1rem;

	.order {
		.dropdown-toggle {
			.button-label {
				margin-left: 0.5rem;
			}
		}
	}
}
</style>

<i18n locale="ru" src="@/locales/ru/views/assignment-list.json"></i18n>
<i18n locale="en" src="@/locales/en/views/assignment-list.json"></i18n>

<script>
import dayjs from 'dayjs';
import QUERY from '@/queries/views/user-assignments/query.graphql';

import Assignment from '@/components/user-assignments/Assignment';
import DropdownList from '@/components/dropdowns/DropdownList';
import Dropdown from '@/components/dropdowns/Dropdown';
import Spinner from '@/components/Spinner';

export default {
	data() {
		return {
			assignments: [],
			filterDropdownItems: ['showEnded', 'showActive', 'showPending', null, 'showComplete', 'showIncomplete'],
			filters: {
				showEnded: false,
				showActive: true,
				showPending: false,
				showComplete: true,
				showIncomplete: true,
			},
			sortOptions: ['none', 'startedAtAsc', 'startedAtDesc', 'endedAtAsc', 'endedAtDesc'],
			currentSort: 'none',
		};
	},
	components: {
		Assignment,
		DropdownList,
		Dropdown,
		Spinner,
	},
	computed: {
		filtersI18n() {
			return {
				locale: this.$i18n.locale,
				messages: this.$i18n.getLocaleMessage(this.$i18n.locale).filters,
			};
		},
		displayedAssignments() {
			const filters = this.filters;
			const option = this.currentSort;
			return this.assignments
				.filter(function (asgn) {
					if (!filters.showEnded && asgn.group.ended) {
						return false;
					}
					if (!filters.showActive && !asgn.group.ended && !asgn.group.pending) {
						return false;
					}
					if (!filters.showPending && asgn.group.pending) {
						return false;
					}
					if (!filters.showComplete && asgn.completed) {
						return false;
					}
					if (!filters.showIncomplete && !asgn.completed) {
						return false;
					}
					return true;
				})
				.sort(function (a, b) {
					if (option === 'none') {
						return 0;
					}
					const startA = dayjs(a.group.startedAt);
					const startB = dayjs(b.group.startedAt);
					const endA = dayjs(a.group.endedAt);
					const endB = dayjs(b.group.endedAt);
					if (option === 'startedAtAsc') {
						return startA.isBefore(startB) ? -1 : 1;
					}
					if (option === 'startedAtDesc') {
						return startA.isBefore(startB) ? 1 : -1;
					}
					if (option === 'endedAtAsc') {
						return endA.isBefore(endB) ? -1 : 1;
					}
					if (option === 'endedAtDesc') {
						return endA.isBefore(endB) ? 1 : -1;
					}
				});
		},
	},
	apollo: {
		assignments: {
			query: QUERY,
			manual: true,
			fetchPolicy: 'cache-and-network',
			result({ loading, data }) {
				if (!loading) {
					this.assignments = data.userAssignments;
				}
			},
			variables() {
				return { uid: this.$store.state.auth.user.id };
			},
		},
	},
};
</script>
