<template>
	<nav class="vr-navbar navbar">
		<button @click="doLogout" class="btn" type="submit">
			<fa-icon icon="fa-solid fa-arrow-right-to-bracket" />
		</button>
	</nav>
</template>

<script>
export default {
	methods: {
		async doLogout() {
			try {
				await this.$store.dispatch('auth/logout');
				await this.$router.push({ name: 'Login' });
			} catch (err) {
				console.log(err);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.vr-navbar {
	grid-area: navbar;
	align-self: start;
	border-bottom: 1px solid $gray-300;

	button {
		margin-left: auto;
		margin-right: 1.5rem;
	}
}
</style>
