export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ru",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация"])},
      "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "companyPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название организации"])},
      "passwordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
      "confirmPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторите пароль"])},
      "submitBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрировать"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я соглашаюсь с условиями пользовательского соглашения"])},
      "validation": {
        "email": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите адрес электронной почты."])},
          "notSameAsPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email не может совпадать с паролем."])},
          "validEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес электронной почты должен быть валидным."])}
        },
        "companyName": {
          "notSameAsPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название организации не может совпадать с паролем."])}
        },
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите пароль."])},
          "sameAs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароли не совпадают."])},
          "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Пароль должен состоять не менее чем из ", _interpolate(_named("passwordLength")), " символов."])},
          "whiteSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль не должен содержать пробелов и отступов."])}
        },
        "agreement": {
          "signAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лизензионное соглашение должно быть подписанно"])}
        }
      },
      "query": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На указанную почту выслано письмо со ссылкой для активации аккаунта. Просьба перейти по данной ссылке."])},
        "error": {
          "uniqueUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь с указанным почтовым адресом уже зарегистрирован."])},
          "uniqueCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Организация с указанным названием уже зарегистрирована."])},
          "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось отправить письмо со ссылкой подтверждения на указанный почтовый адрес. Попробуйте использовать другой email."])}
        }
      }
    }
  })
}
