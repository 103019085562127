export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ru",
    "resource": {
      "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи"])},
      "view_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотр списка пользователей"])},
      "add_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание новых пользователей"])},
      "change_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение существующих пользователей"])},
      "change_user_roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение ролей пользователей"])},
      "trash_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаление пользователей"])},
      "delete_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Безвозвратное удаление пользователей"])},
      "ActivationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коды активации"])},
      "add_activationcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание новых кодов активации"])},
      "Licence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лицензии"])},
      "view_licence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотр списка лицензий"])},
      "activate_licence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активация лицензий"])},
      "Device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Устройства"])},
      "view_device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотр списка устройств"])},
      "add_device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Привязка новых устройств"])},
      "change_device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение существующих устройств"])},
      "delete_device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отвязка устройств"])},
      "Task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задачи"])},
      "add_task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание новых задач"])},
      "change_task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение существующих задач"])},
      "publish_task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публикация задач"])},
      "archive_task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивация задач"])},
      "trash_task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаление задач"])},
      "delete_task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Безвозвратное удаление задач"])},
      "Template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблоны заданий"])},
      "view_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотр списка шаблонов заданий"])},
      "add_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание новых шаблонов заданий"])},
      "change_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение существующих шаблонов заданий"])},
      "delete_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаление шаблонов заданий"])},
      "Assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задания"])},
      "view_assignmentforuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотр списка назначенных заданий"])},
      "add_assignmentforuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назначение новых заданий"])},
      "change_assignmentforuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение назначенных заданий"])},
      "delete_assignmentforuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена назначения заданий"])},
      "Role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роли"])},
      "view_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотр списка ролей"])},
      "add_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавление новых ролей"])},
      "change_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение существующих ролей"])},
      "delete_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаление ролей"])},
      "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Организация"])},
      "change_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение настроек организации"])},
      "Tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теги"])},
      "view_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотр списка тегов"])},
      "add_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавление новых тегов"])},
      "change_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение существующих тегов"])},
      "delete_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаление тегов"])}
    }
  })
}
