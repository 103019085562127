export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот сайт использует cookies для хранения данных. Продолжая использовать сайт, вы даёте согласие на работу с этими файлами."])}
      },
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This site uses cookies for data storage. By continuing to use site you consent to the use cookies."])}
      }
    }
  })
}
