export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "productName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vic's Science Studio"])},
      "sections": {
        "user": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
          "links": {
            "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assignments"])},
            "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["account"])}
          }
        },
        "company": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
          "links": {
            "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["settings"])},
            "licences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licences"])},
            "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tasks"])},
            "devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["devices"])},
            "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["users"])},
            "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assignments"])},
            "assignmentTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assignment templates"])},
            "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["roles"])}
          }
        },
        "info": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
          "links": {
            "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["about"])},
            "userAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["user agreement"])}
          }
        }
      }
    }
  })
}
