export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password reset"])},
      "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the email you used to register."])},
      "submitBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get reset link"])},
      "validation": {
        "email": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter an email address."])}
        }
      },
      "query": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password reset link has been send to your email address."])},
        "error": {
          "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User with provided email address not found."])},
          "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occured. Please try again later."])}
        }
      }
    }
  })
}
