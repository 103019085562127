export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "modalAddLicence": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licence activation"])},
        "fieldPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licence key"])},
        "submitBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate"])},
        "cancelBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
      },
      "successes": {
        "bounded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licence activation successful."])}
      },
      "validations": {
        "EmptyLicenceKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter licence key."])}
      },
      "errors": {
        "fallBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occured. Please try again later."])},
        "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not find a licence matching this key."])},
        "alreadyActivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This licence has already been activated"])},
        "boundedToOther": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This licence has already been activated by another organisation."])},
        "invalidValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong key format."])}
      }
    }
  })
}
