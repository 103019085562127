import base from './base';
import { permissionComputeds, authorOrPermissionMethods } from './base';

export default {
	mixins: [base],
	methods: authorOrPermissionMethods(
		'CHANGE_ASSIGNMENT',
		'DELETE_ASSIGNMENT'
	),
	computed: permissionComputeds('VIEW_ASSIGNMENT', 'ADD_ASSIGNMENT'),
};
