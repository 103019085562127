export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ru",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Восстановление пароля"])},
      "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите почтовый адрес, который вы использовали при регистрации."])},
      "submitBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получить ссылку"])},
      "validation": {
        "email": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите адрес электронной почты."])}
        }
      },
      "query": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка для восстановления пароля отправлена по указанной почте."])},
        "error": {
          "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь с указанными почтовым ящиком не существует."])},
          "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка запроса. Пожалуйста, попробуйте повторить попытку позже."])}
        }
      }
    }
  })
}
