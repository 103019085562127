<template>
	<div>
		<page-section :title="$t('title')">
			<form class="change-password-form" @submit.prevent="changePassword">
				<password-input
					v-model:password="password"
					:vError="v$.password.$error"
					:placeholder="$t('fields.current')"
					:vErrorMsg="v$.password.$errors[0]?.$message"
				/>
				<password-input
					v-model:password="newPassword"
					:vError="v$.newPassword.$error"
					:placeholder="$t('fields.new')"
					:vErrorMsg="v$.newPassword.$errors[0]?.$message"
				/>
				<password-input
					v-model:password="confirmNewPassword"
					:vError="v$.confirmNewPassword.$error"
					:placeholder="$t('fields.confirmNew')"
					:vErrorMsg="v$.confirmNewPassword.$errors[0]?.$message"
				/>
				<button class="btn btn-primary" type="submit">
					{{ $t('changePasswordBtn') }}
				</button>
			</form>
		</page-section>
	</div>
</template>

<i18n locale="ru" src="@/locales/ru/components/user-settings-view/change-password-section.json"></i18n>
<i18n locale="en" src="@/locales/en/components/user-settings-view/change-password-section.json"></i18n>

<script>
import PageSection from '@/components/page-sections/PageSection.vue';
import PasswordInput from '@/components/inputs/PasswordInput.vue';
import { QueryError } from '@/errors';
import useVuelidate from '@vuelidate/core';
import { required, sameAs, minLength, helpers } from '@vuelidate/validators';
import CHANGEPASSWORD from '@/queries/views/user-settings/mutation-change-password.graphql';
import { toastMixin } from '@/mixins';

export default {
	setup() {
		return { v$: useVuelidate() };
	},
	mixins: [toastMixin],
	data() {
		return {
			password: null,
			newPassword: null,
			confirmNewPassword: null,
			passwordMinLength: 8,
			serverError: null,
		};
	},
	methods: {
		async changePassword() {
			this.v$.$touch();
			if (this.v$.$error) {
				return;
			}
			try {
				const { data } = await this.$apollo.mutate({
					mutation: CHANGEPASSWORD,
					variables: { password: this.password, newPassword: this.newPassword },
				});
				if (data.changeUserPassword.success) {
					this.password = null;
					this.newPassword = null;
					this.confirmNewPassword = null;
					this.showToast('query.success.passwordChanged', '', 'success');
				} else {
					throw new QueryError(data.changeUserPassword.error);
				}
			} catch (err) {
				if (err instanceof QueryError) {
					this.serverError = `errors.${err.cause.type}`;
				} else {
					this.serverError = 'errors.fallback';
				}
				this.handleError(err);
			} finally {
				this.v$.$reset();
			}
		},
		handleError(err) {
			if (this.serverError === 'errors.InvalidValueError' && err.cause.field === 'password') {
				this.showToast('query.errors.invalidPassword', '', 'danger');
			}
			if (this.serverError === 'errors.FallbackError') {
				this.showToast('query.errors.fallBack', '', 'danger');
			}
		},
	},
	validations() {
		return {
			password: {
				required: helpers.withMessage(this.$t('validation.password.required'), required),
				notSameAsNew: helpers.withMessage(
					this.$t('validation.password.notSameAs'),
					() => this.password !== this.newPassword || this.password !== this.confirmNewPassword
				),
			},
			newPassword: {
				required: helpers.withMessage(this.$t('validation.password.required'), required),
				minLength: helpers.withMessage(
					this.$t('validation.password.minLength', { passwordLength: this.passwordMinLength }),
					minLength(this.passwordMinLength)
				),
				whiteSpaces: helpers.withMessage(
					this.$t('validation.password.whiteSpaces'),
					() => !/\s/g.test(this.newPassword)
				),
				sameAsConfirmNewPassword: helpers.withMessage(
					this.$t('validation.password.sameAs'),
					sameAs(this.confirmNewPassword)
				),
			},
			confirmNewPassword: {
				required: helpers.withMessage(this.$t('validation.password.required'), required),
				minLength: helpers.withMessage(
					this.$t('validation.password.minLength', { passwordLength: this.passwordMinLength }),
					minLength(this.passwordMinLength)
				),
				whiteSpaces: helpers.withMessage(
					this.$t('validation.password.whiteSpaces'),
					() => !/\s/g.test(this.confirmNewPassword)
				),
				sameAsNewPassword: helpers.withMessage(this.$t('validation.password.sameAs'), sameAs(this.newPassword)),
			},
		};
	},
	components: {
		PageSection,
		PasswordInput,
	},
};
</script>

<style lang="scss" scoped>
.change-password-form {
	& > * + * {
		margin-top: 1rem;
	}
}
</style>
