export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ru",
    "resource": {
      "modalAddLicence": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активиция лицензии"])},
        "fieldPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лицензионный ключ"])},
        "submitBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активировать"])},
        "cancelBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])}
      },
      "successes": {
        "bounded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лицензия была успешно активирована."])}
      },
      "validations": {
        "EmptyLicenceKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите лицензионный ключ"])}
      },
      "errors": {
        "fallBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка запроса. Пожалуйста, попробуйте позже."])},
        "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лицензия с указанным ключом не найдена."])},
        "alreadyActivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лицензия уже активирована"])},
        "invalidValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный формат ключа."])}
      }
    }
  })
}
