export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "gradeTableCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Дата\" - дата последнего прохождения или прочерк, если ни одной попытки пройти задачу не было сделано. \"Балл\" - полученный за последнее прохождение задачи балл или прочерк, если ни одной попытки пройти задачу не было сделано. \"Прх.\" - проходной балл задачи. \"Макс.\" - максимальный балл, который может быть получен за задачу."])},
        "part": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Часть ", _interpolate(_named("n"))])},
        "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Балл"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
        "passingGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прх."])},
        "maxGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Макс."])},
        "showAttempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать все попытки"])},
        "journal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Журнал"])}
      },
      "en": {
        "gradeTableCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Date\" is the date of the latest attempt or a dash if no attempts were ever made. \"Grade\" is the grade for the latest attempt or a dash if no attempts were ever made. \"Pass\" is the task's passing grade. \"Max\" is the maximum grade obtainable for the task."])},
        "part": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Part ", _interpolate(_named("n"))])},
        "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "passingGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass"])},
        "maxGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max"])},
        "showAttempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all attempts"])},
        "journal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journal"])}
      }
    }
  })
}
