export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ru",
    "resource": {
      "tagsFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтровать по тегам"])},
      "newTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать шаблон"])},
      "emptyPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас ещё нет шаблонов заданий"])},
      "assignmentCountLimit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Шаблонов заданий в организации: ", _interpolate(_named("assignmentCount")), "/", _interpolate(_named("assignmentLimit"))])},
      "assignmentCountLimitTipOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Суммарное число задач, созданных организацией."])},
      "assignmentCountLimitTipTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для увеличения лимита задач в организации, пожалуйста, обратитесь в отдел продаж."])},
      "assignmentLimitReached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Достигнуто максимально допустимое количество шаблонов заданий в организации. Чтобы иметь возможность создавать новые шаблоны, удалите часть существующих или обратитесь в поддержку для увеличения лимита."])},
      "filters": {
        "local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показывать шаблоны организации"])},
        "foreign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показывать шаблоны, доступные по лицензии"])},
        "editable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показывать редактируемые шаблоны"])},
        "static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показывать неизменяемые шаблоны"])}
      },
      "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет сортировки"])},
      "сreatedAtAsc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По времени создания (сначала старые)"])},
      "сreatedAtDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По времени создания (сначала новые)"])},
      "nameAsc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По имени (А-я)"])},
      "nameDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По имени (я-А)"])},
      "authorAsc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По автору (А-я)"])},
      "authorDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По автору (я-А)"])},
      "searchTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск по шаблонам"])}
    }
  })
}
