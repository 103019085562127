export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter new password"])},
      "passwordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "confirmPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password"])},
      "submitBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
      "obtainLinkAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend reset link"])},
      "toLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
      "validation": {
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter password"])},
          "sameAs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["passwords do not match"])},
          "minLinegth": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["password must contain at least ", _interpolate(_named("passwordLength")), " symbols"])},
          "whiteSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password must not contain spaces or tabs"])}
        }
      },
      "query": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password successfully changed"])},
        "error": {
          "invalidTokenValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password reset link has been used or has expired."])},
          "invalidPasswordValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password should match your email address."])},
          "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occured. Please try again later."])}
        }
      }
    }
  })
}
