export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идёт привязка личного кабинета, пожалуйcта, подождите..."])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Привязка личного кабинета успешна."])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При привязке личного кабинета произошла ошибка. Пожалуйста, попробуйте позже."])}
      },
      "en": {
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account linking in progress..."])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account linked."])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to link account. Please try again later."])}
      }
    }
  })
}
