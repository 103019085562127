export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
      "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "companyPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation name"])},
      "passwordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "confirmPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
      "submitBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I accept the terms of the user agreement"])},
      "validation": {
        "email": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter you email address"])},
          "notSameAsPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email must not match your password."])},
          "validEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address must be valid."])}
        },
        "companyName": {
          "notSameAsPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation name must not match password."])}
        },
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter password."])},
          "sameAs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match."])},
          "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Password must be at least ", _interpolate(_named("passwordLength")), " characters long."])},
          "whiteSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must not contain spaces or tabulation."])}
        }
      },
      "query": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An account activation link has been sent to your email address. Please follow it to finish your registration."])},
        "error": {
          "uniqueUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A user with this email address has already been registered."])},
          "uniqueCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An organisation with this name already exists."])},
          "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not send an email to your address. Please try another one."])}
        }
      }
    }
  })
}
