<template>
	<modal size="small" v-model:show="localShow">
		<template #header>{{ $t('title') }}</template>
		<template #body>{{ $t('body') }}</template>
		<template #footer>
			<button type="button" class="btn btn-outline-secondary d-block w-25" @click="localShow = false">
				{{ $t('cancel') }}
			</button>
			<button type="button" class="btn btn-danger d-block w-25" @click="confirm">{{ $t('confirm') }}</button>
		</template>
	</modal>
</template>

<i18n>
	{
		"ru": {
			"title": "Внимание!",
			"body": "Назначенное задание будет отменено. Прогресс пользователей по его выполнению будет потерян. Вы уверены, что хотите отменить задание?",
			"cancel": "Нет",
			"confirm": "Да"
		},
		"en": {
			"title": "Warning!",
			"body": "This assignment would be cancelled. All related user progress will be lost forever. Do you really want to cancel this assignment?",
			"cancel": "No",
			"confirm": "Yes"
		}
	}
</i18n>

<script>
import Modal from '@/components/modals/VrModal';

export default {
	emits: ['update:show', 'confirm'],
	props: {
		show: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		Modal,
	},
	computed: {
		localShow: {
			get() {
				return this.show;
			},
			set(v) {
				return this.$emit('update:show', v);
			},
		},
	},
	methods: {
		confirm() {
			this.$emit('confirm');
			this.localShow = false;
		},
	},
};
</script>
