<template>
	<teleport to="#toast-container">
		<transition-group name="toasts">
			<toast v-for="toast in visibleToasts" :key="toast.id" v-bind="toast" />
		</transition-group>
	</teleport>
</template>

<style scoped lang="scss">
.toasts-move,
.toasts-enter-active,
.toasts-leave-active {
	transition: transform 0.5s, opacity 0.5s;
}

.toasts-enter-from,
.toasts-leave-to {
	opacity: 0;
	transform: translateX(calc(100% + 1rem));
}
</style>

<script>
import { mapState } from 'vuex';

import Toast from './Toast';

const MAX_VISIBLE = 5;

export default {
	components: {
		Toast,
	},
	computed: {
		...mapState('toasts', ['toasts']),
		visibleToasts() {
			if (this.toasts.length <= MAX_VISIBLE) {
				return this.toasts;
			} else {
				return this.toasts.slice(0, MAX_VISIBLE);
			}
		},
	},
};
</script>
