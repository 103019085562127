export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регион"])},
        "platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платформа"])},
        "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проходной балл"])}
      },
      "en": {
        "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
        "platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform"])},
        "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passing grade"])}
      }
    }
  })
}
