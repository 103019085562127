<template>
	<nav v-if="pagesTotal > 1" aria-label="Page navigation example">
		<ul class="pagination">
			<a
				@click="currentPage = 1"
				:class="['page-link', { disabled: currentPage == 1 }]"
				href="#"
				aria-label="Previous"
			>
				<span aria-hidden="true">&laquo;</span>
			</a>
			<a @click="decrementPage" :class="['page-link', { disabled: currentPage == 1 }]" href="#" aria-label="Next">
				<span aria-hidden="true">&lsaquo;</span>
			</a>

			<li v-if="displayedPages.some((el, idx) => el !== leftEdgeState[idx])" class="page-item">
				<a class="page-link" href="#">...</a>
			</li>

			<li
				v-for="page in displayedPages"
				:key="`licences-page-link-${page - 1}`"
				@click="currentPage = page"
				:class="['page-item', { active: currentPage === page }]"
			>
				<a class="page-link" href="#">{{ page }}</a>
			</li>

			<li v-if="displayedPages.some((el, idx) => el !== rightEdgeState[idx])" class="page-item">
				<a class="page-link disabled" href="#">...</a>
			</li>

			<a
				@click="incrementPage"
				:class="['page-link', { disabled: currentPage == pagesTotal }]"
				href="#"
				aria-label="Next"
			>
				<span aria-hidden="true">&rsaquo;</span>
			</a>
			<a
				@click="currentPage = pagesTotal"
				:class="['page-link', { disabled: currentPage == pagesTotal }]"
				href="#"
				aria-label="Next"
			>
				<span aria-hidden="true">&raquo;</span>
			</a>
		</ul>
	</nav>
</template>

<script>
export default {
	emits: ['changePage'],
	data() {
		return {
			currentPage: 1,
			displayedPages: [],
		};
	},
	props: {
		itemsOnPage: {
			type: Number,
			default: 5,
		},
		itemsTotal: {
			type: Number,
			default: 0,
		},
		maxPagesRange: {
			type: Number,
			default: 5,
		},
	},
	methods: {
		decrementPage() {
			if (this.canDecrement) this.currentPage -= 1;
		},
		incrementPage() {
			if (this.canIncrement) this.currentPage += 1;
		},
		calcBounds() {
			const rightBound = this.currentPage * this.itemsOnPage;
			const leftBound = rightBound - this.itemsOnPage;
			return [leftBound, rightBound];
		},
		changePagesRange() {
			if (this.currentPage === 1) {
				this.displayedPages = this.leftEdgeState;
				return;
			}
			if (this.currentPage === this.pagesTotal) {
				this.displayedPages = this.rightEdgeState;
				return;
			}
			if (this.canDecrease) {
				this.displayedPages = this.displayedPages.map((_, idx) => this.currentPage + idx - 1);
			}
			if (this.canIncrease) {
				this.displayedPages = this.displayedPages.map(
					(_, idx) => this.currentPage - this.displayedPages.length + idx + 2
				);
			}
		},
	},
	computed: {
		pagesTotal() {
			return Math.ceil(this.itemsTotal / this.itemsOnPage);
		},
		canDecrement() {
			return this.currentPage > 1;
		},
		canIncrement() {
			return this.currentPage < this.pagesTotal;
		},
		canDecrease() {
			return this.canDecrement && this.currentPage <= this.displayedPages[0];
		},
		canIncrease() {
			return this.canIncrement && this.currentPage >= this.displayedPages[this.displayedPages.length - 1];
		},
		leftEdgeState() {
			const pages = this.pagesTotal <= this.maxPagesRange ? this.pagesTotal : this.maxPagesRange;
			return [...Array(pages).keys()].map(el => el + 1);
		},
		rightEdgeState() {
			if (this.pagesTotal <= this.maxPagesRange) {
				return [...Array(this.pagesTotal).keys()].map(el => el + 1);
			}
			return [...Array(this.maxPagesRange).keys()].map(el => el + this.pagesTotal - this.maxPagesRange + 1);
		},
	},
	watch: {
		currentPage() {
			this.$emit('changePage', this.currentPage, this.calcBounds());
			this.changePagesRange();
		},
		itemsTotal: {
			handler() {
				this.changePagesRange();
			},
			immediate: true
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.pagination {
	--bs-pagination-color: $dark;
	--bs-pagination-hover-color: $dark;
	--bs-pagination-active-bg: grey;

	margin-bottom: 0;
}
</style>
