import { ApolloClient, InMemoryCache, HttpLink, split } from '@apollo/client/core';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { createApolloProvider } from '@vue/apollo-option';

const hostname = window.location.hostname;
let VUE_APP_API_URL = '';

switch(true) {
	case hostname.endsWith(process.env.VUE_APP_EN_DOMAIN):
		VUE_APP_API_URL = process.env.VUE_APP_API_URL_EN;
		break;
	case hostname.endsWith(process.env.VUE_APP_RU_DOMAIN):
		VUE_APP_API_URL = process.env.VUE_APP_API_URL_RU;
		break;
	default:
		VUE_APP_API_URL = process.env.VUE_APP_API_URL_RU;
		break;
	}

const httpLink = new HttpLink({
	uri: VUE_APP_API_URL,
	credentials: 'include',
});

const batchHttpLink = new BatchHttpLink({
	uri: VUE_APP_API_URL,
	credentials: 'include',
	batchDebounce: true,
	batchMax: 99,
});

const link = split(operation => operation.getContext().noBatch, httpLink, batchHttpLink);

const cache = new InMemoryCache({
	typePolicies: {
		AssignedTask: {
			keyFields: ['id', 'progressId'],
		},
		AssignmentPartForUser: {
			keyFields: ['id', 'assignmentForUserId'],
		},
	},
});

const apolloClient = new ApolloClient({
	link,
	cache,
});

const apolloProvider = createApolloProvider({
	defaultClient: apolloClient,
});

export default apolloProvider;

export { apolloClient as apollo };
