import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
	faPlus,
	faArrowDown19,
	faShuffle,
	faBars,
	faBarsStaggered,
	faPerson,
	faArrowDown,
	faArrowRightToBracket,
	faClipboard,
	faPenToSquare,
	faXmark,
	faVrCardboard,
	faWrench,
	faInfo,
	faChevronUp,
	faChevronDown,
	faEye,
	faEyeSlash,
	faBookOpen,
	faFilter,
	faSort,
	faSortUp,
	faSortDown,
	faCheck,
	faUserGroup,
	faClipboardList,
	faFileInvoice,
	faCircleExclamation,
	faTriangleExclamation,
	faUsersRectangle,
	faStar,
	faCookieBite,
	faCircleQuestion as faCircleQuestionSolid,
	faSquarePlus,
	faSquareMinus,
	faChevronLeft,
	faCrown,
	faUserTag,
	faCircleInfo,
	faGear,
	faArrowsLeftRight,
} from '@fortawesome/free-solid-svg-icons';

import {
	faCircleQuestion,
	faCircleCheck,
	faCircleXmark,
	faFaceSadTear,
	faFaceDizzy,
	faClipboard as faClipboardRegular,
} from '@fortawesome/free-regular-svg-icons';

library.add(
	faPlus,
	faArrowDown19,
	faShuffle,
	faBars,
	faBarsStaggered,
	faPerson,
	faArrowDown,
	faArrowRightToBracket,
	faClipboard,
	faClipboardRegular,
	faPenToSquare,
	faXmark,
	faCircleXmark,
	faVrCardboard,
	faWrench,
	faInfo,
	faChevronUp,
	faChevronDown,
	faEye,
	faEyeSlash,
	faBookOpen,
	faCircleQuestion,
	faCircleQuestionSolid,
	faFilter,
	faSort,
	faSortUp,
	faSortDown,
	faCheck,
	faUserGroup,
	faClipboardList,
	faCircleCheck,
	faFileInvoice,
	faCircleExclamation,
	faTriangleExclamation,
	faUsersRectangle,
	faStar,
	faCookieBite,
	faFaceSadTear,
	faFaceDizzy,
	faSquarePlus,
	faSquareMinus,
	faChevronLeft,
	faCrown,
	faUserTag,
	faCircleInfo,
	faGear,
	faArrowsLeftRight
);

export default function (app) {
	app.component('fa-icon', FontAwesomeIcon);
}
