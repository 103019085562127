export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
      "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "passwordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset your password"])},
      "submitBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
      "validation": {
        "email": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email address"])}
        },
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your password"])}
        }
      },
      "query": {
        "error": {
          "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A user matching this email and password was not found."])},
          "notActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User is not active. To activate the user, please follow the link in the email sent to your registration email address."])},
          "MutationTrashedEntityError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User has been deleted. Contact your organisation's administrator."])},
          "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occured. Please try again later."])}
        }
      }
    }
  })
}
