import Modal from '@/components/modals/VrModal';

export default {
	props: {
		show: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['update:show'],
	computed: {
		localShow: {
			get() {
				return this.show;
			},
			set(v) {
				return this.$emit('update:show', v);
			},
		},
	},
	components: {
		Modal,
	},
};
