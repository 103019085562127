import { nanoid } from 'nanoid';

export default {
	namespaced: true,
	state: {
		toasts: [],
	},
	mutations: {
		addToast(state, toast) {
			state.toasts.push(toast);
		},
		removeToast(state, { id }) {
			state.toasts = state.toasts.filter(t => t.id !== id);
		},
	},
	actions: {
		showToast({ commit }, { title, message, type, timer }) {
			const id = nanoid();
			const toast = { title, message, type, id, timer };
			commit('addToast', toast);
		},
		hideToast({ state, commit }, toast) {
			commit('removeToast', toast);
		},
	},
};
