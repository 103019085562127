<template>
	<vr-modal v-model:show="modalShow" size="small" align="center">
		<template v-slot:header><slot name="header" /></template>
		<template v-slot:body><slot name="body" /></template>
		<template v-slot:footer>
			<button @click="modalShow = false" class="btn btn-secondary">{{ $t('cancel') }}</button>
			<button @click="confirm" class="btn" :class="[`btn-${confirmButtonStyle}`]">{{ $t('confirm') }}</button>
		</template>
	</vr-modal>
</template>

<script>
import VrModal from '@/components/modals/VrModal.vue';

export default {
	emits: ['update:show', 'confirm'],
	methods: {
		confirm() {
			this.$emit('confirm');
			this.modalShow = false;
		},
	},
	computed: {
		modalShow: {
			get() {
				return this.show;
			},
			set(show) {
				this.$emit('update:show', show);
			},
		},
	},
	components: {
		VrModal,
	},
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		event: {
			type: String,
			default: '',
		},
		confirmButtonStyle: {
			type: String,
			default: 'success', // 'success' or 'danger'
		},
	},
};
</script>

<i18n>
{
    "ru": {
        "confirm": "Подтвердить",
		"cancel": "Отмена"
    },
    "en": {
    	"confirm": "Confirm",
    	"cancel": "Cancel"
    }
}
</i18n>
