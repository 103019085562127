export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])},
        "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сейчас"])}
      },
      "en": {
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now"])}
      }
    }
  })
}
