<template>
	<dropdown :icon="icon" :align="align" :disabled="disabled">
		<li v-for="item in normalizedList">
			<button v-if="item" type="button" class="dropdown-item" @click="toggleFilter(item)">
				<span>{{ $te(item.label) ? $t(item.label) : item.label }}</span>
				<fa-icon class="ms-auto" :class="{ hidden: !isSelected(item) }" icon="fa-solid fa-check" />
			</button>
			<hr v-else class="dropdown-divider" />
		</li>
	</dropdown>
</template>

<style scoped lang="scss">
.dropdown-item {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	.hidden {
		visibility: hidden;
	}
}
</style>

<script>
import Dropdown from '@/components/dropdowns/Dropdown.vue';
export default {
	props: {
		icon: {
			type: String,
			default: null,
		},
		align: {
			type: String,
			default: 'left',
		},
		list: {
			type: Array,
			default: () => [],
		},
		selection: {
			type: [Object, Array],
			default: () => ({}),
		},
		i18n: {
			type: Object,
			default: () => ({ locale: 'ru', messages: {} }),
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		Dropdown,
	},
	computed: {
		normalizedList() {
			return this.list.map(function (item) {
				if (typeof item === 'string') {
					return { value: item, label: item };
				} else {
					return item;
				}
			});
		},
	},
	methods: {
		isSelected(item) {
			if (Array.isArray(this.selection)) {
				return this.selection.includes(item.value);
			} else {
				return this.selection[item.value];
			}
		},
		toggleFilter(item) {
			let newSelection;
			if (Array.isArray(this.selection)) {
				if (this.isSelected(item)) {
					newSelection = this.selection.filter(i => i !== item.value);
				} else {
					newSelection = [...this.selection, item.value];
				}
			} else {
				newSelection = { ...this.selection, [item.value]: !this.selection[item.value] };
			}
			this.$emit('update:selection', newSelection);
		},
	},
	i18n: {},
	created() {
		this.$i18n.setLocaleMessage(this.i18n.locale, this.i18n.messages);
	},
};
</script>
