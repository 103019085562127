<template>
	<div class="tag-list">
		<span class="tag badge rounded-pill text-bg-primary" v-for="tag in tags" :key="tag.id">
			<span class="tag-name">{{ tag.name }}</span>
			<button v-if="editable" class="btn-close btn-close-white ms-1" @click.stop="removeTag(tag)"></button>
		</span>
		<span v-if="editable" class="tag-selector" v-click-outside="hideList">
			<div class="input-group input-group-sm">
				<input
					class="form-control form-control-sm"
					type="text"
					v-model="searchText"
					@focus="showList = true"
					:id="inputId"
				/>
				<button
					v-if="allowCreate"
					class="btn btn-outline-success"
					type="button"
					:disabled="!searchText"
					@click.stop="showCreationModal"
				>
					<fa-icon icon="fa-solid fa-plus" />
				</button>
			</div>
			<div
				v-show="showList"
				class="list-group tag-selector__list"
				:class="{ 'tag-selector__list--short': short, 'tag-selector__list--top': top }"
			>
				<div v-if="filteredTags?.length">
					<div
						v-for="tag in filteredTags"
						:key="tag.id"
						class="list-group-item list-group-item-action p-0 d-flex"
					>
						<button
							type="button"
							class="btn list__toggle"
							:class="{ 'border-end': allowDelete }"
							@click="toggleTag(tag)"
						>
							<span class="badge rounded-pill text-bg-primary">{{ tag.name }}</span>
						</button>
						<button
							type="button"
							v-if="allowDelete"
							class="btn-close px-3 align-self-center"
							@click.stop="showDeleteModal(tag)"
						></button>
					</div>
				</div>
				<div v-else-if="searchText" class="list-group-item">
					{{ $t('searchTag', { search: searchText?.toLowerCase() }) }}
				</div>
				<div v-else class="list-group-item">{{ $t('absenceTag') }}</div>
			</div>
		</span>

		<modal v-if="allowCreate" v-model:show="isTagCreationModalVisible" size="small" align="center">
			<template #header>{{ $t('createModalHeader') }}</template>
			<template #body
				><p>{{ $t('createModalBody', { tag: searchText }) }}</p></template
			>
			<template #footer>
				<button class="modal-button btn btn-outline-secondary" @click="isTagCreationModalVisible = false">
					{{ $t('cancel') }}
				</button>
				<button class="modal-button btn btn-success" @click.stop="createTag">{{ $t('confirm') }}</button>
			</template>
		</modal>

		<modal v-if="allowDelete" v-model:show="isTagDeletionModalVisible" size="small" align="center">
			<template #header>{{ $t('deleteModalHeader') }}</template>
			<template #body
				><p>{{ $t('deleteModalBody', { tag: pendingDeletion && pendingDeletion.name }) }}</p></template
			>
			<template #footer>
				<button class="modal-button btn btn-outline-secondary" @click="isTagDeletionModalVisible = false">
					{{ $t('cancel') }}
				</button>
				<button class="modal-button btn btn-success" @click.stop="deleteTag">{{ $t('confirm') }}</button>
			</template>
		</modal>
	</div>
</template>

<i18n locale="ru" src="@/locales/ru/components/tag-list.json"></i18n>
<i18n locale="en" src="@/locales/en/components/tag-list.json"></i18n>

<style scoped lang="scss">
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.tag-list {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 0.25rem;

	.tag {
		display: flex;
		align-items: center;

		overflow: hidden;

		.tag-name {
			min-width: 0;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.btn-close {
			width: 0.5em;
			height: 0.5em;
		}
	}

	.tag-selector {
		position: relative;

		.tag-selector__list {
			position: absolute;
			max-height: 26.25rem;
			overflow: scroll;
			border: 1px solid $gray-500;
			left: 0;
			top: calc(100% + 0.25rem);
			z-index: 5;
			min-width: 100%;
			overflow-x: hidden;
			overflow-y: auto;

			&--short {
				max-height: 7.9rem;
			}

			&--top {
				top: unset;
				bottom: calc(100% + 0.25rem);
			}

			.list__toggle {
				flex-grow: 1;
				padding: 0;
				padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
				border-color: transparent;
				border-radius: 0;
				text-align: left;
			}
		}
	}
}

.modal-button {
	min-width: 5rem;
}
</style>

<script>
import Modal from '@/components/modals/VrModal';
import CREATE_TAG from '@/queries/components/tag-list/create-tag-mutation.graphql';
import DELETE_TAG from '@/queries/components/tag-list/delete-tag-mutation.graphql';
import { QueryError } from '@/errors';
import { toastMixin } from '@/mixins';
import { nanoid } from 'nanoid';

export default {
	props: {
		tags: {
			type: Array,
			default: () => [],
		},
		allTags: {
			type: Array,
			default: () => [],
		},
		editable: {
			type: Boolean,
			default: false,
		},
		allowCreate: {
			type: Boolean,
			default: false,
		},
		allowDelete: {
			type: Boolean,
			default: false,
		},
		short: {
			type: Boolean,
			default: false,
		},
		top: {
			type: Boolean,
			default: false,
		},
		sortTags: {
			type: [Boolean, Function],
			default: true,
		},
		inputId: {
			type: String,
			default: nanoid,
		},
	},
	mixins: [toastMixin],
	components: {
		Modal,
	},
	data() {
		return {
			searchText: '',
			showList: false,
			isTagCreationModalVisible: false,
			isTagDeletionModalVisible: false,
			pendingDeletion: null,
		};
	},
	computed: {
		filteredTags() {
			const searchText = this.searchText?.toLowerCase();
			const filtered = this.allTags.filter(function (tag) {
				if (searchText) {
					return tag.name.toLowerCase().includes(searchText);
				}
				return true;
			});
			if (this.sortTags === false) {
				return filtered;
			}
			if (typeof this.sortTags === 'function') {
				return filtered.sort((a, b) => this.sortTags(a, b));
			}
			return filtered.sort((a, b) => a.name.localeCompare(b.name, { sensitivity: 'base' }));
		},
	},
	methods: {
		removeTag(tag) {
			const tags = this.tags.filter(t => t.id !== tag.id);
			this.$emit('update:tags', tags);
		},
		toggleTag(tag) {
			if (this.tags.some(t => t.id === tag.id)) {
				this.removeTag(tag);
			} else {
				this.$emit('update:tags', [...this.tags, tag]);
			}
		},
		hideList() {
			this.showList = false;
		},
		showCreationModal() {
			if (this.allTags.some(t => t.name === this.searchText)) {
				this.showToast('errors.UniqueValueError', '', 'danger');
			} else {
				this.isTagCreationModalVisible = true;
			}
		},
		async createTag() {
			try {
				const { data } = await this.$apollo.mutate({
					mutation: CREATE_TAG,
					variables: { name: this.searchText },
				});
				if (data.createTag.success) {
					this.$emit('update:allTags', [...this.allTags, data.createTag.tag]);
					if (this.editable) {
						this.$emit('update:tags', [...this.tags, data.createTag.tag]);
					}
					this.searchText = '';
					this.showToast(this.$t('successes.create', { tag: data.createTag.tag.name }), '', 'success');
				} else {
					throw new QueryError(data.createTag.error);
				}
			} catch (err) {
				this.handleError(err);
			} finally {
				this.isTagCreationModalVisible = false;
			}
		},
		showDeleteModal(tag) {
			this.pendingDeletion = tag;
			this.isTagDeletionModalVisible = true;
		},
		async deleteTag() {
			try {
				const toDelete = this.pendingDeletion;
				const { data } = await this.$apollo.mutate({
					mutation: DELETE_TAG,
					variables: { id: this.pendingDeletion.id },
					update(cache, { data: deleteTag }) {
						cache.evict(cache.identify(toDelete));
						cache.gc();
					},
				});
				if (data.deleteTag.success) {
					this.$emit(
						'update:allTags',
						this.allTags.filter(t => t !== this.pendingDeletion)
					);
					this.showToast(this.$t('successes.delete', { tag: this.pendingDeletion.name }), '', 'success');
				} else {
					throw new QueryError(data.deleteTag.error);
				}
			} catch (err) {
				this.handleError(err);
			} finally {
				this.isTagDeletionModalVisible = false;
				this.pendingDeletion = null;
			}
		},
		handleError(err) {
			let error;
			if (err instanceof QueryError) {
				error = `errors.${err.cause.type}`;
			} else {
				error = 'errors.FallbackError';
			}
			this.showToast(error, '', 'danger');
		},
	},
	watch: {
		allTags(v) {
			const tags = this.tags.filter(function (tag) {
				return this.allTags.some(t => t.id === tag.id);
			}, this);
			this.$emit('update:tags', tags);
		},
	},
};
</script>

<i18n>
	{
		"ru": {
			"absenceTag": "В организации пока нет тегов",
			"searchTag": "Нет тегов, начинающихся на { search }"
		},
		"en": {
			"absenceTag": "No tags exist within this organisation",
			"searchTag": "There are no tags starting with { search }"
		}
	}
</i18n>
