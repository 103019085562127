<template>
	<page-section :title="$t('title')">
		<page-section-field :label="$t('fields.email')" :value="user.email" />
		<page-section-field :label="$t('fields.company')" :value="user.company?.name" />
		<page-section-field :label="$t('fields.pin')" :value="user.pin" />
	</page-section>
</template>

<i18n>
{
	"ru": {
        "title": "О ПОЛЬЗОВАТЕЛЕ",
        "fields": {
            "email": "email",
            "company": "организация",
            "pin": "пин-код"
        }
	},
	"en": {
		"title": "USER DATA",
		"fields": {
			"email": "email",
			"company": "organisation",
			"pin": "pin-code"
		}
	}
}
</i18n>

<script>
import PageSection from '@/components/page-sections/PageSection.vue';
import PageSectionField from '@/components/page-sections/PageSectionField.vue';

export default {
	props: {
		user: {
			type: Object,
			default: () => ({}),
		},
	},
	components: {
		PageSection,
		PageSectionField,
	},
};
</script>
