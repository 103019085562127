<template>
	<modal v-model:show="localShow" size="medium" align="center" footerAlign="center" class="custom-modal" staticBackdrop="true">
		<template v-slot:header> {{ $t('title') }} </template>
		<template v-slot:body>
			<p>
				{{ $t('message') }} <a :href="userAgreementUrl" target="_blank">{{ $t('link') }}</a>
			</p>
			<input type="checkbox" v-model="signature" class="vr-modal-body__checkbox" />
			<label for="checkbox">{{ $t('checkbox') }}</label>
		</template>
		<template v-slot:footer class="vr-modal-footer">
			<button class="btn btn-success" :disabled="!signature" @click="signAgreement">{{ $t('button') }}</button>
		</template>
	</modal>
</template>

<script>
import UPDATE from '@/queries/auth/mutations-sign-agreemend.graphql';
import { QueryError } from '@/errors';
import { toastMixin, modalMixin, userAgreementUrlMixin } from '@/mixins';

export default {
	mixins: [toastMixin, modalMixin, userAgreementUrlMixin],
	data() {
		return {
			signature: false,
		};
	},
	methods: {
		async signAgreement() {
			try {
				const { data } = await this.$apollo.mutate({
					mutation: UPDATE,
					variables: { data: { userAgreementAccepted: true } },
				});
				if (data.updateCompany.success) {
					this.$store.commit('auth/signAgreement');
					this.showToast('query.updateCompany.success', '', 'success');
				} else {
					throw new QueryError(data.updateCompany.error);
				}
			} catch (err) {
				let errType = 'FallbackError';
				if (err instanceof QueryError) {
					errType = err.cause.type;
				}
				this.showToast(`query.updateCompany.errors.${errType}`, '', 'danger');
			}
		},
	},
};
</script>

<style lang="scss">
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.custom-modal {
	.vr-modal-header {
		justify-content: center;

		button {
			display: none;
		}
	}

	.vr-modal-body {
		&__checkbox {
			margin-right: 0.5rem;
			gap: 1rem;
		}
	}
}

.vr-modal-footer {
	text-align: center;
	padding-bottom: 1rem;
}
</style>

<i18n>
	{
		"ru": {
			"title": "Обновление пользовательского соглашения",
			"message": "Добрый день! Мы обновили пользовательское соглашение и правила пользования приложением, и для продолжения работы с лабораторией просим подтвердить ваше согласие с ними. Полный текст пользовательского соглашения можно найти ",
			"link": "по этой ссылке",
			"checkbox": "Я ознакомился и согласен с пользовательским соглашением",
			"button": "Продолжить работу",
			"query": {
				"updateCompany": {
					"success": "Вы успешно подписали пользовательское соглашение",
					"errors": {
						"FallbackError": "Ошибка соединения. Попробуйте позже."
					}
				}
			}

		},
		"en": {
			"title": "User agreement",
			"message": "Hello, we will update your user agreement soon. In the meantime, you can continue working",
			"link": "",
			"checkbox": "I want to continue working",
			"button": "Ok",
			"query": {
				"updateCompany": {
					"sucsess": "",
					"error": {
						"FallbackError": ""
					}
				}
			}
		}
	}
</i18n>
