<template>
    <div class="board-table-container">
        <table class="table board-table">
            <tbody>
                <tr v-for="(row, ridx) in board" :key="`board-row-${ridx}`">
                    <td :class="{ 'cell-border': cell.access == accessChoices.readOnly || cell.access == accessChoices.lockedForCopy }"
                        :colspan="cell.length" v-for="(cell, cidx) in row" :key="`board-cell-${cidx}`">{{
                            cell.content ?? '&ndash;'}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            accessChoices: { full: "Full", readOnly: "ReadOnly", lockedForCopy: "LockedForCopy" },
        }
    },
    props: {
        boardData: {
            type: [String, Object],
            default: ""
        }
    },
    computed: {
        board() {
            let data = JSON.parse(this.boardData);
            let board = Array(data?.RowCount).fill(null).map(() => new Array(data?.ColumnCount).fill({}));
            data?.Entries.forEach(function(entry){
                board[entry.Row][entry.Column] = { 'content': entry?.Content, 
                                                'access': entry?.Access ?? this.accessChoices.full, 
                                                'length': entry?.Length ?? 1 };
            })
            return board;
        }
    }
};
</script>

<style scoped lang="scss">
.board-table-container {
    .board-table {
        tr {
            td {
                text-align: center;
            }

            .cell-border {
                border: 2px solid black;
            }
        }
    }
}
</style>