<template>
	<div v-if="show" :class="['loader', { 'loader--background': loaderBackground }]">
		<div class="flask loader-part"></div>
		<span class="loader-part">{{ $t('loading') }}</span>
	</div>
</template>

<i18n>
{
    "ru": {
        "loading": "Загрузка..."
    },
    "en": {
        "loading": "Loading..."
    }
}
</i18n>

<script>
export default {
	props: {
		show: {
			type: Boolean,
			default: true,
		},
		loaderBackground: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style lang="scss" scoped>
.flask {
	width: 4rem;
	height: 4rem;
	display: block;
	border: 0.125rem solid #dadcdf;
	border-radius: 4rem;
	background-color: #fff;
	background-image: linear-gradient(#0d6efd 6.25rem, transparent 0);
	background-position: 0 1rem;
	background-repeat: no-repeat;
}
.flask::after {
	content: '';
	box-sizing: border-box;
	position: absolute;
	left: 50%;
	top: 0%;
	transform: translate(-50%, -95%);
	border: 0.125rem solid #dadcdf;
	border-bottom: none;
	background: #fff;
	width: 1rem;
	height: 2rem;
}
.flask::before {
	content: '';
	box-sizing: border-box;
	left: 50%;
	transform: translateX(-125%);
	bottom: -0.25rem;
	border-radius: 50%;
	position: absolute;
	width: 0.3rem;
	height: 0.3rem;
	z-index: 10;
	animation: bubbles 6s linear infinite;
}
@keyframes bubbles {
	0% {
		box-shadow: 0.25rem -0.6rem #6dcbe2, 0.3rem 0 #6dcbe2, 0.5rem -1rem #4b8c9c, 0.75rem 0 #6dcbe2;
	}
	20% {
		box-shadow: 0.25rem -1.25rem #5893eb, 0.5rem -0.6rem #5893eb, 0.6rem -2rem #3f76c9, 1rem -0.3rem #5893eb;
	}
	40% {
		box-shadow: 0.125rem -2.25rem #ffffff80, 0.5rem -2rem #0474dd66, 0.5rem -4rem #ffffff80, 0.75rem -1rem #ffffff80;
	}
	60% {
		box-shadow: 0.25rem -4rem #ffffff80, 0.3rem -3rem #221c5c66, 0.6rem -5.625 #29879480, 1rem -1.5rem #5f66c280;
	}
	80% {
		box-shadow: 0.125rem -5rem #1517a380, 0.25rem -4.375rem #1517a380, 0.5rem -7.5rem #1517a380,
			0.75rem -2rem #1517a380;
	}
	100% {
		box-shadow: 0.25rem -6.25rem #03185200, 0.5rem -5.625rem #03185200, 0.6rem -7.5rem #03185200,
			1rem -3rem #03185200;
	}
}

.loader {
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 99;
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&-part {
		position: relative;
	}

	&--background {
		background: rgba(0, 0, 0, 0.6);
	}
}
</style>
