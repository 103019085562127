import { createRouter, createWebHistory } from 'vue-router';
import Licences from '@/views/Licences.vue';
import Devices from '@/views/Devices.vue';
import UserSettings from '@/views/UserSettings.vue';
import Login from '@/views/Login.vue';
import ForgotPassword from '@/views/ForgotPassword.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import store from '@/store';
import AssignmentEditor from '@/views/AssignmentEditor';
import AssignmentUsers from '@/views/AssignmentUsers';
import Tasks from '@/views/Tasks.vue';
import AssignmentList from '@/views/AssignmentList';
import UserList from '@/views/Users';
import AssignmentTemplates from '@/views/AssignmentTemplates';
import RoleList from '@/views/Roles';
import UserAssignments from '@/views/UserAssignments';
import About from '@/views/About';
import CompanySettings from '@/views/CompanySettings';
import TalentLinkConfirm from '@/views/TalentLinkConfirm';

import Navbar from '@/components/Navbar';
import Sidebar from '@/components/Sidebar/Sidebar';
import Footbar from '@/components/Footbar';

import Forbidden403 from '@/views/Forbidden403';
import NotFound404 from '@/views/NotFound404';

const defaults = { navbar: Navbar, sidebar: Sidebar, footbar: Footbar };

const routes = [
	{
		path: '/login',
		name: 'Login',
		components: { content: Login },
	},
	{
		path: '/forgot-password',
		name: 'ForgotPassword',
		components: { content: ForgotPassword },
	},
	{
		path: '/reset-password/:uid/:token',
		name: 'ResetPassword',
		components: { content: ResetPassword },
	},
	{
		path: '/user/assignments',
		name: 'UserAssignments',
		components: { ...defaults, content: UserAssignments },
	},
	{
		path: '/user/settings',
		name: 'UserSettings',
		components: { ...defaults, content: UserSettings },
	},
	{
		path: '/user/talent-link-confirm',
		name: 'TalentLinkConfirm',
		components: { content: TalentLinkConfirm },
		props: { content: route => ({ code: route.query.code }) },
	},
	{
		path: '/company/assignments',
		name: 'Assignments',
		components: { ...defaults, content: AssignmentList },
		meta: { requiredPermissions: ['view_assignmentforuser'] },
	},
	{
		path: '/company/assignment/:id?',
		name: 'Assignment',
		components: { ...defaults, content: AssignmentEditor },
		props: { content: true },
		meta: { requiredPermissions: ['add_assignment', 'change_assignment', 'delete_assignment'] },
	},
	{
		path: '/company/assign-users/:groupId?',
		name: 'AssignmentUsers',
		components: { ...defaults, content: AssignmentUsers },
		props: {
			content: route => ({
				groupId: route.params.groupId,
				assignmentId: route.query.assignmentId,
			}),
		},
		meta: { requiredPermissions: ['add_assignmentforuser'] },
	},
	{
		path: '/company/assignment-templates',
		name: 'AssignmentTemplates',
		components: { ...defaults, content: AssignmentTemplates },
		meta: { requiredPermissions: ['view_assignment'] },
	},
	{
		path: '/company/tasks',
		name: 'CompanyTasks',
		components: { ...defaults, content: Tasks },
		meta: { requiredPermissions: ['view_task'] },
	},
	{
		path: '/company/users',
		name: 'Users',
		components: { ...defaults, content: UserList },
		meta: { requiredPermissions: ['view_user'] },
	},
	{
		path: '/company/roles',
		name: 'Roles',
		components: { ...defaults, content: RoleList },
		meta: { requiredPermissions: ['view_role'] },
	},
	{
		path: '/company/devices',
		name: 'CompanyDevices',
		components: { ...defaults, content: Devices },
		meta: { requiredPermissions: ['view_device'] },
	},
	{
		path: '/company/licences',
		name: 'CompanyLicences',
		components: { ...defaults, content: Licences },
		meta: { requiredPermissions: ['view_licence'] },
	},
	{
		path: '/company/settings',
		name: 'CompanySettings',
		components: { ...defaults, content: CompanySettings },
		meta: { requiredPermissions: ['change_company'] },
	},
	{
		path: '/info/about',
		name: 'About',
		components: { ...defaults, content: About },
	},
	{
		path: '/forbidden-403',
		name: 'Forbidden403',
		components: { ...defaults, content: Forbidden403 },
	},
	{
		path: '/not-found-404',
		name: 'NotFound404',
		components: { ...defaults, content: NotFound404 },
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

router.beforeEach(async function (to, from) {
	let redirect = false;
	const notAuthPages = ['Login', 'ForgotPassword', 'ResetPassword'];

	if (!store.getters['auth/isLoggedIn'] && !notAuthPages.find(el => el === to.name)) {
		try {
			await store.dispatch('auth/me');
			redirect = !store.getters['auth/isLoggedIn'];
		} catch {
			redirect = true;
		}
	}
	if (redirect) return { name: 'Login' };

	if (store.getters['auth/isLoggedIn'] && to.path === '/') {
		return { name: 'About' };
	}

	if (to.meta?.requiredPermissions) {
		const redirect = !to.meta.requiredPermissions.some(perm => store.state.auth.user.permissions.includes(perm));
		if (redirect) return { name: 'Forbidden403' };
	}

	if (to.name === undefined) return { name: 'NotFound404' };
});

export default router;
