export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список устройств"])},
        "noBindedDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет прикрепленных устройств"])}
      },
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device list"])},
        "noBindedDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No devices"])}
      }
    }
  })
}
