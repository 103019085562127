<template>
	<div v-if="list.length !== 0" class="vr-modal-section-list">
		<h6 v-if="title" class="vr-modal-section-list__title">
			{{ title.toUpperCase() }}
		</h6>
		<ul class="list-group list-group-numbered">
			<li
				v-for="(item, idx) in list"
				:key="`vr-modal-section-${idx}`"
				class="list-group-item list-group-item-light"
			>
				{{ item }}
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: '',
			required: false,
		},
		list: {
			type: Array,
			default: () => [],
			required: true,
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.vr-modal-section-list {
	&__title {
		padding: 0.75rem;
		border-radius: 0.5rem;
		background-color: $green-400;
		color: $light;
	}
}
</style>
