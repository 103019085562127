<template>
	<div class="page-content d-flex">
		<div class="roles-list">
			<div class="roles-filter mb-3">
				<div class="input-group">
					<span class="input-group-text">
						<fa-icon icon="fa fa-filter" />
					</span>
					<input type="text" class="form-control" :placeholder="$t('filter')" v-model="roleFilter" />
				</div>
			</div>
			<div class="list-group">
				<button
					class="list-group-item list-group-item-action"
					v-for="role in displayedRoles"
					:key="role.id"
					:class="{ active: selectedRoleId === role.id }"
					@click="selectRole(role)"
				>
					<span>{{ role.name }}</span>
					<fa-icon v-if="defaultRoleId === role.id" icon="fas fa-star" :title="$t('defaultRole')" />
				</button>
				<button
					v-if="canAddRole && !hasNewRole"
					class="list-group-item list-group-item-action list-group-item-success"
					@click="addRole"
				>
					{{ $t('createRole') }}
				</button>
			</div>
		</div>
		<div class="roles-data" v-show="!!selectedRoleId">
			<div class="controls">
				<text-input
					:placeholder="$t('roleName')"
					v-model:value="roleName"
					:disabled="!canChangeRole"
					:error="v.roleName.$error"
					:errorMsg="v.roleName.$error ? $t(`errors.${v.roleName.$errors[0]?.$message}`) : ''"
				/>
				<button v-if="canDeleteRole" class="btn btn-danger" @click.stop="isConfirmModalVisible = true">
					{{ $t('deleteRole') }}
				</button>
				<button
					v-if="canSetDefaultRole"
					:disabled="defaultRoleId === selectedRoleId"
					class="btn btn-info"
					@click="setDefaultRole"
				>
					{{ $t('setDefaultRole') }}
				</button>
				<button v-if="canAddRole || canChangeRole" class="btn btn-success" @click="saveRole">
					{{ $t('saveRole') }}
				</button>
			</div>

			<div class="role-permissions">
				<div class="card" v-for="group in permissionGroups" :key="group.name">
					<div class="card-header">
						{{ $t(group.name) }}
					</div>
					<ul class="list-group list-group-flush">
						<li class="list-group-item" v-for="permission in group.permissions" :key="permission">
							<input
								class="form-check-input me-1"
								type="checkbox"
								:value="permission"
								v-model="rolePermissions"
								:id="permission"
								:disabled="!canChangeRole"
							/>
							<label class="form-check-label" :for="permission">{{ $t(permission) }}</label>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<confirm-modal v-model:show="isConfirmModalVisible" confirm-button-style="danger" @confirm="deleteRole">
			<template #header>{{ $t('confirmDeleteHeader') }}</template>
			<template #body>{{ $t('confirmDeleteBody') }}</template>
		</confirm-modal>
	</div>
</template>

<style scoped lang="scss">
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
.roles-list {
	max-width: 25%;
	padding: 0.5rem;

	border-radius: $border-radius;
	border: $border-width solid $border-color;

	overflow: auto;

	.list-group-item {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.roles-data {
	flex-grow: 1;
	padding: 0.5rem;
	margin-left: 1rem;

	border-radius: $border-radius;
	border: $border-width solid $border-color;

	display: flex;
	flex-direction: column;
	row-gap: 1rem;

	.controls {
		display: flex;
		align-items: center;
		gap: 1rem;

		button:first-of-type {
			margin-left: auto;
		}
	}

	.role-permissions {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
		gap: 0.5rem;

		min-height: 0;

		overflow: auto;

		.list-group-item {
			white-space: nowrap;

			.form-check-label {
				white-space: initial;
			}
		}
	}
}
</style>

<i18n locale="ru" src="@/locales/ru/common/permissions.json" />
<i18n locale="en" src="@/locales/en/common/permissions.json" />

<i18n locale="ru" src="@/locales/ru/views/roles.json"></i18n>
<i18n locale="en" src="@/locales/en/views/roles.json"></i18n>

<script>
import useVuelidate from '@vuelidate/core';
import { required, maxLength, not, helpers } from '@vuelidate/validators';
import { PERMISSION_GROUPS } from '@/utils/permissions';
import { rolePermissionsMixin, companyPermissionsMixin, toastMixin } from '@/mixins';
import { QueryError } from '@/errors';
import QUERY from '@/queries/views/roles/query.graphql';
import CREATE from '@/queries/views/roles/mutation-create.graphql';
import UPDATE from '@/queries/views/roles/mutation-update.graphql';
import DELETE from '@/queries/views/roles/mutation-delete.graphql';
import SET_DEFAULT from '@/queries/views/roles/mutation-set-default.graphql';

import TextInput from '@/components/inputs/TextInput';
import ConfirmModal from '@/components/modals/ModalConfirm';

function nameNotUsed(value, { roles, selectedRoleId }) {
	const names = roles.filter(r => r.id !== selectedRoleId).map(r => r.name);
	return !names.includes(value);
}

export default {
	data() {
		return {
			defaultRoleId: null,
			roles: [],
			selectedRoleId: null,
			roleFilter: null,
			roleName: null,
			rolePermissions: [],
			isConfirmModalVisible: false,
		};
	},
	validations() {
		return {
			roleName: {
				maxLength: helpers.withMessage('NameTooLongError', maxLength(100)),
				required: helpers.withMessage('EmptyValueError', required),
				unique: helpers.withMessage('UniqueValueError', nameNotUsed),
			},
		};
	},
	mixins: [rolePermissionsMixin, companyPermissionsMixin, toastMixin],
	components: {
		TextInput,
		ConfirmModal,
	},
	computed: {
		displayedRoles() {
			return this.roles.filter(r => r.name.toLowerCase().startsWith(this.roleFilter?.toLowerCase() ?? ''));
		},
		permissionGroups() {
			return PERMISSION_GROUPS;
		},
		isNew() {
			return this.selectedRoleId === -1;
		},
		hasNewRole() {
			return this.roles.some(r => r.id === -1);
		},
		canSetDefaultRole() {
			return !this.isNew && this.canChangeCompany;
		},
	},
	methods: {
		selectRole(role) {
			this.selectedRoleId = role.id;
			this.roleName = role.name;
			this.rolePermissions = role.permissions;
		},
		addRole() {
			const role = { id: -1, name: this.$t('defaultRoleName'), permissions: [] };
			this.roles.push(role);
			this.selectRole(role);
		},
		async saveRole() {
			this.v.$touch();
			if (this.v.$error) {
				return;
			}
			try {
				const { data } = await this.$apollo.mutate({
					mutation: this.isNew ? CREATE : UPDATE,
					variables: {
						id: this.isNew ? undefined : this.selectedRoleId,
						data: { name: this.roleName, permissions: this.rolePermissions },
					},
				});
				if (data.mutation.success) {
					if (this.isNew) {
						const role = this.roles.find(r => r.id === -1);
						role.id = data.mutation.role.id;
						role.name = data.mutation.role.name;
						role.permissions = data.mutation.role.permissions;
					}
					this.showToast('saveSuccess', 'success', 'success');
					this.v.$reset();
				} else {
					throw new QueryError(data.mutation.error);
				}
			} catch (err) {
				if (err instanceof QueryError) {
					this.handleError(err.cause);
				} else {
					this.handleError({ type: 'FallbackError' });
				}
			}
		},
		async deleteRole() {
			if (this.isNew) {
				this.roles = this.roles.filter(r => r.id !== -1);
				this.selectedRoleId = null;
				return;
			}
			try {
				const { data } = await this.$apollo.mutate({
					mutation: DELETE,
					variables: { id: this.selectedRoleId },
				});
				if (data.deleteRole.success) {
					this.roles = this.roles.filter(r => r.id !== this.selectedRoleId);
					this.selectedRoleId = null;
					this.showToast('deleteSuccess', 'success', 'success');
				} else {
					throw new QueryError(data.deleteRole.error);
				}
			} catch (err) {
				if (err instanceof QueryError) {
					this.handleError(err.cause);
				} else {
					this.handleError({ type: 'FallbackError' });
				}
			}
		},
		async setDefaultRole() {
			try {
				const { data } = await this.$apollo.mutate({
					mutation: SET_DEFAULT,
					variables: { data: { defaultRole: this.selectedRoleId } },
				});
				if (data.updateCompany.success) {
					this.defaultRoleId = data.updateCompany.company.defaultRole.id;
					this.showToast('setDefaultSuccess', 'success', 'success');
				} else {
					throw new QueryError(data.updateCompany.error);
				}
			} catch (err) {
				if (err instanceof QueryError) {
					this.handleError(err.cause);
				} else {
					this.handleError({ type: 'FallbackError' });
				}
			}
		},
		handleError({ type, entity }) {
			const msg = `errors.${type}` + (type === 'NotFoundError' ? `.${entity}` : '');
			this.showToast(msg, 'error', 'danger');
		},
	},
	apollo: {
		roles: {
			manual: true,
			fetchPolicy: 'cache-and-network',
			query: QUERY,
			result({ loading, data }) {
				if (!loading) {
					this.roles = data.myCompany.roles.slice(0);
					this.defaultRoleId = data.myCompany.defaultRole.id;
				}
			},
		},
	},
	setup() {
		return { v: useVuelidate() };
	},
};
</script>
