export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device information"])},
      "status": {
        "isValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device is active"])},
        "isInvalid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Device is inactive (", _interpolate(_named("licenceStatus")), ")"])}
      },
      "licenceStatus": {
        "notActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licence is inactive"])},
        "isValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licence is active"])},
        "isPending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licence is pending"])},
        "isExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licence is expired"])},
        "isBlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licence suspended"])}
      },
      "sections": {
        "common": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["common data"])},
          "fields": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["device name"])},
            "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["model"])}
          }
        },
        "licence": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licence"])},
          "fields": {
            "startedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["valid since"])},
            "endedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["valid until"])},
            "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licence key"])}
          }
        }
      },
      "submitBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
      "cancelBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "validation": {
        "name": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter device name."])},
          "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Device name length must not exceed ", _interpolate(_named("nameLength")), " characters."])}
        }
      },
      "query": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device update successful."])},
        "errros": {
          "NotFoundError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device not found."])},
          "FallbackError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occured. Please try again later."])}
        }
      }
    }
  })
}
