<template>
	<date-picker
		v-model="localDate"
		:locale="$i18n.locale"
		:format="format"
		:select-text="$t('select')"
		:cancel-text="$t('cancel')"
		:now-button-label="$t('now')"
		v-bind="$attrs"
	/>
</template>

<style scoped lang="scss">
.dp__main {
	width: auto;
}
</style>

<i18n>
	{
		"ru": {
			"select": "Выбрать",
			"cancel": "Отменить",
			"now": "Сейчас"
		},
		"en": {
			"select": "Select",
			"cancel": "Cancel",
			"now": "Now"
		}
	}
</i18n>

<script>
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export default {
	props: {
		date: {
			type: String,
			default: () => new Date().toISOString(),
		},
	},
	computed: {
		localDate: {
			get() {
				return this.date ? dayjs(this.date).format('YYYY-MM-DDTHH:mm') : null;
			},
			set(v) {
				const date = v ? dayjs(v).utc().toISOString() : null;
				this.$emit('update:date', date);
			},
		},
		format() {
			if (this.$i18n.locale === 'ru') {
				return 'dd.MM.yyyy HH:mm';
			}
			if (this.$i18n.locale === 'en') {
				return 'MM/dd/yyyy HH:mm';
			}
			return 'dd.MM.yyyy HH:mm';
		},
	},
};
</script>
