<template>
	<vr-modal v-model:show="modalShow" @close="resetForm" size="medium" align="center" :footer-line="false">
		<template v-slot:header> {{ $t('title') }} </template>
		<template v-slot:body>
			<div v-if="!!warningMsg" class="alert alert-warning" role="alert">
				<fa-icon icon="fa-solid fa-triangle-exclamation" class="icon-false" />
				<h6>
					{{ warningMsg }}
				</h6>
			</div>
			<form class="update-task-form" @submit.prevent="updateTask">
				<page-section :title="$t(`sections.common.title`)">
					<div class="form-group">
						<label>{{ $t('sections.common.fields.tags') }}</label>
						<tag-list
							v-if="modalShow"
							v-model:tags="taskData.tags"
							v-model:all-tags="localTags"
							:allow-create="canAddTag"
							:allow-delete="canDeleteTag"
							:editable="canChangeTask(task.author?.id)"
						/>
					</div>
					<text-input
						v-model:value="taskData.name"
						inputId="taskName"
						:placeholder="$t('sections.common.fields.name')"
						:error="v$.taskData.name.$error"
						:errorMsg="v$.taskData.name.$errors[0]?.$message"
						:disabled="!canUpdateFields"
						required
					/>
					<text-area-input
						v-model:value="taskData.annotation"
						inputId="taskAnnotation"
						:placeholder="$t('sections.common.fields.annotation')"
						:disabled="!canUpdateFields"
					/>
					<text-input
						v-model:value="taskData.passingGrade"
						inputId="taskPassingGrade"
						:placeholder="$t('sections.common.fields.passingGrade')"
						:error="v$.taskData.passingGrade.$error"
						:errorMsg="v$.taskData.passingGrade.$errors[0]?.$message"
						:disabled="!canUpdateFields"
					/>
					<page-section-field :label="$t(`sections.common.fields.author`)" :value="task.author?.email" />
					<page-section-field
						:label="$t(`sections.common.fields.createdAt`)"
						:value="formatDate(task.createdAt)"
					/>
				</page-section>
				<page-section :title="$t(`sections.status.title`)">
					<div class="status-control">
						<button
							type="button"
							class="btn"
							:class="{
								'btn-outline-secondary': !isDraft,
								'btn-success': isDraft,
							}"
							:disabled="!canDraft"
							@click="setStatus('DRAFT')"
						>
							<span>{{ $t('sections.status.fields.draft') }}</span>
							<popper class="popper-tooltip" hover arrow placement="top">
								<fa-icon icon="fa-regular fa-circle-question" />
								<template #content>
									<p>{{ $t('tips.draft.one') }}</p>
									<p v-if="!$store.getters['auth/isSuperOrStaff']">
										<strong>{{ $t('tips.draft.two') }}</strong>
									</p>
									<p v-else>
										<strong>{{ $t('tips.draft.three') }}</strong>
									</p>
								</template>
							</popper>
						</button>
						<fa-icon icon="fas fa-arrows-left-right" size="2x" />
						<button
							type="button"
							class="btn"
							:class="{
								'btn-outline-secondary': !isPublished,
								'btn-success': isPublished,
							}"
							:disabled="!canPublish"
							@click="setStatus('PUBLISHED')"
						>
							<span>{{ $t('sections.status.fields.published') }}</span>
							<popper class="popper-tooltip" hover arrow placement="top">
								<fa-icon icon="fa-regular fa-circle-question" />
								<template #content>
									<p>{{ $t('tips.publication.one') }}</p>
									<p>
										<strong>{{ $t('tips.publication.two') }} </strong>
									</p>
								</template>
							</popper>
						</button>
						<fa-icon icon="fas fa-arrows-left-right" size="2x" />
						<button
							type="button"
							class="btn"
							:class="{
								'btn-outline-secondary': !isArchived,
								'btn-success': isArchived,
							}"
							:disabled="!canArchive"
							@click="setStatus('ARCHIVED')"
						>
							<span>{{ $t('sections.status.fields.archived') }}</span>
							<popper class="popper-tooltip" hover arrow placement="top">
								<fa-icon icon="fa-regular fa-circle-question" />
								<template #content>
									<p>{{ $t('tips.archived') }}</p>
								</template>
							</popper>
						</button>
					</div>
				</page-section>
			</form>
		</template>
		<template v-slot:footer>
			<button @click="closeModal" class="btn btn-secondary">{{ $t('cancelBtn') }}</button>
			<button
				@click="updateTask"
				type="submit"
				class="btn btn-success"
				:disabled="(!canManageTasks && !(isSuperuser || isStaff))"
			>
				{{ $t('submitBtn') }}
			</button>
		</template>
	</vr-modal>
</template>

<i18n locale="ru" src="@/locales/ru/components/modals/modal-task-data.json"></i18n>
<i18n locale="en" src="@/locales/en/components/modals/modal-task-data.json"></i18n>

<script>
import { QueryError } from '@/errors';
import useVuelidate from '@vuelidate/core';
import { required, maxLength, helpers } from '@vuelidate/validators';
import VrModal from '@/components/modals/VrModal.vue';
import PageSection from '@/components/page-sections/PageSection.vue';
import PageSectionField from '@/components/page-sections/PageSectionField.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import TextAreaInput from '@/components/inputs/TextAreaInput.vue';
import TagList from '@/components/TagList';
import taskPermissionsMixin from '@/mixins/permissions/taskPermissions.js';
import { dateTimeMixin, toastMixin, tagPermissionsMixin } from '@/mixins';
import UPDATETASK from '@/queries/views/tasks/mutation-update-task.graphql';
import { mapState } from 'vuex';

export default {
	setup() {
		return { v$: useVuelidate() };
	},
	emits: ['update:show', 'update:tags'],
	mixins: [dateTimeMixin, toastMixin, taskPermissionsMixin, tagPermissionsMixin],
	data() {
		return {
			taskData: {
				name: null,
				annotation: null,
				passingGrade: null,
				status: null,
				tags: [],
			},
			maxNameLength: 55,
			maxGradeVal: 999,
		};
	},
	methods: {
		setStatus(status) {
			this.taskData.status = status;
		},
		resetTaskData() {
			for (let key in this.taskData) {
				this.taskData[key] = this.task[key];
			}
		},
		resetForm() {
			this.v$.$reset();
			this.resetTaskData();
		},
		closeModal() {
			this.modalShow = false;
			this.resetForm();
		},
		async updateTask() {
			this.v$.$touch();
			let success = true;
			if (this.v$.$error) {
				return;
			}
			if (this.taskDataChanged) {
				try {
					this.taskData.passingGrade = !this.taskData.passingGrade ? 0 : +this.taskData.passingGrade;
					const { data } = await this.$apollo.mutate({
						mutation: UPDATETASK,
						variables: {
							id: this.task.id,
							data: { ...this.changedData, tags: this.taskData.tags.map(tag => tag.id) },
						},
					});
					if (data.updateTask.success) {
						this.showToast('query.success', '', 'success');
					} else {
						throw new QueryError(data.updateTask.error);
					}
				} catch (err) {
					success = false;
					let errMsg;
					if (err instanceof QueryError) {
						errMsg = `${err.cause.type}`;
					} else {
						errMsg = 'FallbackError';
					}
					this.handleError(errMsg);
				}
			}
			if (success) this.closeModal();
		},
		handleError(msg) {
			this.showToast(`query.errors.${msg}`, '', 'danger');
		},
	},
	computed: {
		modalShow: {
			get() {
				return this.show;
			},
			set(show) {
				this.$emit('update:show', show);
			},
		},
		localTags: {
			get() {
				return this.tags;
			},
			set(tags) {
				this.$emit('update:tags', tags);
			},
		},
		isDraft() {
			return this.taskData.status === 'DRAFT';
		},
		isPublished() {
			return this.taskData.status === 'PUBLISHED';
		},
		isArchived() {
			return this.taskData.status === 'ARCHIVED';
		},
		warningMsg() {
			if (this.task.availableFrom === 'LICENCE' && !(this.isSuperuser || this.isStaff))
				return this.$t('warnings.taskFromLicence');
			return null;
		},
		taskDataChanged() {
			if (this.task.id === null) {
				return false;
			}
			for (let key in this.taskData) {
				if (this.taskData[key] !== this.task[key]) {
					return true;
				}
			}
			return false;
		},
		changedData() {
			let changedData = {};
			for (let key in this.taskData) {
				if (this.taskData[key] !== this.task[key]) {
					changedData[key] = this.taskData[key];
				}
			}
			return changedData;
		},
		canMakeTaskUpdate() {
			return (this.canManageTasks && this.task.availableFrom === 'COMPANY') || this.isSuperuser || this.isStaff;
		},
		canUpdateFields() {
			if (!this.canMakeTaskUpdate) {
				return false;
			}
			if (this.task.id === null || !this.task.isDraft) {
				return false;
			}
			if (this.canChangeTask(this.task.author?.id)) {
				return true;
			}
			return false;
		},
		canDraft() {
			if (!this.canMakeTaskUpdate) {
				return false;
			}
			if (!this.task.isUnused || this.task.isArchived) {
				return false;
			}
			if (this.isDraft) {
				return false;
			}
			return this.canPublishTask(this.task.author?.id);
		},
		canPublish() {
			if (!this.canMakeTaskUpdate) {
				return false;
			}
			if (this.task.id === null) {
				return false;
			}
			if (this.isPublished) {
				return false;
			}
			return this.canPublishTask(this.task.author?.id);
		},
		canArchive() {
			if (!this.canMakeTaskUpdate) {
				return false;
			}
			if (this.task.id === null || this.task.isDraft) {
				return false;
			}
			if (this.isArchived) {
				return false;
			}
			return this.canArchiveTask(this.task.author?.id);
		},
		...mapState('auth', {
			canManageTasks: state => state.company.canManageTasks,
			isSuperuser: state => state.user.isSuperuser,
			isStaff: state => state.user.isStaff,
		}),
	},
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		task: {
			type: Object,
			default: () => ({ id: null }),
		},
		tags: {
			type: Array,
			default: () => [],
		},
	},
	components: {
		VrModal,
		PageSection,
		PageSectionField,
		TextInput,
		TextAreaInput,
		TagList,
	},
	watch: {
		task() {
			this.resetTaskData();
		},
	},
	validations() {
		return {
			taskData: {
				name: {
					required: helpers.withMessage(this.$t('validation.name.required'), required),
					maxLength: helpers.withMessage(
						this.$t('validation.name.maxLength', { nameLength: this.maxNameLength }),
						maxLength(this.maxNameLength)
					),
				},
				passingGrade: {
					format: helpers.withMessage(
						this.$t('validation.passingGrade.format', { gradeVal: this.maxGradeVal }),
						() =>
							+this.taskData.passingGrade >= 0 &&
							+this.taskData.passingGrade <= this.maxGradeVal &&
							Number.isInteger(+this.taskData.passingGrade)
					),
				},
			},
		};
	},
};
</script>

<style lang="scss" scoped>

.update-task-form {
	&__group {
		position: relative;
	}

	& > * + * {
		margin-top: 1rem;
	}
}

.status-control {
	display: flex;
	align-items: center;
	gap: 0.25rem;

	& > button {
		opacity: 1;

		& > span {
			text-transform: capitalize;
			margin-right: 0.5rem;
		}

		& > .popper-tooltip {
			pointer-events: auto;
		}
	}
}

.form-switch {
	label {
		margin-right: 1rem;
	}
}

::v-deep(.popper) {
	max-width: 40rem;
}
</style>
