export const VIEW_USER = 'view_user';
export const ADD_USER = 'add_user';
export const CHANGE_USER = 'change_user';
export const CHANGE_USER_ROLES = 'change_user_roles';
export const DELETE_USER = 'delete_user';

export const ADD_ACTIVATION_CODE = 'add_activationcode';

export const VIEW_LICENCE = 'view_licence';
export const ACTIVATE_LICENCE = 'activate_licence';

export const VIEW_DEVICE = 'view_device';
export const ADD_DEVICE = 'add_device';
export const CHANGE_DEVICE = 'change_device';
export const DELETE_DEVICE = 'delete_device';

export const VIEW_TASK = 'view_task';
export const ADD_TASK = 'add_task';
export const CHANGE_TASK = 'change_task';
export const PUBLISH_TASK = 'publish_task';
export const ARCHIVE_TASK = 'archive_task';
export const DELETE_TASK = 'delete_task';

export const VIEW_ASSIGNMENT = 'view_assignment';
export const ADD_ASSIGNMENT = 'add_assignment';
export const CHANGE_ASSIGNMENT = 'change_assignment';
export const DELETE_ASSIGNMENT = 'delete_assignment';

export const VIEW_ASSIGNMENT_FOR_USER = 'view_assignmentforuser';
export const ADD_ASSIGNMENT_FOR_USER = 'add_assignmentforuser';
export const CHANGE_ASSIGNMENT_FOR_USER = 'change_assignmentforuser';
export const DELETE_ASSIGNMENT_FOR_USER = 'delete_assignmentforuser';

export const VIEW_ROLE = 'view_role';
export const ADD_ROLE = 'add_role';
export const CHANGE_ROLE = 'change_role';
export const DELETE_ROLE = 'delete_role';

export const CHANGE_COMPANY = 'change_company';

export const VIEW_TAG = 'view_tag';
export const ADD_TAG = 'add_tag';
export const CHANGE_TAG = 'change_tag';
export const DELETE_TAG = 'delete_tag';

export const PERMISSION_GROUPS = [
    { name: 'User', permissions: [VIEW_USER, ADD_USER, CHANGE_USER, CHANGE_USER_ROLES, DELETE_USER] },
    { name: 'ActivationCode', permissions: [ADD_ACTIVATION_CODE] },
    { name: 'Licence', permissions: [VIEW_LICENCE, ACTIVATE_LICENCE] },
    { name: 'Device', permissions: [VIEW_DEVICE, ADD_DEVICE, CHANGE_DEVICE, DELETE_DEVICE] },
    { name: 'Task', permissions: [ADD_TASK, CHANGE_TASK, PUBLISH_TASK, ARCHIVE_TASK, DELETE_TASK] },
    { name: 'Template', permissions: [VIEW_ASSIGNMENT, ADD_ASSIGNMENT, CHANGE_ASSIGNMENT, DELETE_ASSIGNMENT] },
    {
        name: 'Assignment',
        permissions: [
            VIEW_ASSIGNMENT_FOR_USER,
            ADD_ASSIGNMENT_FOR_USER,
            CHANGE_ASSIGNMENT_FOR_USER,
            DELETE_ASSIGNMENT_FOR_USER,
        ],
    },
    { name: 'Role', permissions: [VIEW_ROLE, ADD_ROLE, CHANGE_ROLE, DELETE_ROLE] },
    { name: 'Company', permissions: [CHANGE_COMPANY] },
    { name: 'Tag', permissions: [VIEW_TAG, ADD_TAG, CHANGE_TAG, DELETE_TAG] },
];

export default {
    VIEW_USER,
    ADD_USER,
    CHANGE_USER,
    CHANGE_USER_ROLES,
    DELETE_USER,

    VIEW_LICENCE,
    ACTIVATE_LICENCE,

    VIEW_DEVICE,
    ADD_DEVICE,
    CHANGE_DEVICE,
    DELETE_DEVICE,

    VIEW_TASK,
    ADD_TASK,
    CHANGE_TASK,
    PUBLISH_TASK,
    ARCHIVE_TASK,
    DELETE_TASK,

    VIEW_ASSIGNMENT,
    ADD_ASSIGNMENT,
    CHANGE_ASSIGNMENT,
    DELETE_ASSIGNMENT,

    VIEW_ASSIGNMENT_FOR_USER,
    ADD_ASSIGNMENT_FOR_USER,
    CHANGE_ASSIGNMENT_FOR_USER,
    DELETE_ASSIGNMENT_FOR_USER,

    VIEW_ROLE,
    ADD_ROLE,
    CHANGE_ROLE,
    DELETE_ROLE,

    CHANGE_COMPANY,

    VIEW_TAG,
    ADD_TAG,
    CHANGE_TAG,
    DELETE_TAG,
};
