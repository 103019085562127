<template>
	<div class="vr-sidebar__body__section">
		<div class="vr-sidebar__body__section__header">
			<span class="vr-sidebar__body__section__header__title">{{ section.label }}</span>
			<div class="vr-sidebar__body__section__header__separator"></div>
		</div>
		<template v-for="(link, idx) in section.links" :key="`sidebar-nav-link-${idx}`">
			<sidebar-nav-link v-if="link.canView" :link="link" />
		</template>
	</div>
</template>

<script>
import SidebarNavLink from './SidebarNavLink';

export default {
	props: {
		section: {
			type: Object,
			default: () => {},
			required: true,
		},
	},
	components: {
		SidebarNavLink,
	},
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.vr-sidebar__body__section {
	&__header {
		display: flex;
		height: 1.5rem;
		margin: 0.25rem 0;

		&__title {
			position: relative;
			color: $gray-500;
		}

		&__separator {
			flex-grow: 1;
			margin-left: 0.5rem;
			align-self: center;
			border-top: 1px solid $gray-500;
		}
	}
}
</style>
