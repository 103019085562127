export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ru",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход в систему"])},
      "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "passwordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
      "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забыли пароль?"])},
      "submitBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
      "validation": {
        "email": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите адрес электронной почты"])}
        },
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите пароль"])}
        }
      },
      "query": {
        "error": {
          "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный адрес электронной почты и/или пароль."])},
          "notActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь не активирован. Перейдите по ссылке активации, присланной в письме на указанный при регистрации email."])},
          "MutationTrashedEntityError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь удалён. Обратитесь к администратору своей организации."])},
          "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка запроса. Пожалуйста, попробуйте повторить попытку позже."])}
        }
      }
    }
  })
}
