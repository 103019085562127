export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "titleCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new user"])},
      "titleEditSingle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit user"])},
      "titleEditMultiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit users"])},
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention! All changes you introduce will affect several users at once."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
      "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin-code"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "passwordRepeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password"])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
      "rolesHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All user roles will be replaced by those selected above."])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
      "tagsHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All user tags will be replaced by those above."])},
      "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "confirmCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
      "confirmEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "errors": {
        "EmailFormatError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email address."])},
        "EmailExistsError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address already in use."])},
        "EmailRequiredError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address is required."])},
        "PinRequiredError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin-code is required."])},
        "PasswordTooShortError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be at least 8 characters long."])},
        "SameAsEmailError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be different from email."])},
        "PinLengthError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin-code length must be exactly 4 characters."])},
        "PinFormatError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin-code can only contain numeric characters."])},
        "PasswordMismatchError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords are different."])}
      }
    }
  })
}
