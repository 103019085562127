<template>
	<modal size="small" v-model:show="localShow">
		<template #header>{{ $t('title') }}</template>
		<template #body>{{ $t('body', { emails }) }}</template>
		<template #footer>
			<button type="button" class="btn btn-outline-secondary d-block w-25" @click="localShow = false">
				{{ $t('cancel') }}
			</button>
			<button type="button" class="btn btn-danger d-block w-25" @click="confirm">{{ $t('confirm') }}</button>
		</template>
	</modal>
</template>

<i18n>
	{
		"ru": {
			"title": "Внимание!",
			"body": "Пользователи {emails} будут удалены безвозвратно. Вместе с ними будет удалена вся текущая статистика и весь прогресс прохождения заданий. Вы уверены, что хотите удалить пользователей?",
			"cancel": "Нет",
			"confirm": "Да"
		},
		"en": {
			"title": "Warning!",
			"body": "Users {emails} will be removed along with all current statistics and assignment progress. This action can not be undone. Are your sure you want to remove these users?",
			"cancel": "No",
			"confirm": "Yes"
		}
	}
</i18n>

<script>
import { modalMixin } from '@/mixins';
import Modal from '@/components/modals/VrModal';

export default {
	mixins: [modalMixin],
	emits: ['confirm'],
	props: {
		users: {
			type: Array,
			default: () => [],
		},
	},
	components: {
		Modal,
	},
	computed: {
		emails() {
			return this.users.map(u => u.email).join(', ');
		},
	},
	methods: {
		confirm() {
			this.$emit('confirm');
			this.localShow = false;
		},
	},
};
</script>
