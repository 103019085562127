export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation name"])},
      "defaultRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default role"])},
      "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation owner"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes"])},
      "dangerZone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning! Actions listed below may result in you losing control over your organisation. Please make sure that you know what you are doing before making any changes!"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success!"])},
      "updateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation settings successfully updated."])},
      "transferSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation owner changed."])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error!"])},
      "errors": {
        "NoNameError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter organisation name."])},
        "UnauthorizedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not logged in."])},
        "NotFound": {
          "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account does not exist."])}
        },
        "NotActiveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your profile is not active."])},
        "MutationTrashedEntityError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your profile is deleted."])},
        "MutationArchivedEntityError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your profile is archived."])},
        "UserPermissionError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have permission to perform this action."])},
        "CompanyMismatchError": {
          "UserCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not part of the organisation you are trying to change."])},
          "CompanyRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New default role does not belong to this organisation."])}
        },
        "EmptyValueError": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("errors.NoNameError", undefined, _type)])},
        "UniqueValueError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation with the same name already exists."])},
        "ForbiddenError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have permission to change organisation ownership."])},
        "FallbackError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occured. Please try again later."])}
      }
    }
  })
}
