<template>
	<div class="page-content talent-link-confirm-page">
		<p v-show="success === null">{{ $t('loading') }}</p>
		<p v-show="success === true">{{ $t('success') }}</p>
		<p v-show="success === false">{{ $t('error') }}</p>
	</div>
</template>

<style scoped lang="scss">
.talent-link-confirm-page {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>

<i18n>
	{
		"ru": {
			"loading": "Идёт привязка личного кабинета, пожалуйcта, подождите...",
			"success": "Привязка личного кабинета успешна.",
			"error": "При привязке личного кабинета произошла ошибка. Пожалуйста, попробуйте позже."
		},
		"en": {
			"loading": "Account linking in progress...",
			"success": "Account linked.",
			"error": "Failed to link account. Please try again later."
		}
	}
</i18n>

<script>
import LINK_TALENT_ACCOUNT from '@/queries/views/user-settings/mutation-link-talent.graphql';

export default {
	props: {
		code: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			success: null,
		};
	},
	methods: {
		async linkTalentAccount() {
			try {
				const { data } = await this.$apollo.mutate({
					mutation: LINK_TALENT_ACCOUNT,
					variables: { code: this.code },
				});
				if (data.linkTalentAccount.success) {
					window.opener.updateCache(data);
					this.success = true;
					setTimeout(() => window.close(), 1000);
				}
			} catch (err) {
				this.success = false;
			}
		},
	},
	mounted() {
		if (this.code) {
			this.linkTalentAccount();
		}
	},
};
</script>
