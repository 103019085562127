<template>
	<div class="page-content position-relative">
		<div class="alert alert-danger" v-show="isNew && assignmentCount >= assignmentLimit">
			{{ $t('labels.assignmentLimitReached') }}
		</div>
		<div class="alert alert-warning" v-show="isForeign">
			{{ $t('labels.assignmentIsForeign') }}
		</div>
		<form class="assignment-data">
			<div class="form-group">
				<label for="assignment-name" class="form-label">{{ $t('labels.title') }}</label>
				<input
					type="text"
					id="assignment-name"
					class="form-control"
					:class="{ 'is-invalid': v$.name.$error }"
					v-model="name"
					:disabled="!inputsEnabled"
				/>
				<div class="invalid-tooltip">{{ v$.name.$error && $t(v$.name.$errors[0]?.$message) }}</div>
			</div>
			<div class="form-check">
				<input
					type="checkbox"
					class="form-check-input"
					id="assignment-ordered"
					v-model="ordered"
					:disabled="!inputsEnabled"
				/>
				<label for="assignment-ordered" class="form-check-label">{{ $t('labels.ordered') }}</label>
			</div>
			<div class="form-group">
				<label for="assignment-region" class="form-label">{{ $t('labels.region') }}</label>
				<select class="form-select" id="assignment-region" v-model="region" :disabled="!inputsEnabled">
					<option v-for="r in availableRegions" :key="`assignment-region-${r.id}`" :value="r.id">
						{{ r.name }}
					</option>
				</select>
			</div>
			<div class="form-group">
				<label>{{ $t('labels.tags') }}</label>

				<tag-list
					v-model:tags="tags"
					v-model:all-tags="availableTags"
					:editable="canEditAssignment"
					:allow-create="canAddTag"
					:allow-delete="canDeleteTag"
				/>
			</div>
			<div class="form-check" v-if="isSuperuser || isStaff">
				<input type="checkbox" class="form-check-input" id="assignment-talent" v-model="talent" />
				<label for="assignment-talent" class="form-check-label">{{ $t('labels.talent') }}</label>
			</div>
		</form>
		<div class="assignment-tasks">
			<draggable
				class="tasks-available p-3 border rounded"
				v-show="inputsEnabled"
				v-model="displayedTasks"
				:group="{ name: 'tasks', pull: 'clone', put: false }"
				:sort="false"
				item-key="id"
				:move="checkMoveTarget"
			>
				<template #header>
					<h3>{{ $t('labels.availableTasks') }}</h3>
					<div class="card filter-card">
						<div class="card-body">
							<h5 class="card-title text-center">{{ $t('labels.filters') }}</h5>
							<div class="form-group my-3">
								<label for="region-filter" class="col-form-label">{{
									$t('labels.filterRegion')
								}}</label>
								<select class="form-select" id="region-filter" v-model="regionFilter">
									<option value="">{{ $t('labels.regionAny') }}</option>
									<option v-for="r in availableRegions" :key="`region-filter-${r.id}`" :value="r.id">
										{{ r.name }}
									</option>
								</select>
								<label for="platform-filter" class="ms-3 col-form-label">{{
									$t('labels.filterPlatform')
								}}</label>
								<select class="form-select" id="platform-filter" v-model="platformFilter">
									<option value="">{{ $t('labels.platformAny') }}</option>
									<option value="VR">VR</option>
									<option value="WEB">WEB</option>
								</select>
							</div>
							<div class="form-group">
								<label class="col-form-label">{{ $t('labels.tags') }}</label>
								<tag-list v-model:tags="tagFilter" :all-tags="tagsList" editable />
							</div>
							<div class="form-group mt-3">
								<label for="name-filter" class="col-form-label">{{ $t('labels.filterName') }}</label>
								<input type="text" class="form-control" id="name-filter" v-model="nameFilter" />
							</div>
						</div>
					</div>
				</template>
				<template #item="{ element }">
					<task-card :task="element" />
				</template>
			</draggable>

			<draggable
				class="tasks-selected p-3 border rounded"
				v-model="groups"
				:group="{ name: 'groups', put: ['groups', 'tasks'] }"
				@change="redirectDroppedTask"
				item-key="id"
				:disabled="!inputsEnabled"
			>
				<template #header>
					<h3>{{ $t('labels.tasksSelected') }}</h3>
				</template>
				<template #item="{ index }">
					<group-card
						v-model:group="groups[index]"
						@remove="removeGroup"
						:isEditable="isEditable"
						:inputsEnabled="inputsEnabled"
					/>
				</template>
				<template #footer>
					<div class="card border-success">
						<div class="card-body d-flex justify-content-center">
							<button class="btn btn-success" @click="addGroup" :disabled="!inputsEnabled">
								<fa-icon class="me-3" icon="fa-solid fa-plus" /><span>{{ $t('labels.addGroup') }}</span>
							</button>
						</div>
					</div>
				</template>
			</draggable>
		</div>
		<div class="controls">
			<popper
				v-show="!isForeign && canDeleteAssignment(authorId)"
				:disabled="inputsEnabled"
				hover
				arrow
				placement="top"
			>
				<button
					v-show="!isNew"
					:disabled="!inputsEnabled"
					class="btn btn-danger"
					@click.stop="isConfirmDeleteModalVisible = true"
				>
					{{ $t('labels.deleteTemplate') }}
				</button>
				<template #content>
					<p>{{ $t('labels.deleteTemplateHint') }}</p>
				</template>
			</popper>
			<popper v-show="isNew" :disabled="assignmentCount < assignmentLimit" hover arrow placement="top">
				<button
					class="btn btn-success"
					:disabled="assignmentCount >= assignmentLimit"
					@click="submitAssignment"
				>
					{{ $t('labels.createTemplate') }}
				</button>
				<template #content>
					<p>{{ $t('labels.createTemplateHint') }}</p>
				</template>
			</popper>
			<popper
				v-show="!isNew && canEditAssignment"
				:disabled="canChangeAssignment(authorId)"
				hover
				arrow
				placement="top"
			>
				<button class="btn btn-success" :disabled="!canChangeAssignment(authorId)" @click="submitAssignment">
					{{ $t('labels.editTemplate') }}
				</button>
				<template #content>
					<p>{{ $t(isForeign ? 'labels.editForeignTemplateHint' : 'labels.editLocalTemplateHint') }}</p>
				</template>
			</popper>
			<popper v-show="canAddAssignmentForUser" :disabled="!isNew" hover arrow placement="top">
				<router-link
					class="btn btn-success"
					:class="{ disabled: isNew }"
					:to="isNew ? {} : { name: 'AssignmentUsers', query: { assignmentId: id } }"
					>{{ $t('labels.assignTemplate') }}</router-link
				>
				<template #content>
					<p>{{ $t('labels.assignTemplateHint') }}</p>
				</template>
			</popper>
		</div>
		<confirm-modal v-model:show="isConfirmDeleteModalVisible" @confirm="deleteAssignment">
			<template #header>{{ $t('labels.confirmDeleteHeader') }}</template>
			<template #body>{{ $t('labels.confirmDeleteBody') }}</template>
		</confirm-modal>
	</div>
</template>

<style lang="scss" scoped>
.form-group {
	position: relative;
}

.assignment-data > * + * {
	margin-top: 1rem;
}

.assignment-tasks {
	margin-top: 2rem;
	display: flex;
	gap: 2rem;

	.tasks-available,
	.tasks-selected {
		flex-basis: 0;
		flex-grow: 1;

		display: flex;
		flex-direction: column;
		gap: 1rem;
	}

	.tasks-available {
		.filter-card {
			.card-body {
				.form-group {
					display: flex;
					gap: 1rem;
				}
			}
		}
	}
}

.controls {
	position: sticky;
	bottom: -2rem;
	padding-top: 1rem;
	padding-bottom: 1rem;
	margin-bottom: -2rem;

	pointer-events: none;

	display: flex;
	justify-content: center;
	gap: 1rem;

	& > * {
		pointer-events: auto;
	}

	:deep(.popper) {
		--popper-theme-padding: 0.5rem;

		p {
			margin-bottom: 0;
		}
	}
}
</style>

<i18n locale="ru" src="@/locales/ru/views/assignment-editor.json"></i18n>
<i18n locale="en" src="@/locales/en/views/assignment-editor.json"></i18n>

<script>
import _isEqual from 'lodash/isEqual';
import Draggable from 'vuedraggable';
import { nanoid } from 'nanoid';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';

import CREATE_QUERY from '@/queries/views/assignment-editor/query-create.graphql';
import EDIT_QUERY from '@/queries/views/assignment-editor/query-edit.graphql';
import CREATE_MUTATION from '@/queries/views/assignment-editor/mutation-create.graphql';
import EDIT_MUTATION from '@/queries/views/assignment-editor/mutation-edit.graphql';
import DELETE_MUTATION from '@/queries/views/assignment-editor/mutation-delete.graphql';

import { QueryError } from '@/errors';

import {
	toastMixin,
	assignmentPermissionsMixin,
	assignmentForUserPermissionsMixin,
	tagPermissionsMixin,
} from '@/mixins';

import GroupCard from '@/components/assignment-editor/GroupCard';
import TaskCard from '@/components/assignment-editor/TaskCard';
import TagList from '@/components/TagList';
import ConfirmModal from '@/components/modals/ModalConfirm';
import { mapState } from 'vuex';

export default {
	props: {
		id: {
			type: String,
			default: null,
		},
	},
	mixins: [toastMixin, assignmentPermissionsMixin, assignmentForUserPermissionsMixin, tagPermissionsMixin],
	components: {
		Draggable,
		GroupCard,
		TaskCard,
		TagList,
		ConfirmModal,
	},
	data() {
		return {
			originalAssignment: {},
			assignmentCount: 0,
			assignmentLimit: 1,
			isEditable: true,
			name: null,
			ordered: false,
			region: null,
			authorId: null,
			availableFrom: null,
			tags: [],
			talent: false,
			availableTasks: [],
			availableRegions: [],
			availableTags: [],
			groups: [{ id: nanoid(), ordered: false, tasks: [] }],
			vuelidateExternalResults: {
				name: null,
			},
			regionFilter: '',
			platformFilter: '',
			tagFilter: [],
			nameFilter: null,
			isConfirmDeleteModalVisible: false,
		};
	},
	validations() {
		return {
			name: {
				required: helpers.withMessage('errors.NoNameError', required),
			},
		};
	},
	computed: {
		isNew() {
			return !this.id;
		},
		displayedTasks() {
			return this.availableTasks.filter(function (task) {
				if (this.regionFilter && task.region.id !== this.regionFilter) {
					return false;
				}
				if (this.platformFilter && task.platform !== this.platformFilter) {
					return false;
				}
				if (this.tagFilter.length) {
					const hasAllTags = this.tagFilter.every(tag => task.tags.some(t => t.id === tag.id));
					if (!hasAllTags) {
						return false;
					}
				}
				if (this.nameFilter && !task.name.toLowerCase().includes(this.nameFilter.toLowerCase())) {
					return false;
				}
				return true;
			}, this);
		},
		isForeign() {
			return this.availableFrom === 'LICENCE';
		},
		inputsEnabled() {
			if (this.isNew) {
				return this.canAddAssignment;
			} else {
				return !this.isForeign && this.isEditable && this.canChangeAssignment(this.authorId);
			}
		},
		canEditAssignment() {
			return (this.isNew && this.canAddAssignment) || (!this.isNew && this.canChangeAssignment(this.authorId));
		},
		content() {
			if (
				!this.isNew &&
				_isEqual(
					this.groups,
					this.originalAssignment.parts.map(prt => ({ ...prt, tasks: prt.tasks.slice(0) }))
				)
			) {
				return null;
			}

			return this.groups.map(gr => ({
				ordered: gr.ordered,
				tasks: gr.tasks.map(t => ({ id: t.id, grade: t.gradeOverride || t.passingGrade })),
			}));
		},
		tagsList() {
			return this.availableTasks.reduce(function (acc, task) {
				if (!task.tags?.length) {
					return acc;
				}
				const tags = task.tags.map(t => ({ ...t })).filter(t => !acc.some(a => a.id === t.id));
				acc.push(...tags);
				return acc;
			}, []);
		},
		...mapState('auth', {
			canManageTasks: state => state.company.canManageTasks,
			isSuperuser: state => state.user.isSuperuser,
			isStaff: state => state.user.isStaff,
		}),
	},
	apollo: {
		myCompany() {
			if (this.id) {
				return {
					query: EDIT_QUERY,
					manual: true,
					variables() {
						return { id: this.id };
					},
					result({ loading, data }) {
						if (!loading) {
							this.availableTasks = data.myCompany.tasks;
							this.availableTags = [...data.myCompany.tags];
							this.availableRegions = data.regions;
							this.name = data.assignment.name;
							this.ordered = data.assignment.ordered;
							this.region = data.assignment.region.id;
							this.groups = data.assignment.parts.map(prt => ({ ...prt, tasks: prt.tasks.slice(0) }));
							this.tags = data.assignment.tags.slice(0);
							this.isEditable = data.assignment.isEditable;
							this.authorId = data.assignment.author.id;
							this.originalAssignment = { ...data.assignment };
							this.availableFrom = data.assignment.availableFrom;
							this.talent = data.assignment.talent;
						}
					},
				};
			} else {
				return {
					query: CREATE_QUERY,
					manual: true,
					result({ loading, data }) {
						if (!loading) {
							this.availableTasks = data.myCompany.tasks;
							this.availableTags = [...data.myCompany.tags];
							this.region = data.myCompany.region.id;
							this.availableRegions = data.regions;
							this.assignmentCount = data.myCompany.assignmentCount;
							this.assignmentLimit = data.myCompany.assignmentLimit;
							this.availableFrom = 'COMPANY';
						}
					},
				};
			}
		},
	},
	methods: {
		addGroup() {
			this.groups.push({ id: nanoid(), ordered: false, tasks: [] });
		},
		removeGroup(group) {
			this.groups = this.groups.filter(g => g !== group);
			if (!this.groups.length) {
				this.addGroup();
			}
		},
		redirectDroppedTask(data) {
			if (data.added) {
				this.groups = this.groups.filter(gr => gr !== data.added.element);
				this.groups[this.groups.length - 1].tasks.push(data.added.element);
			}
		},
		checkMoveTarget({ draggedContext: { element }, relatedContext: { list } }) {
			if (!this.isEditable) {
				return false;
			}
			if (list === this.groups) {
				list = this.groups[this.groups.length - 1].tasks;
			}
			return !list.some(i => i.id === element.id);
		},
		buildSubmitData() {
			return {
				name: this.name !== this.originalAssignment?.name ? this.name : null,
				ordered: this.ordered !== this.originalAssignment?.ordered ? this.ordered : null,
				regionId: this.region !== this.originalAssignment.region?.id ? this.region : null,
				content: this.content,
				tags: this.tags.map(t => t.id),
				talent: this.talent !== this.originalAssignment?.talent ? this.talent : null,
			};
		},
		async submitAssignment() {
			this.v$.$clearExternalResults()
			this.v$.$touch();
			if (this.v$.$error) {
				return;
			}
			try {
				if (this.isNew) {
					await this.createAssignment();
				} else {
					await this.editAssignment();
				}
			} catch (err) {
				this.handleError(err);
			}
		},
		async createAssignment() {
			const assignmentData = this.buildSubmitData();
			const { data } = await this.$apollo.mutate({
				mutation: CREATE_MUTATION,
				variables: { data: assignmentData },
			});
			if (data.createAssignment.success) {
				this.showToast('labels.createSuccess', 'labels.toastSuccess', 'success');
				this.$router.push({ name: 'Assignment', params: { id: data.createAssignment.assignment.id } });
				this.originalAssignment = { ...data.createAssignment.assignment };
			} else {
				throw new QueryError(data.createAssignment.error);
			}
		},
		async editAssignment() {
			const assignmentData = this.buildSubmitData();
			const groups = this.groups;
			const { data } = await this.$apollo.mutate({
				mutation: EDIT_MUTATION,
				variables: { id: this.id, data: assignmentData },
				update(cache, { data }) {
					cache.modify({
						id: cache.identify(data.editAssignment.assignment),
						fields: {
							parts(existingParts) {
								return [...groups];
							},
						},
					});
				},
			});
			if (data.editAssignment.success) {
				this.showToast('labels.editSuccess', 'labels.toastSuccess', 'success');
			} else {
				throw new QueryError(data.editAssignment.error);
			}
		},
		async deleteAssignment() {
			try {
				const { data } = await this.$apollo.mutate({
					mutation: DELETE_MUTATION,
					variables: { id: this.id },
				});
				if (data.deleteAssignment.success) {
					this.$router.push({ name: 'AssignmentTemplates' });
				} else {
					throw new QueryError(data.deleteAssignment.error);
				}
			} catch (err) {
				this.handleError(err);
			}
		},
		setNameError(message) {
			this.vuelidateExternalResults.name = message;
		},
		handleError(err) {
			let errorMessage;
			if (err instanceof QueryError) {
				const { type, ...data } = err.cause;
				let errorString = `errors.${type}`;
				if (type === 'UniqueValueError' || type === 'NotFoundError') {
					errorString += `.${data.entity}`;
				}
				errorMessage = this.$t(errorString, data);

				if (type === 'UniqueValueError' && data.entity === 'Assignment') {
					return this.setNameError(errorMessage);
				}
			} else {
				errorMessage = 'errors.FallbackError';
			}
			this.showToast(errorMessage, 'labels.toastError', 'danger');
		},
	},
	setup() {
		return { v$: useVuelidate() };
	},
	watch: {
		name() {
			if (this.v$.$dirty) {
				this.v$.$reset();
			}
		},
	},
};
</script>
