<template>
	<div class="vr-modal-section">
		<h6 v-if="title" class="vr-modal-section__title">
			{{ title.toUpperCase() }}
		</h6>
		<div class="vr-modal-section__content">
			<slot />
		</div>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: '',
			required: false,
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.vr-modal-section {
	&:not(:last-of-type) {
		margin-bottom: 2rem;
	}

	&__title {
		margin: 1rem 0;
		color: $gray-500;
		border-bottom: 1px solid $gray-300;
	}

	&__content {
		padding: 0 1rem;

		& > :slotted(* + *) {
			margin-top: 1rem;
		}

		&__title {
			display: inline-block;
			margin-bottom: 1rem;
			padding: 0.5rem;
			border-radius: 0.5rem;
			background-color: $info;
			color: $light;
			font-weight: bold;

			&:not(:first-of-type) {
				margin-top: 1.5rem;
			}
		}
	}
}
</style>
