<template>
	<div class="file-input">
		<input hidden :id="`file-input-${id}`" type="file" v-bind="$attrs" @change="selectFile" />
		<label :for="`file-input-${id}`" class="btn btn-outline-secondary">{{ file?.name ?? $t('noFile') }}</label>
	</div>
</template>

<i18n>
	{
		"ru": {
			"noFile": "Выберите файл"
		},
		"en": {
			"noFile": "Select a file"
		}
	}
</i18n>

<script>
import { nanoid } from 'nanoid';
export default {
	props: {
		file: {
			type: Object,
			default: null,
		},
	},
	emits: ['update:file'],
	data() {
		return {
			id: nanoid(),
		};
	},
	methods: {
		selectFile(ev) {
			this.$emit('update:file', ev.target.files[0]);
		},
	},
};
</script>
