<template>
	<div :class="['toast', 'show', `toast--${type}`]" v-bind="$attrs">
		<div :class="['toast-header', `text-bg-${type}`]">
			<span>{{ title }}</span>
			<button :class="['btn-close', { 'btn-close-white': whiteBtn }]" @click="hideToast"></button>
		</div>
		<div class="toast-body">{{ message }}</div>
	</div>
</template>

<script>
const TYPES = ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'];
export default {
	props: {
		id: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			default: '',
		},
		message: {
			type: String,
			requred: true,
		},
		// possible values: bootstrap $theme-colors (primary, secondary, success,..)
		type: {
			type: String,
			default: 'light',
			validator(value) {
				return TYPES.includes(value);
			},
		},
		timer: {
			type: Number,
			default: 5000,
		},
	},
	data() {
		return {
			timerId: null,
		};
	},
	computed: {
		whiteBtn() {
			return this.type !== 'light' && this.type !== 'warning' && this.type !== 'info';
		},
	},
	methods: {
		hideToast() {
			this.$store.dispatch('toasts/hideToast', { id: this.id });
		},
	},
	mounted() {
		if (this.timer !== Infinity) {
			this.timerId = setTimeout(() => this.hideToast(), this.timer);
		}
	},
	beforeUnmount() {
		if (this.timerId) {
			clearTimeout(this.timerId);
		}
	},
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.toast {
	&-header {
		.btn-close {
			margin-left: auto;
		}
	}

	&--primary {
		.toast-body {
			background-color: $blue-100;
		}
	}
	&--secondary {
		.toast-body {
			background-color: $gray-100;
		}
	}
	&--success {
		.toast-body {
			background-color: $green-100;
		}
	}
	&--danger {
		.toast-body {
			background-color: $red-100;
		}
	}
	&--warning {
		.toast-body {
			background-color: $yellow-100;
		}
	}
	&--info {
		.toast-body {
			background-color: $cyan-100;
		}
	}
	&--light {
		.toast-body {
			background-color: $gray-100;
		}
	}
	&--dark {
		.toast-body {
			background-color: $white;
		}
	}
}
</style>
