<template>
	<page-section class="talent-section" :title="$t('title')">
		<template v-if="user.talentAccountLinked">
			<p class="mb-0">{{ $t('talentAccountLinked') }}</p>
			<button class="btn btn-danger" @click="unlinkTalentAccount">{{ $t('unlinkTalentAccount') }}</button>
		</template>
		<template v-else>
			<p class="mb-0">{{ $t('talentAccountNotLinked') }}</p>
			<button class="btn btn-success" @click="openAuthWindow">{{ $t('linkTalentAccount') }}</button>
		</template>
	</page-section>
</template>

<i18n>
	{
		"ru": {
			"title": "Личный кабинет Таланта",
			"talentAccountLinked": "Привязка к личному кабинету Таланта выполнена.",
			"talentAccountNotLinked": "Нет привязки к личному кабинету Таланта.",
			"linkTalentAccount": "Привязать личный кабинет Таланта",
			"unlinkTalentAccount": "Отвязать личный кабинет Таланта",
			"unlinkSuccess": "Личный кабинет Таланта отвязан.",
			"unlinkFailed": "Не удалось отвязать личный кабинет Таланта. Пожалуйста, попробуйте позже."
		},
		"en": {
			"title": "Talent account",
			"talentAccountLinked": "Talent account linked.",
			"talentAccountNotLinked": "Talent account not linked.",
			"linkTalentAccount": "Link Talent account",
			"unlinkTalentAccount": "Unlink Talent account",
			"unlinkSuccess": "Talent account unlinked.",
			"unlinkFailed": "Falied to unlink Talent account. Please try again later."
		}
	}
</i18n>

<script>
import { toastMixin } from '@/mixins';

import PageSection from '@/components/page-sections/PageSection';

import SETTINGS from '@/queries/views/user-settings/query-settings.graphql';

import UNLINK_TALENT_ACCOUNT from '@/queries/views/user-settings/mutation-unlink-talent.graphql';

export default {
	props: {
		user: {
			type: Object,
			default: () => ({}),
		},
	},
	mixins: [toastMixin],
	components: {
		PageSection,
	},
	data() {
		return {
			settings: {
				talentClientId: null,
				talentUrl: null,
			},
		};
	},
	computed: {
		talentAuthUrl() {
			return `${this.settings.talentUrl}oauth/authorize/?response_type=code&client_id=${this.settings.talentClientId}&redirect_uri=${window.location.origin}/user/talent-link-confirm`;
		},
	},
	methods: {
		openAuthWindow() {
			const cache = this.$apollo.provider.clients.defaultClient.cache;
			window.updateCache = function (data) {
				cache.modify({
					id: cache.identify(data.linkTalentAccount.user),
					fields: {
						talentAccountLinked() {
							return data.linkTalentAccount.user.talentAccountLinked;
						},
					},
				});
				delete window.updateCache;
			};
			window.open(this.talentAuthUrl, '_blank', 'popup,height=570,width=520');
		},
		async unlinkTalentAccount() {
			try {
				const { data } = await this.$apollo.mutate({ mutation: UNLINK_TALENT_ACCOUNT });
				if (data.unlinkTalentAccount.success) {
					this.showToast('unlinkSuccess', null, 'success');
				}
			} catch (err) {
				this.showToast('unlinkFailed', null, 'danger');
			}
		},
	},
	apollo: {
		settings: {
			query: SETTINGS,
		},
	},
};
</script>
