export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "newAssignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New assignment template"])},
      "assignAssignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign template to users"])},
      "filters": {
        "showEnded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show past"])},
        "showActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show ongoing"])},
        "showPending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show pending"])},
        "showComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show completed"])},
        "showIncomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show incomplete"])}
      },
      "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No sorting"])},
      "startedAtAsc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By start date (old first)"])},
      "startedAtDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By start date (new first)"])},
      "endedAtAsc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By end date (old first)"])},
      "endedAtDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By end date (new first)"])},
      "toast": {
        "deleteSuccessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success!"])},
        "deleteSuccessText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment cancelled."])},
        "deleteErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error!"])},
        "deleteErrorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error while cancelling the assignment. Please try again."])}
      },
      "emptyPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have any assignments yet"])}
    }
  })
}
