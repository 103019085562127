export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "ordered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последовательное выполнение"])},
        "emptyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перетащите задачи сюда"])}
      },
      "en": {
        "ordered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordered"])},
        "emptyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag tasks here"])}
      }
    }
  })
}
