export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "createModalHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new tag"])},
      "createModalBody": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to create a new tag \"", _interpolate(_named("tag")), "\"?"])},
      "deleteModalHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deleteTag"])},
      "deleteModalBody": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to delete the tag \"", _interpolate(_named("tag")), "\""])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
      "successes": {
        "create": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tag ", _interpolate(_named("tag")), " has been created."])},
        "delete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tag ", _interpolate(_named("tag")), " has been deleted."])}
      },
      "errors": {
        "UniqueValueError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag with the same name already exists."])},
        "FallbackError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occured. Please try again later."])}
      }
    }
  })
}
