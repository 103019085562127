<template>
	<div class="input-group">
		<input
			class="form-control"
			:class="{ 'is-invalid': vError }"
			:type="passwordType"
			:placeholder="passwordPlaceholder"
			v-model="localPassword"
			name="password"
			:autocomplete="autocompleteType"
		/>
		<button
			id="passwordVisibleBtn"
			@click="visible = !visible"
			class="btn btn-outline-secondary"
			type="button"
		>
			<fa-icon :icon="passwordIcon" />
		</button>
		<div class="invalid-tooltip">{{ vErrorMsg }}</div>
	</div>
</template>

<script>
export default {
	emits: ['update:password'],
	data() {
		return {
			visible: false,
		};
	},
	props: {
		autocompleteType: {
			type: String,
			default: 'new-password'
		},
		password: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		vError: {
			type: Boolean,
			default: false,
		},
		vErrorMsg: {
			type: String,
			default: '',
		},
		required: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		localPassword: {
			get() {
				return this.password;
			},
			set(password) {
				this.$emit('update:password', password);
			},
		},
		passwordIcon() {
			return this.visible ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye';
		},
		passwordType() {
			return this.visible ? 'text' : 'password';
		},
		passwordPlaceholder() {
			return this.required ? this.placeholder + '*': this.placeholder
		}
	},
};
</script>

<style scoped lang="scss">
#passwordVisibleBtn {
	border-radius: 0 0.375rem 0.375rem 0;
}
</style>
