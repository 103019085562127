<template>
	<div class="card-body">
		<div class="card">
			<div class="card-header d-flex">
				<div v-show="isForeign" class="is-foreign"><fa-icon icon="fas fa-clipboard" /></div>
				<span>{{ localTask.name }}</span>
				<button v-if="inputsEnabled" class="btn-close m-auto me-0" @click="$emit('remove', localTask)"></button>
			</div>
			<div class="card-body">
				<p>
					<strong>{{ $t('region') }}:</strong>&nbsp;<span>{{ localTask.region.name }}</span>
				</p>
				<p>
					<strong>{{ $t('platform') }}:</strong>&nbsp;<span>{{ localTask.platform }}</span>
				</p>
				<p class="d-flex align-items-center">
					<strong class="text-nowrap">{{ $t('grade') }}:</strong>&nbsp;<input
						type="text"
						:readonly="!inputsEnabled"
						:class="{ 'form-control': inputsEnabled, 'form-control-plaintext': !inputsEnabled }"
						v-model.number="gradeOverride"
					/>
				</p>
				<tag-list :tags="localTask.tags" />
			</div>
		</div>
	</div>
</template>

<i18n>
	{
		"ru": {
			"region": "Регион",
			"platform": "Платформа",
			"grade": "Проходной балл"
		},
		"en": {
			"region": "Region",
			"platform": "Platform",
			"grade": "Passing grade"
		}
	}
</i18n>

<script>
import Draggable from 'vuedraggable';
import TagList from '@/components/TagList';

export default {
	emits: ['update:task', 'remove'],
	data() {
		return {
			gradeOverride: this.task.gradeOverride || this.task.passingGrade,
		};
	},
	props: {
		task: {
			type: Object,
			default: () => ({}),
		},
		isEditable: {
			type: Boolean,
			default: false,
		},
		inputsEnabled: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		localTask: {
			get() {
				return this.task;
			},
			set(task) {
				this.$emit('update:task', task);
			},
		},
		isForeign() {
			return this.localTask.availableFrom === 'LICENCE';
		},
	},
	components: {
		Draggable,
		TagList,
	},
	watch: {
		gradeOverride(val) {
			let gradeOverride = 0;
			if (typeof val === 'number') {
				gradeOverride = val
			}
			this.localTask = {...this.localTask, gradeOverride: gradeOverride};
		}
	},
};
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.is-foreign {
	right: 0;
	top: 0;

	padding-right: 0.5rem;
	padding-left: 0.5rem;
	padding-bottom: 0.25rem;

	border-top-right-radius: $card-border-radius;
	border-bottom-left-radius: $card-border-radius;

	background-color: $warning;

	margin-right: 0.5rem;
}
</style>
