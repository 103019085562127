export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "tagsFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by tags"])},
      "newTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create template"])},
      "emptyPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have any assignment templates yet"])},
      "assignmentCountLimit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Organisation assignment templates: ", _interpolate(_named("assignmentCount")), "/", _interpolate(_named("assignmentLimit"))])},
      "assignmentCountLimitTipOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total number of all assignment templates created by this organisation."])},
      "assignmentCountLimitTipTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get in touch with support to increase task limit."])},
      "assignmentLimitReached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your organisation has reached its assignment template limit. To create new templates either remove exising ones or contact support to increase the limit."])},
      "filters": {
        "local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show templates from this organisation"])},
        "foreign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show templates available via licence"])},
        "editable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show editable templates"])},
        "static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show immutable templates"])}
      },
      "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No sorting"])},
      "сreatedAtAsc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By creation date  (old first)"])},
      "сreatedAtDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By creation date (new first)"])},
      "nameAsc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By name (A-z)"])},
      "nameDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By name (z-A)"])},
      "authorAsc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By author (A-z)"])},
      "authorDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By author (z-A)"])},
      "searchTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by template"])}
    }
  })
}
