export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ru",
    "resource": {
      "labels": {
        "assignmentLimitReached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Достигнуто максимально допустимое количество шаблонов заданий в организации. Чтобы иметь возможность создавать новые шаблоны, удалите часть существующих или обратитесь в поддержку для увеличения лимита."])},
        "assignmentIsForeign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот шаблон задания доступен по лицензии. В него нельзя внести изменения помимо добавления или удаления тегов."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название шаблона"])},
        "ordered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последовательное выполнение"])},
        "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регион шаблона"])},
        "availableTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступные задачи"])},
        "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтры"])},
        "filterRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регион"])},
        "regionAny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Любой"])},
        "filterPlatform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платформа"])},
        "platformAny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Любая"])},
        "filterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
        "tasksSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбранные задачи"])},
        "addGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить группу"])},
        "deleteTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить шаблон задания"])},
        "deleteTemplateHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невозможно удалить шаблон задания, назначенного пользователям."])},
        "createTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать шаблон задания"])},
        "createTemplateHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невозможно создать шаблон задания: достигнуто предельное количество шаблонов для организации."])},
        "editTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать шаблон задания"])},
        "editLocalTemplateHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невозможно редактировать шаблон задания, назначенного пользователям."])},
        "editForeignTemplateHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невозможно редактировать шаблон задания, доступного по лицензии."])},
        "assignTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назначить задание"])},
        "assignTemplateHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы назначить шаблон задания пользователям, его необходимо сохранить."])},
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теги"])},
        "toastError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка!"])},
        "toastSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно!"])},
        "editSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблон задания изменён."])},
        "createSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблон задания создан."])},
        "confirmDeleteHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внимание!"])},
        "confirmDeleteBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите удалить этот шаблон задания?"])},
        "talent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связан с курсом Таланта"])}
      },
      "errors": {
        "NoNameError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблон задания должен иметь название."])},
        "NotFoundError": {
          "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь не найден."])},
          "Region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регион не найден."])},
          "Assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактируемое задание не найдено."])},
          "Task": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Входящая в задание задача с ID = ", _interpolate(_named("pattern")), " не найдена."])}
        },
        "UserPermissionError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас нет разрешения на выполнение этого действия."])},
        "UserIsNotAdminError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы должны обладать правами администратора, чтобы создавать шаблоны заданий, не привязанные к конкретной организации."])},
        "ForbiddenError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нельзя редактировать задание, которое уже было кому-то назначено."])},
        "InconsistentError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задачи, входящие в задание, не могут иметь разные платформы."])},
        "UniqueValueError": {
          "Assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблон задания с таким названием уже существует."])},
          "AssignmentPart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Две группы в задании имеют одинаковый порядковый номер."])}
        },
        "UnpublishedTaskUsageError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одна или несколько задач, входящих в задание, не опубликованы."])},
        "ArchivedTaskUsageError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одна или несколько задач, входящих в задание, находится в архиве."])},
        "NotAvailableTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одна или несколько задач, входищих в задание, не доступны организации."])},
        "EntityLimitError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Достигнут предел количества шаблонов заданий."])},
        "RequestError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось создать курс на платформе Талант. Пожалуйста, попробуйте позже."])},
        "FallbackError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка на сервере. Пожалуйста, попробуйте позже."])}
      }
    }
  })
}
