export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ru",
    "resource": {
      "createTaskBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать задачу"])},
      "emptyTablePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас ещё нет задач"])},
      "cantManageTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вашей организации нет разрешения на управление задачами. Для получения доступа к управлению задачами, организации необходимо активировать лицензионный ключ, дающий соотвествующее разрешение."])},
      "taskLimitReached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша организация не может создавать больше задач, так как их количество достигло максимально допустимого. Вы можете либо удалить существущие задачи, либо обратиться в поддержку для увеличения лимита."])},
      "tagsFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтровать по тегам"])},
      "searchTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск по названию задачи"])},
      "taskCountLimit": {
        "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Задач в организации: ", _interpolate(_named("taskCount")), "/", _interpolate(_named("taskLimit"))])},
        "tips": {
          "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Суммарное число задач, созданных организацией."])},
          "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для увеличения лимита задач в организации, пожалуйста, обратитесь в отдел продаж."])}
        }
      },
      "publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vr chemistry lab"])},
      "filters": {
        "licenced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Из лицензии"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Из организации"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Черновые"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опубликованные"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивные"])}
      },
      "actions": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--- Действие ---"])},
        "bulkCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копировать"])},
        "bulkArchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивировать"])},
        "bulkRestore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Восстановить"])},
        "bulkDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])}
      },
      "taskTable": {
        "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список задач"])},
        "headers": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
          "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор"])},
          "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
          "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теги"])}
        },
        "items": {
          "statuses": {
            "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["черновик"])},
            "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["опубликована"])},
            "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["архивирована"])}
          }
        }
      },
      "query": {
        "success": {
          "bulkCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задачи успешно скопированы."])},
          "bulkArchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задачи успешно отправлены в архив."])},
          "bulkRestore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задачи успешно восстановлены."])},
          "bulkDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задачи успешно удалены."])}
        },
        "errors": {
          "NotFoundError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одна из выбранных задач не найдена."])},
          "CompanyTaskManageError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вашей организации нет разрешения на совершение данного действия."])},
          "CompanyMismatchError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невозможно выполнить операцию над задачей, принадлежащей другой организации."])},
          "UserPermissionError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас нет разрешения на выполнение этого действия."])},
          "TrashingEntityError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Некоторые из выбранных задач не могут быть удалены. Они используются в заданиях организации."])},
          "DeletingEntityError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Некоторые из выбранных задач не могут быть удалены безвозвратно. Для того, чтобы удалить их безвозвратно, необходимо сначала их удалить."])},
          "UniqueValueError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задача с таким именем уже существует."])},
          "EntityLimitError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Достигнуто максимально допустимое количество задач."])},
          "FallbackError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка на сервере. Пожалуйста, попробуйте позже."])}
        }
      },
      "bulkDeleteConfirm": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Безвозвратное удаление задач"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В результате действия будут безвозвратно удалены выбранные задачи. Для продолжения нажмите \"Подтвердить\"."])}
      }
    }
  })
}
