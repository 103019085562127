export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
      "view_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View user list"])},
      "add_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new users"])},
      "change_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change existing users"])},
      "change_user_roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change user roles"])},
      "trash_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete users"])},
      "delete_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove users"])},
      "ActivationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ActivationCodes"])},
      "add_activationcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new activation codes"])},
      "Licence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licences"])},
      "view_licence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View licence list"])},
      "activate_licence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate licences"])},
      "Device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devices"])},
      "view_device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View device list"])},
      "add_device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new devices"])},
      "change_device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change existing devices"])},
      "delete_device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove devices"])},
      "Task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks"])},
      "add_task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new tasks"])},
      "change_task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change existing tasks"])},
      "publish_task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish tasks"])},
      "archive_task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive tasks"])},
      "trash_task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete tasks"])},
      "delete_task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove tasks"])},
      "Template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment templates"])},
      "view_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View assignment template list"])},
      "add_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new assignment templates"])},
      "change_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change existing assignment templates"])},
      "delete_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete assignment templates"])},
      "Assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignments"])},
      "view_assignmentforuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View assignment list"])},
      "add_assignmentforuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new assignments"])},
      "change_assignmentforuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change existing assignments"])},
      "delete_assignmentforuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel assignment"])},
      "Role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
      "view_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View role list"])},
      "add_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new roles"])},
      "change_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change existing roles"])},
      "delete_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete roles"])},
      "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisations"])},
      "change_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change organisation settings"])},
      "Tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
      "view_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View tag list"])},
      "add_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new tags"])},
      "change_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change existing tags"])},
      "delete_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete tags"])}
    }
  })
}
