export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "ru",
    "resource": {
      "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название организации"])},
      "defaultRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль по умолчанию"])},
      "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Владелец организации"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить изменения"])},
      "dangerZone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внимание! Действия ниже могут стать причиной потери контроля над организацией. Убедитесь в том, что вы точно знаете, что делаете, перед тем, как что-то менять!"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно!"])},
      "updateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки организации успешно обновлены."])},
      "transferSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Владелец организации изменён."])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка!"])},
      "errors": {
        "NoNameError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Организация должна иметь название"])},
        "UnauthorizedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы не вошли в систему."])},
        "NotFound": {
          "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш профиль не существует."])}
        },
        "NotActiveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш профиль не активен."])},
        "MutationTrashedEntityError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш профиль удалён."])},
        "MutationArchivedEntityError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш профиль отправлен в архив."])},
        "UserPermissionError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас нет разрешения на выполнение этого действия."])},
        "CompanyMismatchError": {
          "UserCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невозможно выполнить операцию над другой организацией. "])},
          "CompanyRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новая роль по умолчанию не принадлежит к текущей организации."])}
        },
        "EmptyValueError": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("errors.NoNameError", undefined, _type)])},
        "UniqueValueError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Организация с таким названием уже существует."])},
        "ForbiddenError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас нет разрешения на смену владельца организации."])},
        "FallbackError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка на сервере. Пожалуйста, попробуйте позже."])}
      }
    }
  })
}
