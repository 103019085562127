export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внимание!"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назначенное задание будет отменено. Прогресс пользователей по его выполнению будет потерян. Вы уверены, что хотите отменить задание?"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])}
      },
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning!"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This assignment would be cancelled. All related user progress will be lost forever. Do you really want to cancel this assignment?"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])}
      }
    }
  })
}
