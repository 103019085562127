<template>
	<footer class="vr-footer">
		<span>&copy; {{ new Date().getFullYear() }} {{$t('productName')}}</span>
	</footer>
</template>


<i18n>
{
	"ru": {
		"productName": "VR Chemistry Lab"
	},
	"en": {
		"productName": "Vic's Science Studio"
	}
}
</i18n>

<style lang="scss">
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.vr-footer {
	grid-area: footbar;
	height: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-top: 1px solid $gray-300;

	span {
		font-size: 0.83rem;
		color: $gray-600;
	}
}
</style>
